import React from "react";
import { Scrollable } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import RowSheetContent from "./RowSheetContent.tsx";
import Sheet from "../../../components/sheet";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors.js";
import { store } from "src/js/store";
import TaskSheet from "../../Agenda/TaskSheet/TaskSheet";

const RowSheet = (props) => {
	const { t } = useTranslation(["row", "common"]);

	const boardId = props.board?.id || props.row?.boardId || props.row?.board_id || props.boardId;
	if ([store.getState().user.todo_board_id, store.getState().account.todo_board_id].includes(boardId)) {
		return (
			<TaskSheet
				board={props.board}
				open={props.open}
				row={props.row || null}
				// board={board}
				onClose={props.onClose}
				onCompleteAwaited={
					props?.onCompleteAwaited
						? async (row) => {
								await props.onCompleteAwaited?.(row);
								props.onUpdateRow(row);
								props.onClose?.();
						  }
						: undefined
				}
				onComplete={(row) => {
					props.onComplete?.(row);
					props.onUpdateRow(row);
					props.onClose?.();
				}}
			/>
		);
	}

	//This is handled in RowModalForm by returning <CalendarModalContent/>, not sure if something will break if I add this here so I will leave it commented for now.
	// if ([store.getState().user.calendar_board?.id || store.getState().user.calendar_board_id].includes(boardId)) {
	// 	return (
	// 		<CalendalSheet
	// 			open={props.open}
	// 			row={props.row || null}
	// 			onClose={props.onClose}
	// 			onComplete={(row) => {
	// 				props.onUpdateRow(row);
	// 				props.onClose?.();
	// 			}}
	// 		/>
	// 	);
	// }

	return (
		<Sheet
			open={props.open}
			onClose={props.onClose}
			staus="info"
			title={
				props.row?.title ? (
					<div>
						{props.row?.title}
						{props.row?.archived && <Badge color={Colors.yellow}>{t("row.archived", "Arkiverad")}</Badge>}
					</div>
				) : (
					`${t("common.actions.create", "Skapa")} ${(props.board && props.board.singular) || t("row.singular", "Rad")}`
				)
			}
		>
			<div
				className="row_sheet"
				style={{
					display: "flex",
					flexDirection: "column",
					// height: "calc(100% - 56px)",
				}}
			>
				<Scrollable style={{ flex: 1 }}>
					<RowSheetContent
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...props}
						contact={props.contact}
						initialTabIndex={props.initialTabIndex != undefined ? props.initialTabIndex : 0}
						open={props.open}
						board={props.board}
						onClose={props.onClose}
						row={props.row}
						onUpdateRow={props.onUpdateRow}
					/>
				</Scrollable>
			</div>
		</Sheet>
	);
};
export default RowSheet;
