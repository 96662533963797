import i18n from "src/js/i18n";

import FortnoxOrder from "src/js/views/Fortnox/Orders";
import FortnoxOrderCreate from "src/js/views/Fortnox/Orders/Create";
import { store } from "src/js/store";
import FortnoxOrdersList from "src/js/views/Fortnox/Orders/List/FortnoxOrdersList";

export default () => [
	{
		path: "/admin/fortnox/contacts/:contactId/orders/create",
		mainMenu: false,
		exact: true,
		component: FortnoxOrderCreate,
		name: i18n.t("routes.names.fortnox_order_create", "Skapa order"),
	},
	{
		path: "/admin/fortnox/orders/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxOrder,
		name: i18n.t("routes.names.fortnox_order", "Order"),
	},
	{
		path: "/admin/fortnox/orders",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxOrdersList,
		name: i18n.t("routes.names.fortnox_orders", "Ordrar"),
	},
];
