import i18n from "src/js/i18n";
import CategoryCreate from "src/js/views/OrderFlow/Categories/Create";
import CategoriesList from "src/js/views/OrderFlow/Categories/List";
import Category from "src/js/views/OrderFlow/Categories";

export default () => [
	{
		path: "/admin/categories",
		exact: true,
		component: CategoriesList,
		name: i18n.t("routes.names.categories", "Kategorier"),
	},
	{
		path: "/admin/categories/create",
		exact: true,
		component: CategoryCreate,
		name: i18n.t("routes.names.category_create", "Skapa kategori"),
	},
	{
		path: "/admin/categories/:id(\\d+)",
		exact: true,
		component: Category,
		name: i18n.t("routes.names.category", "Kategori"),
	},
];
