import { CircleInformationMajor, MobileAcceptMajor } from "@shopify/polaris-icons";
import Colors from "src/js/Colors";
import i18n from "src/js/i18n";

export const getValidationStatuses = () => {
	return {
		pending: {
			label: i18n.t("outreach.outbound_email_batches.fields.status.pending", "Validering pågår"),
			color: Colors.lightblue,
			helpText: i18n.t(
				"outreach.outbound_email_batches.fields.status.pending_help_text",
				"E-postadressen valideras just nu. Detta kan ta en stund beroende på tjänstens svarstid."
			),
		},
		ok: {
			label: i18n.t("outreach.outbound_email_batches.fields.status.ok1", "Giltig e-postadress"),
			color: Colors.green,
			helpText: i18n.t("outreach.outbound_email_batches.fields.status.ok_help_text", "E-postadressen har klarat alla kontroller och är korrekt."),
			icon: MobileAcceptMajor,
		},
		invalid: {
			label: i18n.t("outreach.outbound_email_batches.fields.status.invalid1", "Ogiltig e-postadress"),
			color: Colors.red,
			helpText: i18n.t(
				"outreach.outbound_email_batches.fields.status.invalid_help_text",
				"E-postadressen är ogiltig och kan inte användas för utskick. Kontrollera stavning och format."
			),
			icon: CircleInformationMajor,
		},
		risky: {
			label: i18n.t("outreach.outbound_email_batches.fields.status.risky", "Riskabel e-postadress"),
			color: Colors.orange,
			helpText: i18n.t(
				"outreach.outbound_email_batches.fields.status.risky_help_text",
				"E-postadressen anses riskabel och kan vara associerad med spam eller andra problem."
			),
			icon: CircleInformationMajor,
		},
		unknown: {
			label: i18n.t("outreach.outbound_email_batches.fields.status.unknown", "Okänd status"),
			color: Colors.grey,
			helpText: i18n.t(
				"outreach.outbound_email_batches.fields.status.unknown_help_text",
				"Statusen för e-postadressen är okänd. Kontrollera att valideringen har slutförts."
			),
			icon: CircleInformationMajor,
		},
	};
};

const getValidationStatus = (emailValidationResult: EmailValidationResult, outBoundEmailBatch?: OutboundEmailBatchType): any => {
	if (!emailValidationResult && outBoundEmailBatch?.validation_started_at) {
		return {
			label: i18n.t("outreach.outbound_email_batches.fields.status.no_email", "E-postadress saknas"),
			color: Colors.grey,
			helpText: i18n.t("outreach.outbound_email_batches.fields.status.no_email_help_text", "E-postadressen saknas för denna kontakt."),
		};
	}

	switch (emailValidationResult?.status) {
		case "pending":
			return getValidationStatuses().pending;
		case "ok":
			return getValidationStatuses().ok;
		case "invalid":
			return {
				...getValidationStatuses().invalid,
				subStatus: getSubStatus(emailValidationResult),
			};
		case "risky":
			if (["accept_all", "is_role"].includes(emailValidationResult?.sub_status)) {
				// return getSubStatus(emailValidationResult) || getValidationStatuses().risky;
				return {
					...getValidationStatuses().ok,
					subStatus: getSubStatus(emailValidationResult),
				};
			}
			return {
				...getValidationStatuses().risky,
				subStatus: getSubStatus(emailValidationResult),
			};
		case "unknown":
			if (emailValidationResult?.sub_status) {
				return getSubStatus(emailValidationResult) || getValidationStatuses().unknown;
			}
			return getValidationStatuses().unknown;
		default:
			if (emailValidationResult?.sub_status) {
				return getSubStatus(emailValidationResult);
			}

			return null;
	}

	// return getSubStatus(log) || getValidationStatuses()[log.email_validation_result?.status || "unknown"];
};

export default getValidationStatus;

export const getSubStatus = (emailValidationResult?: EmailValidationResult) => {
	switch (emailValidationResult?.sub_status) {
		case "email_ok":
		case "accept_all":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.email_ok", "E-postadressen är ok"),
				color: Colors.green,
				helpText: i18n.t("outreach.outbound_email_batches.fields.sub_status.email_ok_help_text", "E-postadressen är giltig."),
			};
		case "is_disposable":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.is_disposable", "Engångs-e-postadress"),
				color: Colors.orange,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.is_disposable_help_text",
					"E-postadressen är en engångsadress och bör undvikas för långsiktig kommunikation."
				),
			};
		case "is_role":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.is_role", "Rollbaserad e-postadress"),
				color: Colors.orange,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.is_role_help_text",
					"E-postadressen är rollbaserad, vilket innebär att den delas av flera användare. T.ex. support@example.com eller info@example.com"
				),
			};
		case "failed_mx_check":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.failed_mx_check", "MX-kontroll misslyckades"),
				color: Colors.red,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.failed_mx_check_help_text",
					"E-postservern svarade inte korrekt under MX-kontrollen. Kontrollera att domänens MX-poster är korrekt inställda."
				),
			};
		case "failed_smtp_check":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.failed_smtp_check", "SMTP-kontroll misslyckades"),
				color: Colors.red,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.failed_smtp_check_help_text",
					"E-postservern svarade inte korrekt under SMTP-kontrollen. Detta kan tyda på att servern är otillgänglig eller inte accepterar anslutningar."
				),
			};
		case "failed_no_mailbox":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.failed_no_mailbox", "Ingen brevlåda hittades"),
				color: Colors.red,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.failed_no_mailbox_help_text",
					"E-postservern kunde inte hitta en brevlåda för denna adress. Kontrollera att adressen är korrekt."
				),
			};
		case "failed_greylisted":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.failed_greylisted", "Greylisting misslyckades"),
				color: Colors.orange,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.failed_greylisted_help_text",
					"E-postservern är för närvarande under greylisting, vilket kan leda till fördröjningar i leveransen."
				),
			};
		case "failed_syntax_check":
			return {
				label: i18n.t("outreach.outbound_email_batches.fields.sub_status.failed_syntax_check", "Syntaxkontroll misslyckades"),
				color: Colors.red,
				helpText: i18n.t(
					"outreach.outbound_email_batches.fields.sub_status.failed_syntax_check_help_text",
					"E-postadressen är inte korrekt formaterad. Kontrollera att den följer standardformatet för e-postadresser."
				),
			};
		default:
			return null;
	}
};
