import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import IndexTable from "src/js/components/IndexTable";

const TargetAudiences = () => {
	const { t } = useTranslation(["outreach", "common"]);
	const history = useHistory();

	const columns = [
		{
			handle: "TITLE",
			label: t("outreach.target_audience.fields.title", "Titel"),
			render: (row) => row.title,
		},
		{
			handle: "UPDATED_AT",
			label: t("outreach.target_audience.fields.updated_at", "Uppdaterad"),
			render: (row) => row.updated_at,
		},
		{
			handle: "CREATED_AT",
			label: t("outreach.target_audience.created_at.title", "Skapad"),
			render: (row) => row.created_at,
		},
	];

	return (
		<IndexTable
			title={t("outreach.target_audience.plural", "Målgrupper") as string}
			savedSearchHandle="target_audiences"
			resourceUrl="/api/target_audiences.json"
			resourceHandle="target_audiences"
			resourceName={{
				singular: t("outreach.target_audience.singular", "målgrupp"),
				plural: t("outreach.target_audience.plural", "målgrupper"),
			}}
			columns={columns}
			primaryAction={{
				content: t("outreach.target_audience.create", "Skapa målgrupp") as string,
				url: "/admin/outreach/target_audiences/create",
			}}
			onClick={(row) => {
				history.push(`/admin/outreach/target_audiences/${row.id}`);
			}}
		/>
	);
};
export default TargetAudiences;
