import i18n from "src/js/i18n";
import PricelistCreate from "src/js/views/OrderFlow/Pricelists/Create";
import PricelistList from "src/js/views/OrderFlow/Pricelists/List";
import Pricelist from "src/js/views/OrderFlow/Pricelists";

export default () => [
	{
		path: "/admin/pricelists",
		exact: true,
		component: PricelistList,
		name: i18n.t("routes.names.pricelists", "Prislistor"),
	},
	{
		path: "/admin/pricelists/create",
		exact: true,
		component: PricelistCreate,
		name: i18n.t("routes.names.pricelist_create", "Skapa prislista"),
	},
	{
		path: "/admin/pricelists/:id(\\d+)",
		exact: true,
		component: Pricelist,
		name: i18n.t("routes.names.pricelist", "Prislista"),
	},
];
