import React from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Colors from "src/js/Colors";
import Modal from "src/js/components/modal";
import Spinner from "src/js/components/Spinner";
import useQuery from "src/js/hooks/useQuery";
import styled from "styled-components";

const EmailTemplateModal = ({ isOpen, onClose, onChange, form }) => {
	const { t } = useTranslation(["outreach", "common"]);

	const { data: emailTemplates, isRefetching } = useQuery({
		queryKey: ["emailTemplates"],
		queryFn: async () => {
			try {
				const res = await API.get("/api/email_templates.json", { params: { is_template: 1 } });

				return res.data.email_templates;
			} catch (error) {
				return [];
			}
		},
	});

	return (
		<Modal large open={isOpen} onClose={onClose} title={t("outreach.outbound_emails.edit.choose_template", "Välj mall")}>
			{isRefetching && <Spinner />}
			<Wrapper>
				{emailTemplates?.map((emailTemplate) => {
					const { id, title, template_thumbnail: templateThumbnail } = emailTemplate;

					return (
						<TemplateButton
							key={id}
							onClick={() => {
								onChange(emailTemplate);
								onClose();
							}}
							selected={id === form?.template?.id}
						>
							<p>{title}</p>
							<img alt="" src={templateThumbnail} />
						</TemplateButton>
					);
				})}
			</Wrapper>
		</Modal>
	);
};
export default EmailTemplateModal;

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
`;
export const TemplateButton = styled.button<{ selected?: boolean; width?: number | string }>`
	border: 1px solid ${(props) => (props.selected ? Colors.accent : "rgb(231,231,231)")};
	padding: 10px;
	margin: 5px;
	background-color: transparent;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: border 0.3s;

	&:hover {
		border: 1px solid ${Colors.accent};
	}

	p {
		text-align: center;
		color: #ffffff;
		margin-bottom: 5px;
	}

	img {
		width: 150px;
		background-color: ${(props) => (props.selected ? "#ffffff" : "rgb(231,231,231)")};
	}
`;
