import { FormLayout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import BoardFilters from "src/js/views/Workspaces/components/BoardFilters";
import styled from "styled-components";

const BoardFilter = ({ index, filterSource, handleChangeFilter, t }) => {
	const filter = filterSource.board;

	const onChange = (field: string) => (value: any) => {
		if (["date_source", "person_column", "value_column"].includes(field) && !["created_at", "updated_at"].includes(value)) {
			value = value === "" ? null : filter.board.columns.find((column: BoardColumnType) => String(column.id) === String(value)) || value;
		}

		handleChangeFilter(index)({
			...filterSource,
			board: {
				...filter,
				[field]: value,
			},
		});
	};

	const options = filter.board.columns.reduce(
		(acc, column) => {
			if (column?.type === "datetime") {
				acc.dates.push({
					label: column.title,
					value: String(column.id),
				});
			}

			if (column?.type === "person") {
				acc.person.push({
					label: column.title,
					value: String(column.id),
				});
			}

			if (["number", "formula"].includes(column?.type)) {
				acc.values.push({
					label: column.title,
					value: String(column.id),
				});
			}

			return acc;
		},
		{
			dates: [
				{
					label: "Välj datumkolumn",
					value: "",
				},
				{
					label: "Raden skapades",
					value: "created_at",
				},
				{
					label: "Raden updaterades senast",
					value: "updated_at",
				},
			],
			person: [
				{
					label: "Ignorera personkolumn",
					value: "",
				},
			],
			values: [
				{
					label: "Räkna antal rader",
					value: "",
				},
			],
		}
	);

	return (
		<Wrapper>
			<FormLayout>
				<SelectsWrapper>
					<Select
						label={t("targets.filters.date_source.label", "Välj datumkolumn") as string}
						options={options.dates}
						value={String(
							filter.date_source === "created_at" || filter.date_source === "updated_at"
								? filter.date_source
								: filter.date_source?.id || filter.date_source
						)}
						requiredIndicator
						onChange={onChange("date_source")}
					/>
					<Select
						label={t("targets.filters.person_column.label", "Välj personkolumn") as string}
						options={options.person}
						value={String(filter.person_column?.id)}
						onChange={onChange("person_column")}
					/>
					<Select
						label={t("targets.filters.value_column.label", "Välj värdekolumn") as string}
						options={options.values}
						value={String(filter.value_column?.id)}
						onChange={onChange("value_column")}
					/>
				</SelectsWrapper>
				<BoardFilters
					onChangeFilters={onChange("filters")}
					filters={filter.filters}
					boards={[filter.board]}
					groups={filter.board.groups}
					boardPeopleIds={filter.board?.members?.map((member) => member.user_id)}
					// filteredRows={this.props.filteredRows}
					rows={[]}
					// additionalAction={this.state.filterAction}
				/>
			</FormLayout>
		</Wrapper>
	);
};
export default withTranslation(["targets"])(BoardFilter);

const Wrapper = styled.div``;
const SelectsWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
