import i18n from "src/js/i18n";

import FortnoxContract from "src/js/views/Fortnox/BillingContracts";
import FortnoxContractCreate from "src/js/views/Fortnox/BillingContracts/Create";
import FortnoxBillingContractsList from "src/js/views/Fortnox/BillingContracts/List/FortnoxBillingContractsList";
import { store } from "src/js/store";

export default () => [
	{
		path: "/admin/fortnox/contacts/:contactId/contracts/create",
		mainMenu: false,
		exact: true,
		component: FortnoxContractCreate,
		name: i18n.t("routes.names.fortnox_contract_create", "Skapa avtal"),
	},
	{
		path: "/admin/fortnox/contracts/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxContract,
		name: i18n.t("routes.names.fortnox_contract", "Avtal"),
	},
	{
		path: "/admin/fortnox/contracts",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxBillingContractsList,
		name: i18n.t("routes.names.fortnox_contracts", "Avtal"),
	},
];
