import { useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import React, { useState, createContext, use, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";

const EmailTemplateContext = createContext({} as any);

export const useEmailTemplateContext = () => {
	return use(EmailTemplateContext);
};

export const EmailTemplateContextProvider = ({ children }) => {
	const { t } = useTranslation(["emailTemplate", "common"]);
	const { id }: any = useParams();
	const history = useHistory();

	const queryClient = useQueryClient();

	const [form, setForm] = useState({
		title: "",
		content: "",
		design: {},
		is_template: 1,
	});
	const queryKey = useMemo(() => (id ? ["emailTemplate", id] : []), [id]);

	const {
		data: emailTemplate,
		isFetching: isLoading,
		isInitialLoading,
		isFetched,
		isFetchedAfterMount,
		isPlaceholderData,
		isSuccess,
	}: UseQueryResult<any> = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/email_templates/${id}.json`);

				return res.data.email_template;
			} catch (e) {
				Toast.error(e);
			}
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (emailTemplate) {
			setForm(emailTemplate);
		}
	}, [emailTemplate]);

	const mutation = useMutation(
		async ({ content, design }: { content: any; design: any }) => {
			const newForm = { ...form, content, design };
			setForm(newForm);
			try {
				if (id) {
					const res = await API.put(`/api/email_templates/${id}.json`, newForm);

					Toast.success(t("emailTemplate.responses.updated.success", "E-postmall uppdaterad"));
					return res.data.email_template;
				} else {
					const res = await API.post(`/api/email_templates.json`, newForm);
					Toast.success(t("emailTemplate.responses.created.success", "E-postmall skapad"));

					history.push(`/admin/email_templates/${res.data.email_template.id}`);

					return res.data.email_template;
				}
			} catch (e) {
				Toast.error(e);
			}
		},
		{
			onSuccess: (data) => {
				if (data?.id) {
					const id = data.id;
					const queryKey = id ? ["emailTemplate", id] : [];
					if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				}
			},
		}
	);

	const handleSave = useCallback(
		async (data) => {
			try {
				return await mutation.mutateAsync(data);
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		},
		[mutation]
	);

	const handleDuplicate = useCallback(async () => {
		try {
			const res = await API.post(`/api/email_templates/${id}/duplicate.json`);
			const emailTemplate = res.data.email_template;

			Toast.success(t("emailTemplate.responses.duplicated.success", "E-postmall duplicerad"));

			history.push(`/admin/email_templates/${emailTemplate.id}`);
		} catch (e) {
			Toast.error(e);
		}
	}, [history, id, t]);

	const isSaving = mutation.isLoading;

	const value = useMemo(
		() => ({
			t,
			id,
			emailTemplate,
			isLoading,
			handleSave,
			isSaving,
			isInitialLoading,
			form,
			setForm,
			handleDuplicate,
			isFetched,
			isFetchedAfterMount,
			isPlaceholderData,
			isSuccess,
		}),
		[
			t,
			id,
			emailTemplate,
			isLoading,
			handleSave,
			isSaving,
			isInitialLoading,
			form,
			setForm,
			handleDuplicate,
			isFetched,
			isFetchedAfterMount,
			isPlaceholderData,
			isSuccess,
		]
	);

	return <EmailTemplateContext value={value}>{children}</EmailTemplateContext>;
};

export default EmailTemplateContext;
