import i18n from "src/js/i18n";

import Pdf from "src/js/views/Pdf/Pdf.js";
import NewPDF from "src/js/views/Pdf/NewPDF.js";
import EditPDF from "src/js/views/Pdf/EditPDF.js";
import EditorView from "src/js/views/Pdf/Editor/EditorView.js";

export default () => [
	{
		path: "/admin/pdf_templates/new",
		mainMenu: true,
		exact: true,
		component: NewPDF,
		name: i18n.t("routes.names.pdf_create", "Skapa PDF"),
	},
	{
		path: "/admin/pdf_templates",
		mainMenu: true,
		exact: true,
		component: Pdf,
		name: i18n.t("routes.names.pdfs", "PDFs"),
	},
	{
		path: "/admin/pdf_templates/editor/:id",
		mainMenu: true,
		exact: true,
		component: EditorView,
		name: i18n.t("routes.names.pdf_editor", "Redigera PDF"),
	},
	{
		path: "/admin/pdf_templates/:id",
		mainMenu: true,
		exact: true,
		component: EditPDF,
		name: i18n.t("routes.names.pdf_edit", "Redigera PDF"),
	},
];
