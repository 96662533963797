import React, { useState } from "react";
import TaskRow from "../views/Agenda/TaskRow";
import { useSelector } from "react-redux";
import { formatDeadlineRows } from "../views/Workspaces/boardutils/BoardUtils";
import TaskSheet from "../views/Agenda/TaskSheet";
import CalendarRow from "../views/Agenda/Calendar/CalendarRow";
import RowSheet from "../views/Workspaces/components/RowSheet";
import RowSnippet2 from "../views/Workspaces/components/RowSnippet2";

const BoardRowComponent = ({ row, board, onUpdate }) => {
	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);

	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	if ([user.todo_board_id, account.todo_board_id].includes(board?.id)) {
		if (!row.deadline) row = formatDeadlineRows({ rows: [row], boards: [board] });

		return (
			<>
				<TaskRow
					key={row.key}
					row={row}
					// color={Colors.red}
					// refresh={handleRefresh}
					onOpen={handleOpen}
					// setSelection={setSelection}
					// selection={selection}
				/>
				<TaskSheet
					open={!!isOpen}
					row={(row || null) as BoardRowType | any}
					// board={board}
					onClose={() => {
						handleClose();
					}}
					onComplete={(row: BoardRowType) => {
						onUpdate(row);
						handleClose();
						// handleRefresh();
					}}
				/>
			</>
		);
	}

	if ([user.calendar_board_id].includes(board?.id)) {
		if (!row.deadline) row = formatDeadlineRows({ rows: [row], boards: [board] });

		return (
			<>
				<CalendarRow
					key={row.key}
					row={row}
					onClick={handleOpen}
					// refresh={this.refresh}
				/>
				<RowSheet open={isOpen} row={row} board={board} initialTabIndex={0} onUpdateRow={onUpdate} onClose={handleClose} onComplete={onUpdate} />
			</>
		);
	}

	return (
		<>
			<RowSnippet2
				key={row.id}
				onClick={handleOpen}
				// group={groups ? groups.find((g) => g.id === row.group_id) : { id: row.group_id, color: row.group_color }}
				board={board}
				row={row}
			/>
			<RowSheet open={isOpen} row={row} board={board} initialTabIndex={0} onUpdateRow={onUpdate} onClose={handleClose} onComplete={onUpdate} />
		</>
	);
};
export default BoardRowComponent;
