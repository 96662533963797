/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

import { Frame, ContextualSaveBar, Loading } from "@shopify/polaris";
import introJs from "intro.js";
import { withTranslation } from "react-i18next";
import moment from "moment";
import getRoutes from "./Routes";
import { store } from "./store";

import MyTopBar from "./components/MyTopBar";
import MainMenu from "./components/MainMenu";
import Announcements from "./views/Announcements/Announements";
import "./redirectToMobileStore";
import "intro.js/introjs.css";
import SocketProvider from "./SocketProvider";
import API from "./API";
import i18n from "./i18n";
import Clippy from "./components/Clippy";
import { NotificationProvider } from "./ContextProviders/NotificationContext";
import { FortnoxGlobalContextProvider } from "./views/Fortnox/ContextProvider/FortnoxGlobalContext";
import { ProgressJobProvider } from "./components/ProgressJob/ProgressJobContext";
import { ThemeProvider } from "./views/Dashboard/ThemeToggle";
import PDFContextProvider from "./PDF/PDFContext";
import RouteWithTitle from "src/RouteWithTitle";
import BrowserWarningBanner from "./BrowserWarningBanner";
import { WorkspacesContextProvider } from "./components/Workspaces/WorkspaceContext";

Object.defineProperty(String.prototype, "trans", {
	value() {
		return this;
	},
	enumerable: false,
});

// Service worker registration
if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("/firebase-messaging-sw.js")
		.then((registration) => {
			console.debug("Service Worker registered with scope:", registration.scope);
		})
		.catch((err) => {
			console.warn("Service Worker registration failed:", err);
		});
}

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.addEventListener("message", function (event) {
		const url = event.data?.url;
		if (event.isTrusted && event?.data?.action === "navigate" && url) {
			if (url) window.location.href = url;
		}
	});
}

moment.updateLocale(store.getState().user.locale || "sv", {
	relativeTime: {
		future: i18n.t("moment.future", "om {{v}}", { v: "%s" }),
		past: i18n.t("moment.future", "{{v}} sedan", { v: "%s" }),
		s(number) {
			return i18n.t("moment.mm", "{{v}} minuter", { v: `00:${(number < 10 ? "0" : "") + number}` });
		},
		m: i18n.t("moment.m", "01:00 minuter"),
		mm(number) {
			return i18n.t("moment.mm", "{{v}} minuter", { v: `${(number < 10 ? "0" : "") + number}:00` });
		},
		h: i18n.t("moment.h", "en timme"),
		hh: i18n.t("moment.hh", "{{v}} timmar", { v: "%d" }),
		d: i18n.t("moment.d", "en dag"),
		dd: i18n.t("moment.dd", "{{v}} dagar", { v: "%d" }),
		M: i18n.t("moment.M", "en månad"),
		MM: i18n.t("moment.MM", "{{v}} månader", { v: "%d" }),
		y: i18n.t("moment.y", "ett år"),
		yy: i18n.t("moment.yy", "{{v}} år", { v: "%d" }),
	},
});

introJs().setOptions({
	tooltipClass: "introjs_tooltip",
});

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onMessage = (evt) => {
			if (evt.data === "reload") {
				window.location.reload();
			}
		};
		this.handleMessage = this.onMessage.bind(this);
	}

	componentDidMount() {
		if (window.addEventListener) {
			window.addEventListener("message", this.handleMessage, false);
		} else {
			window.attachEvent("onmessage", this.handleMessage);
		}
	}

	render() {
		const introslide = <Announcements fetchNewsletters history={this.props.history} />;
		const isDirty = store.getState().savable;

		const contextualSaveBarMarkup = isDirty ? (
			<ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: this.handleSave,
				}}
				discardAction={{
					onAction: this.handleDiscard,
				}}
			/>
		) : null;

		const loadingMarkup = store.getState().loading ? <Loading /> : null;

		const topBar = (
			<MyTopBar
				onNavigationToggle={() => {
					this.setState({ showMobileNavigation: !this.state.showMobileNavigation });
				}}
				history={this.props.history}
				location={this.props.location}
			/>
		);

		return (
			<ThemeProvider>
				<WorkspacesContextProvider>
					<SocketProvider history={this.props.history}>
						<ProgressJobProvider history={this.props.history}>
							<NotificationProvider>
								<FortnoxGlobalContextProvider>
									<PDFContextProvider>
										<Frame
											topBar={topBar}
											navigation={
												<MainMenu
													onCloseMenu={() => this.setState({ showMobileNavigation: false })}
													location={this.props.location}
													history={this.props.history}
												/>
											}
											showMobileNavigation={this.state.showMobileNavigation}
											onNavigationDismiss={() => this.setState({ showMobileNavigation: !this.state.showMobileNavigation })}
										>
											<BrowserWarningBanner />
											{introslide}
											{contextualSaveBarMarkup}
											{loadingMarkup}
											<Clippy history={this.props.history} />
											<Switch>
												{getRoutes().map((prop, key) => {
													if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
													return (
														<RouteWithTitle
															path={prop.path}
															component={prop.component}
															key={key}
															exact={prop.exact}
															route={prop}
															roles={prop.roles}
														/>
													);
												})}
											</Switch>
										</Frame>
									</PDFContextProvider>
								</FortnoxGlobalContextProvider>
							</NotificationProvider>
						</ProgressJobProvider>
					</SocketProvider>
				</WorkspacesContextProvider>
			</ThemeProvider>
		);
	}
}

export default withTranslation(["app", "common"], { withRef: true })(App);
