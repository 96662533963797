import i18n from "src/js/i18n";

import UserIndex from "src/js/views/Users/index";
// import UserCreate from "./views/Users/create.js";
import UserEdit from "src/js/views/Users/edit";

export default () => [
	{
		path: "/admin/users",
		mainMenu: true,
		exact: true,
		component: UserIndex,
		name: i18n.t("routes.names.users", "Användare"),
	},
	// {
	// 	path: "/admin/users/new",
	// 	mainMenu: false,
	// 	exact: true,
	// 	component: UserCreate,
	// 	name: i18n.t("routes.names.user_create", "Skapa användare"),
	// },
	{
		path: "/admin/users/:id",
		mainMenu: false,
		exact: true,
		component: UserEdit,
		name: i18n.t("routes.names.user_edit", "Redigera användare"),
	},
];
