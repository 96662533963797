import i18n from "src/js/i18n";
import { TargetCreate, TargetEdit, TargetList } from "src/js/views/Targets";

export default () => [
	{
		path: "/admin/targets",
		exact: true,
		component: TargetList,
		name: i18n.t("routes.names.targets", "Målsättningar"),
	},
	{
		path: "/admin/targets/create",
		exact: true,
		component: TargetCreate,
		name: i18n.t("routes.names.target_create", "Skapa målsättning"),
	},
	{
		path: "/admin/targets/:id",
		exact: true,
		component: TargetEdit,
		name: i18n.t("routes.names.target_edit", "Redigera målsättning"),
	},
];
