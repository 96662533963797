import i18n from "src/js/i18n";

import UsersActivity from "src/js/views/UsersActivity";
import UsersActivityUserPage from "src/js/views/UsersActivity/UsersActivityUserPage";

export default () => [
	{
		path: "/admin/users_activity",
		exact: true,
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
		component: UsersActivity,
		name: i18n.t("routes.names.users_activity", "Användaraktivitet"),
	},
	{
		path: "/admin/users_activity/user/:id",
		exact: true,
		component: UsersActivityUserPage,
		name: i18n.t("routes.names.users_activity", "Användaraktivitet"),
	},
];
