import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Comment from "./Comment";
import TextField from "../TextField";
import useQuery from "src/js/hooks/useQuery";
import API from "src/js/API";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Toast from "../Toast";
import { useTranslation } from "react-i18next";
import Button from "../Button";

type CommentsProps = {
	url: string;
	resourceHandle: string;
	style?: React.CSSProperties;
};

const Comments = ({ url, resourceHandle, style }: CommentsProps) => {
	const [input, setInput] = useState("");
	const [height, setHeight] = useState<any>(null);
	const ref = React.useRef<HTMLDivElement>(null);
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const { t } = useTranslation(["comments"]);

	const queryClient = useQueryClient();

	React.useEffect(() => {
		setTimeout(() => {
			if (ref.current) {
				setHeight(ref.current.clientHeight);
			}
		}, 0);
	}, []);

	const queryKey = ["comments", url];
	const enabled = !!(url && !url?.includes("undefined"));
	const { data: comments } = useQuery({
		queryKey,
		queryFn: async () => {
			const result = await API.get(url);
			return result.data?.[resourceHandle];
		},
		enabled,
		initialData: [],
	});

	const scrollToBottom = useCallback((smooth = true, top = scrollRef.current?.scrollHeight) => {
		if (scrollRef.current) {
			if (smooth) {
				scrollRef.current.scrollTo({
					top,
					behavior: "smooth",
				});
			} else if (typeof top === "number") {
				scrollRef.current.scrollTop = top;
			}
		}
	}, []);

	useEffect(() => {
		setTimeout(scrollToBottom, 250);
	}, [scrollToBottom]);

	useEffect(() => {
		scrollToBottom();
	}, [comments?.length, scrollToBottom]);

	// addd mutation for prospectList
	const saveComment = useMutation(
		async (data: any) => {
			const res = await API.post(url, data);
			Toast.success(t("comments.responses.create.success", "Koommentar sparad"));
			return res?.data.dial_entry_activity_log;
		},
		{
			onSuccess: (data) => {
				if (queryKey?.length && enabled) {
					queryClient.setQueryData(queryKey, (old: any) => {
						return [...old, data];
					});
				}
			},
		}
	);

	const handleSave = async () => {
		await saveComment.mutateAsync({ content: input });
		setInput("");
	};

	return (
		<Wrapper ref={ref} height={height} style={style}>
			<CommentsScrollWrapper ref={scrollRef}>
				{height &&
					comments?.map((comment, index, arr) => (
						<>
							<Comment key={index} data={comment} />
							{index !== arr.length - 1 && <hr style={{ opacity: 0.25, marginInline: "5%", height: 1 }} />}
						</>
					))}
			</CommentsScrollWrapper>
			<TextField
				disabled={saveComment.isLoading}
				value={input}
				onChange={setInput}
				placeholder="Skriv en kommentar"
				onSubmit={handleSave}
				multiline={2}
				suffix={
					<Button loading={saveComment.isLoading} primary onClick={handleSave}>
						{t("comments.actions.save", "Spara")}
					</Button>
				}
			/>
		</Wrapper>
	);
};
export default Comments;

const Wrapper = styled.div<{
	height: number | null;
}>`
	padding: 0.25rem;
	height: ${(props) => (props.height ? `${props.height}px` : "100%")};
	display: flex;
	flex-direction: column;
	justify-content: end;
`;

const CommentsScrollWrapper = styled.div`
	flex: 1;
	overflow: auto;
`;
