import React, { useState } from "react";
import useContact from "src/js/hooks/useContact";
import { Wrapper } from "./styles";
import OpenNotificationBanner from "src/js/components/OpenNotification";
import { useHistory, useParams } from "react-router-dom";
import SkeletonContact from "src/js/components/Skeletons/SkeletonContact";
import Page from "src/js/components/page";
import Banner from "src/js/components/banner";
import {
	CalendarMajor,
	ChecklistMajor,
	EditMajor,
	EmailMajor,
	ExitMajor,
	MobileHorizontalDotsMajor,
	NoteMajor,
	UploadMajor,
} from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import Modals from "./Modals";
import { store } from "src/js/store";
import { getIconByString } from "../Workspaces/components/IconSelector";
import TextField from "src/js/components/TextField";
import { FormLayout } from "@shopify/polaris";
import SelfContainedEmailModal from "./Modals/SelfContainedEmailModal";
import SelfContainedEmailImportModal from "./Modals/SelfContainedEmailImportModal";
import SelfContainedAddressModal from "./Modals/SelfContainedAddressModal";
import Spinner from "src/js/components/Spinner";

import ContactTabs from "./ContactTabs";
import ContactModal from "./ContactModal";

/**
 * Some notes to know for this new refactor of contact view.
 * - We no longer use the "links" on integrations database table, instead we just check for the integration.
 * - We still use "shortcuts" from the database, but there are only 1 shortcut called "Ny affär"
 *
 */

const Contact = ({ external }) => {
	const { id }: any = useParams();
	const { contact, isContactRefetching, isInitialLoading, removeFortnoxIntegration, t, syncToFortnox } = useContact(id, undefined, {
		refetchOnMount: true,
	});
	const history = useHistory();
	const params = new URLSearchParams(history.location.search);
	const notificationId = params.get("notification_id");
	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);
	const [modalsData, setModalsData] = useState({ board: null, boardRow: null, boardColumn: null });
	const [customCustomerNumber, setCustomCustomerNumber] = useState("");

	const [modalStates, setModalsStates] = useState({ contact_modal: false });
	const handleModalClose = (modalName) => {
		setModalsStates((c) => ({
			...c,
			[modalName]: false,
		}));
	};

	const handleModalOpen = (modalName) => {
		setModalsStates((c) => ({
			...c,
			[modalName]: true,
		}));
	};

	const handleToggleModal = (modalName, open = !modalStates[modalName]) => {
		setModalsStates((c) => ({
			...c,
			[modalName]: open,
		}));
	};

	const isModalOpen = (modalName) => {
		return !!modalStates[modalName];
	};

	if (isInitialLoading) return <SkeletonContact contact={contact} id={id} key={id} />;
	if (!contact) {
		return (
			<Wrapper>
				<Page>
					<Banner type="error" title={t("contact.not_found", "Kontakten hittades inte")} />
				</Page>
			</Wrapper>
		);
	}

	const clickShortcut = (shortcut) => () => {
		if (
			shortcut.board_column &&
			shortcut.board_column.type === "upload" &&
			shortcut.board_column.options &&
			shortcut.board_column.options.integration &&
			shortcut.board_column.options.integration.type === "scrive"
		) {
			// this.toggleContractModal(shortcut.board_column, shortcut.board);
			setModalsData({ board: shortcut.board, boardRow: null, boardColumn: shortcut.board_column });
			handleToggleModal("contract");
		} else if (shortcut.board_column) {
			setModalsData({ board: shortcut.board, boardRow: null, boardColumn: shortcut.board_column });
			handleModalOpen("columnModal");
		} else if (shortcut.board) {
			setModalsData({ board: shortcut.board, boardRow: null, boardColumn: null });
			handleModalOpen("rowModal");
		}
	};

	const orgnr = contact?.orgnr?.replace(/[^0-9]/g, "");
	const secondaryActions = [
		{
			icon: ChecklistMajor,
			onAction: () => {
				handleModalOpen("task");
			},
			content: t("common.related_resources.actions.new_task", "Ny uppgift"),
			enabled: !!user.todo_board,
		},
		{
			icon: CalendarMajor,
			onAction: () => {
				handleModalOpen("calendar");
			},
			content: t("common.related_resources.actions.new_calendar_event", "Nytt kalenderinlägg"),
			enabled: user.calendar_board,
		},
		...(store.getState().shortcuts || []).map((shortcut) => {
			return {
				icon: getIconByString(shortcut.board.icon),
				content: shortcut.title,
				onAction: clickShortcut(shortcut),
				roles: shortcut.board?.id ? [`ROLE_BOARD_${shortcut.board.id}_WRITE`] : [],
			};
		}),
		{
			icon: UploadMajor,
			content: t("common.related_resources.actions.new_document", "Nytt dokument"),
			onAction: () => handleModalOpen("document"),
			enabled: account.upload_board_id || account.sales_board_id,
		},
		{
			icon: (<img src="/assets/logos/allabolag-logo.jpg" alt="Allabolag" />) as any,
			content: t("common.related_resources.actions.allabolag", "Allabolag"),
			onAction: () => window.open(`https://www.allabolag.se/${orgnr}`),
			enabled: !!orgnr,
		},
		{
			icon: (<img src="/assets/fortnox-logo.png" alt="Fortnox" />) as any,
			content: t("common.related_resources.actions.sync_fortnox", "Synka till Fortnox"),
			onAction: async () => {
				await syncToFortnox(customCustomerNumber);
				setCustomCustomerNumber("");
			},
			confirmation: {
				title: t("common.related_resources.sync_fortnox.confirmation.title", "Synka till Fortnox"),
				content: (
					<FormLayout>
						<p>{t("common.related_resources.sync_fortnox.confirmation.content", "Är du säker på att du vill synka denna kund till Fortnox?")}</p>

						<TextField
							label={t("common.related_resources.sync_fortnox.confirmation.optional_customer_number", "Kundnummer (Valfritt)")}
							value={customCustomerNumber}
							onChange={(v) => {
								setCustomCustomerNumber(v);
							}}
						/>
					</FormLayout>
				),
				confirm: t("common.related_resources.sync_fortnox.confirmation.confirm", "Synka"),
				cancel: t("common.related_resources.sync_fortnox.confirmation.cancel", "Avbryt"),
			},

			enabled:
				store.getState().account.fortnox_integration && !store.getState().account.fortnox_integration?.removed && !contact?.fortnox_customer_id,
		},
		{
			icon: EmailMajor,
			title: "E-post",
			actions: [
				<SelfContainedEmailModal key="email_send" contact={contact} />,
				<SelfContainedEmailImportModal key="email_import" contact={contact} />,
			]?.filter((a) => a && (!("enabled" in a) || a?.enabled)),
		},
		// ...(actions?.filter((action) => !action?.actions) || []),
	].filter((s) => (!("enabled" in s) || s.enabled) && (s.onAction || s.actions?.length));

	return (
		<Wrapper>
			<Page
				fullWidth
				titleMetadata={isContactRefetching && <Spinner size={"small"} />}
				key={contact?.id}
				title={contact?.name}
				secondaryActions={secondaryActions}
				actionGroups={
					contact?.removed
						? []
						: [
								{
									icon: MobileHorizontalDotsMajor,
									title: t("common.terms.rest", "Övrigt"),
									actions: [
										{
											icon: EditMajor,
											content: t("contact.actions.change", "Ändra uppgifter"),
											onAction: () => handleToggleModal("contact_modal", true),
										},
										(<SelfContainedAddressModal key="address" contact={contact} />) as any,
										{
											icon: ExitMajor,
											content: t("contact.actions.fornox.remove", "Ta bort Fortnox koppling"),
											onAction: removeFortnoxIntegration,
											destructive: true,
											enabled:
												!contact.fortnox_integration?.removed && contact.fortnox_customer_id && store.getState().user.roles.includes("ROLE_ADMIN"),
											confirmation: {
												title: t("contact.fortnox.remove.confirmation.title", "Ta bort koppling till Fortnox"),
												content: t("contact.fortnox.remove.confirmation.content", "Är du säker på att du vill ta bort kopplingen till Fortnox?"),
											},
										},
										{
											icon: NoteMajor,
											content: t("contact.actions.logs.label", "Öppna kontakt loggar"),
											onAction: () => {
												history.push(`/admin/contacts/${id}/logs`);
											},
										},
									].filter((a) => !("enabled" in a) || a?.enabled),
								},
						  ]
				}
			>
				<OpenNotificationBanner notification_id={notificationId} />
				<Modals
					handleModalClose={handleModalClose}
					handleModalOpen={handleModalOpen}
					isModalOpen={isModalOpen}
					handleToggleModal={handleToggleModal}
					setModalsData={setModalsData}
					modalsData={modalsData}
				/>
				{contact.removed && (
					<Banner style={{ marginBottom: "0.6250rem" }} status="warning" title={t("contact.statuses.removed", "Denna kontakt är borttagen")}>
						{t("contact.text.removed", "{{name}} är borttagen och kan ej redigeras längre.", { name: contact.name })}
					</Banner>
				)}

				<ContactTabs external={external} />

				<ContactModal
					open={modalStates.contact_modal}
					company={contact?.is_company ? contact : contact?.parent}
					contact={contact}
					onClose={() => handleToggleModal("contact_modal", false)}
				/>

				{/* <Note /> */}
			</Page>
		</Wrapper>
	);
};

export default Contact;
