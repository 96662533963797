import i18n from "src/js/i18n";

// const TargetAudienceCreate = React.lazy(() => import("src/js/views/Outreach/TargetAudience/Create"));
// const TargetAudienceEdit = React.lazy(() => import("src/js/views/Outreach/TargetAudience/Edit"));
// const TargetAudienceList = React.lazy(() => import("src/js/views/Outreach/TargetAudience/List"));
import TargetAudienceCreate from "src/js/views/Outreach/TargetAudience/Create";
import TargetAudienceEdit from "src/js/views/Outreach/TargetAudience/Edit";
import TargetAudienceList from "src/js/views/Outreach/TargetAudience/List";

export default () => [
	{
		path: "/admin/outreach/target_audiences/create",
		name: i18n.t("routes.names.outreach.target_audiences.create", "Skapa målgrupp"),
		exact: true,
		component: TargetAudienceCreate,
	},
	{
		path: "/admin/outreach/target_audiences/:id",
		name: i18n.t("routes.names.outreach.target_audiences.edit", "Redigera målgrupp"),
		exact: true,
		component: TargetAudienceEdit,
	},
	{
		path: "/admin/outreach/target_audiences",
		name: i18n.t("routes.names.outreach.target_audiences.list", "Målgrupper"),
		exact: true,
		component: TargetAudienceList,
	},
];
