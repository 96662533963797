import i18n from "src/js/i18n";

import ContactsCompanies from "src/js/views/Contacts/Companies.js";
import ContactsPeople from "src/js/views/Contacts/People";
import ContactImportRoaring from "src/js/views/Contacts/ContactImportRoaring.js";
import ContactImport from "src/js/views/Contacts/Import/Import";
import Contact from "src/js/views/Contacts/Contact";
import ContactLogs from "src/js/views/Contacts/logs/ContactLogs";
import ContactEdit from "src/js/views/Contacts/edit.js";

export default () => [
	{
		path: "/admin/contacts/import/:orgnr",
		mainMenu: false,
		exact: true,
		component: ContactImport,
		name: i18n.t("routes.names.contacts.import.name", "Importera kontakter"),
	},
	{
		path: "/admin/contacts/roaring/:id",
		mainMenu: false,
		exact: true,
		component: ContactImportRoaring,
		name: i18n.t("routes.names.contact_import_roaring", "Importera kontakter från Roaring"),
	},
	{
		path: "/admin/contacts/companies",
		mainMenu: true,
		handle: "companies",
		exact: true,
		component: ContactsCompanies,
		name: i18n.t("routes.names.companies", "Företag"),
	},
	{
		path: "/admin/contacts/people",
		mainMenu: true,
		handle: "contacts",
		exact: true,
		component: ContactsPeople,
		name: i18n.t("routes.names.people", "Personer"),
	},
	{
		path: "/admin/contacts/:id/logs",
		mainMenu: false,
		exact: true,
		component: ContactLogs,
		name: i18n.t("routes.names.contact_los", "Kontakt logs"),
		// roles: ["ROLE_CONTACTS"],
	},
	{
		path: "/admin/contacts/:id/:people_id",
		mainMenu: false,
		exact: true,
		component: Contact,
		name: i18n.t("routes.names.contact_edit", "Redigera kontakt"),
	},
	{
		path: "/admin/contacts/:id",
		mainMenu: false,
		exact: true,
		component: Contact,
		name: i18n.t("routes.names.contact_edit", "Redigera kontakt"),
		// roles: ["ROLE_CONTACTS"],
	},

	{
		path: "/admin/companies/:id/:people_id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
	},
	{
		path: "/admin/companies/:id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
	},
];
