import MyPage from "../views/Users/mypage";
import NotificationsIndex from "../views/Notifications/NotificationsIndex.js";
import AdwordAccountIndex from "../views/AdAccounts/GoogleIndex.js";
import FacebookAccountIndex from "../views/AdAccounts/FacebookIndex.js";
import AutomationIndex from "../views/Automations/AutomationIndex.js";
import AutomationEdit from "../views/Automations/AutomationEdit.js";
import AdsIndex from "../views/Ads/Ads.js";
import AdCampaignIndex from "../views/AdCampaigns/AdCampaigns.js";
import AdCampaignEdit from "../views/AdCampaigns/AdCampaignEdit.js";
import AdCreate from "../views/Ads/AdCreate.js";
import AdEdit from "../views/Ads/AdEdit.js";
import AdCompleteFacebook from "../views/Ads/AdCompleteFacebook.js";
import EmailCampaigns from "../views/EmailCampaigns/EmailCampaigns.js";
import EmailCampaignsCreate from "../views/EmailCampaigns/EmailCampaignCreate.js";
import EmailCampaignsTemplate from "../views/EmailCampaigns/EmailCampaignTemplate.js";
import EmailTemplate from "../views/EmailTemplates/Edit";
import EmailCampaignsEdit from "../views/EmailCampaigns/EmailCampaignEdit.js";
// import SettingsIndex from "./views/Settings/index.js";
import SupportSettings from "../views/Tickets/SupportSettings";
import ContactSettings from "../views/Contacts/ContactSettings.js";
import AccountIndex from "../views/Account/index.js";
import BillingIndex from "../views/Billing/index.js";

import Tracking from "../views/Integrations/Tracking.js";
import Triggers from "../views/Triggers/Triggers.js";
import TriggersCreate from "../views/Triggers/TriggersCreate.js";
import TriggersEdit from "../views/Triggers/TriggersEdit.js";

import Audiences from "../views/Audiences/Audiences.js";
import AudienceCreate from "../views/Audiences/AudienceCreate.js";
import AudienceEdit from "../views/Audiences/AudienceEdit.js";
import EmailContacts from "../views/EmailContacts/EmailContacts.js";
import IconsOverview from "../components/IconsOverview.js";
import Help from "../views/Help/index.js";
import HelpPage from "../views/Help/HelpPage.js";
import TagsIndex from "../views/Tags/index.js";
import VisitorsConversions from "../views/Visitors/VisitorsConversions.js";
import LanguagesIndex from "../views/Languages/index.js";
import EditLanguage from "../views/Languages/edit.js";
import { store } from "../store/index.js";
import CallLists from "../views/CallLists/index.js";
import ComponentRoutes from "../views/ComponentsOverview/ComponentRoutes.js";
import ApiUserTokens from "../views/ApiUserTokens";
import ScriveIndex from "../views/Scrive";
import ScriveDocumentView from "../views/Scrive/Document";

import ContractLicenses from "../views/Licenses/ContractLicenses";

import Dashboards from "../views/dashboards";
import i18n from "../i18n.js";
import MissingPermission from "../views/MissingPermission";
import checkUserRoles from "../Utilities/checkUserRoles";
import MissingLicense from "../views/MissingLicense";
import TestView from "../views/TestView";
import { getLocalStorage } from "../Utilities";

// import Dashboard from "./views/Dashboard/dashboard.js";
// import FortnoxArticleCreate from "./views/Fortnox/Articles/Create";

import targetAudience from "./Outreach/TargetAudiences";
import outboundEmail from "./Outreach/OutboundEmails";
import dialList from "./DialLists";
import prospectList from "./ProspectLists";
import target from "./Targets";
import customField from "./CustomFields";
import pricelist from "./PriceLists";
import category from "./Categories";
import articles from "./Articles";
import orders from "./Orders";
import pdfTemplates from "./PdfTemplates";
import groups from "./Groups";
import users from "./Users";
import usersActivity from "./UsersActivity";
import contacts from "./Contacts";
import integrations from "./Integrations";
import workspaces from "./Workspaces";

import fortnoxOrders from "./Fortnox/Orders";
import fortnoxOffers from "./Fortnox/Offers";
import fortnoxInvoices from "./Fortnox/Invoices";
import fortnoxContracts from "./Fortnox/Contracts";
import fortnoxArticles from "./Fortnox/Articles";
import fortnoxSettings from "./Fortnox/Settings";
import EmailTemplateList from "../views/EmailTemplates/List";

const getBaseRoutes = () => [
	{
		path: "/admin/dashboard/:type?",
		mainMenu: true,
		handle: "dashboard",
		exact: true,
		component: Dashboards,
	},
	{
		path: "/admin/audiences/:id(\\d+)",
		mainMenu: false,
		exact: true,
		component: AudienceEdit,
	},
	{
		path: "/admin/audiences/new",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: AudienceCreate,
		name: i18n.t("routes.names.audience_create", "Skapa målgrupp"),
	},
	{
		path: "/admin/audiences",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: Audiences,
		name: i18n.t("routes.names.audiences_old", "Målgrupper (utgående)"),
	},
	{
		path: "/admin/email_contacts",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: EmailContacts,
		name: i18n.t("routes.names.email_contacts", "E-post kontakter"),
	},
	{
		path: "/admin/ads/complete_facebook/:id(\\d+)",
		mainMenu: true,
		handle: "ad facebook",
		exact: true,
		component: AdCompleteFacebook,
	},
	{
		path: "/admin/ads/:id(\\d+)",
		mainMenu: false,
		exact: true,
		component: AdEdit,
	},
	{
		path: "/admin/ads/new",
		mainMenu: true,
		handle: "ads",
		exact: true,
		component: AdCreate,
		name: i18n.t("routes.names.ad_create", "Skapa annons"),
	},
	{
		path: "/admin/ads",
		mainMenu: true,
		handle: "ads",
		exact: true,
		component: AdsIndex,
		name: i18n.t("routes.names.ads", "Annonser"),
	},
	{
		path: "/admin/ad_campaigns",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: AdCampaignIndex,
		name: i18n.t("routes.names.ad_campaigns", "Annonskampanjer"),
	},
	{
		path: "/admin/ad_campaigns/:id(\\d+)",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: AdCampaignEdit,
	},
	{
		path: "/admin/me",
		mainMenu: true,
		handle: "me",
		exact: true,
		component: MyPage,
		name: i18n.t("routes.names.my_page", "Min sida"),
	},
	{
		path: "/admin/scrive/documents/:id(\\d+)",
		mainMenu: true,
		handle: "scrive Document",
		exact: true,
		component: ScriveDocumentView,
	},
	{
		path: "/admin/scrive/documents",
		mainMenu: true,
		handle: "scrive Documents",
		exact: true,
		component: ScriveIndex,
		name: i18n.t("routes.names.scrive", "Scrive"),
	},

	{
		path: "/admin/notifications",
		mainMenu: true,
		handle: "tasks",
		exact: true,
		component: NotificationsIndex,
		name: i18n.t("routes.names.notifications", "Notifikationer"),
	},
	{
		path: "/admin/call_lists",
		mainMenu: true,
		exact: true,
		component: CallLists,
		name: i18n.t("routes.names.call_lists", "Ring-listor"),
	},
	{
		path: "/admin/google/accounts",
		handle: "accounts",
		exact: true,
		component: AdwordAccountIndex,
		name: i18n.t("routes.names.google_accounts", "Google konton"),
	},
	{
		path: "/admin/facebook/accounts",
		mainMenu: true,
		handle: "accounts",
		exact: true,
		component: FacebookAccountIndex,
		name: i18n.t("routes.names.facebook_accounts", "Facebook konton"),
	},
	{
		path: "/admin/email_campaigns",
		mainMenu: true,
		handle: "email_campaigns",
		exact: true,
		component: EmailCampaigns,
		name: i18n.t("routes.names.email_campaigns", "E-postkampanjer"),
		// roles: ["ROLE_MARKETING_EMAIL_CAMPAIGNS"],
	},
	{
		path: "/admin/automations/:id",
		mainMenu: true,
		handle: "automations",
		exact: true,
		component: AutomationEdit,
		name: i18n.t("routes.names.automation_edit", "Redigera automatisering"),
	},
	{
		path: "/admin/automations",
		mainMenu: true,
		handle: "automations",
		exact: true,
		component: AutomationIndex,
		name: i18n.t("routes.names.automations", "Automatiseringar"),
	},
	{
		path: "/admin/conversions",
		mainMenu: false,
		exact: true,
		component: VisitorsConversions,
		name: i18n.t("routes.names.visitors_conversions", "Besökare och konverteringar"),
	},
	{
		path: "/admin/email_campaigns/new",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsCreate,
		name: i18n.t("routes.names.email_campaign_create", "Skapa e-postkampanj"),
	},
	{
		path: "/admin/test",
		mainMenu: false,
		exact: true,
		component: TestView,
		name: "test",
		enabled: [2, 3, 419, 418].includes(store.getState().user?.id),
	},
	{
		path: "/admin/email_campaigns/new/template",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_campaigns/new/template/:id",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_templates/create",
		mainMenu: false,
		exact: true,
		component: EmailTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_templates/:id",
		mainMenu: false,
		exact: true,
		component: EmailTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_templates",
		mainMenu: false,
		exact: true,
		component: EmailTemplateList,
		name: i18n.t("routes.names.email_campaign_templates", "E-postmallar"),
	},
	{
		path: "/admin/email_campaigns/:id",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsEdit,
		name: i18n.t("routes.names.email_campaign_edit", "Redigera e-postkampanj"),
	},
	...contacts(),
	...integrations(),
	...workspaces(),

	{
		path: "/admin/account",
		settings: true,
		description: "Hantera kontoinställningar och profilering",
		exact: true,
		component: AccountIndex,
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
		name: i18n.t("routes.names.account", "Kontoinställningar"),
	},
	{
		path: "/admin/tags",
		description: "Hantera företag och person taggar",
		exact: true,
		component: TagsIndex,
		name: i18n.t("routes.names.tags", "Taggar"),
	},
	{
		path: "/admin/billing",
		settingsa: true,
		description: "Visar faktureringhistorik till oss",
		exact: true,
		component: BillingIndex,
		name: i18n.t("routes.names.billing", "Fakturering"),
	},
	{
		path: "/admin/triggers",
		settingsa: true,
		description: "Hantera automatiska jobb som utför handlingar när något händer i systemet",
		exact: true,
		component: Triggers,
		name: i18n.t("routes.names.triggers", "Automationer"),
	},
	{
		path: "/admin/languages",
		settings: true,
		description: "Översättningar för språk",
		exact: true,
		component: LanguagesIndex,
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
		enabled: [24, 1514].includes(store.getState().account.id) || [2, 3, 418, 419].includes(store.getState().user.id),
		name: i18n.t("routes.names.languages", "Språk"),
		// enabled: store.getState().user.roles.includes(["ROLE_TRANSLATION"]),
	},
	...usersActivity(),
	{
		path: "/admin/languages/:id",
		description: "Översättningar för språk",
		exact: true,
		component: EditLanguage,
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
		enabled: [24, 1514].includes(store.getState().account.id) || [2, 3, 418, 419].includes(store.getState().user.id),
		name: i18n.t("routes.names.languages", "Språk"),
		// enabled: store.getState().user.roles.includes(["ROLE_TRANSLATION"]),
	},

	{
		path: "/admin/tracking-chat",
		settingsa: true,
		description: "Spåra dina besökare från hemsidan",
		exact: true,
		component: Tracking,
		name: i18n.t("routes.names.tracking", "Spårning"),
	},
	{
		path: "/admin/servicedesk/settings",
		settings: true,
		name: "Servicedesk",
		description: i18n.t("routes.names.servicedesk.settings", "Hantera din servicedesk och köer här"),
		exact: true,
		component: SupportSettings,
	},
	{
		path: "/admin/contact-settings",
		settings: true,
		description: "Konfigurera extra data-fält, aktiviteter, etc. för dina kontakter",
		exact: true,
		component: ContactSettings,
		name: i18n.t("routes.names.contact_settings", "Kontaktinställningar"),
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
	},
	{
		path: "/admin/triggers/new",
		exact: true,
		component: TriggersCreate,
		name: i18n.t("routes.names.triggers_create", "Skapa automation"),
	},
	{
		path: "/admin/triggers/:id",
		exact: true,
		component: TriggersEdit,
		name: i18n.t("routes.names.triggers_edit", "Redigera automation"),
	},

	...users(),
	...groups(),
	// {
	// 	path: "/admin/settings",
	// 	mainMenu: true,
	// 	settings: true,
	// 	exact: true,
	// 	component: SettingsIndex,
	// 	name: i18n.t("routes.names.settings", "Inställningar"),
	// },
	{
		path: "/admin/help",
		exact: true,
		component: Help,
		name: i18n.t("routes.names.help", "Hjälp"),
	},
	{
		path: "/admin/help/:id",
		exact: true,
		component: HelpPage,
		name: i18n.t("routes.names.help", "Hjälp"),
	},
	{
		path: "/admin/icons",
		mainMenu: true,
		exact: true,
		component: IconsOverview,
		name: i18n.t("routes.names.icons", "Ikonöversikt"),
	},

	...pdfTemplates(),

	{
		path: "/admin/licenses",
		mainMenu: false,
		exact: true,
		component: ContractLicenses,
		name: i18n.t("routes.names.licenses", "Licenser"),
	},

	...fortnoxOffers(),
	...fortnoxOrders(),
	...fortnoxInvoices(),
	...fortnoxContracts(),
	...fortnoxArticles(),
	...fortnoxSettings(),

	...orders(),
	...articles(),
	...pricelist(),
	...category(),
	...customField(),
	...target(),
	...prospectList(),
	...dialList(),
	...targetAudience(),
	...outboundEmail(),
	{
		path: "/admin/api_user_tokens",
		exact: true,
		component: ApiUserTokens,
		name: i18n.t("routes.names.api_user_tokens", "API användartokens"),
	},
	{
		redirect: true,
		path: "/admin/contacts",
		to: "/admin/contacts/companies",
		name: i18n.t("routes.names.contacts", "Kontakter"),
	},
	{
		path: "/admin/components",
		component: ComponentRoutes,
		enabled:
			process.env.NODE_ENV === "development" ||
			String(store.getState().account.id) === String(24) ||
			String(store.getState().user.id) === String(419) ||
			getLocalStorage("components_route_enabled"),
		name: i18n.t("routes.names.components", "Komponenter"),
	},
	{
		path: "/admin/missing_permission",
		name: i18n.t("routes.names.missing_permission", "Saknar behörighet"),
		exact: true,
		component: MissingPermission,
	},
	{
		path: "/admin/403",
		name: i18n.t("routes.names.missing_permission", "Saknar behörighet"),
		exact: true,
		component: MissingPermission,
	},
	{
		path: "/admin/406",
		name: i18n.t("routes.names.missing_license", "Saknar Licens"),
		exact: true,
		component: MissingLicense,
	},
	{
		redirect: true,
		path: "/admin/",
		to: "/admin/dashboard",
		name: i18n.t("routes.names.dashboard", "Dashboard"),
	},
];

const getRoutes = () => {
	return getBaseRoutes().filter((route: any) => {
		return (
			(!("enabled" in route) || route.enabled) &&
			// (!("admin" in route) || !route.admin || store.getState().user?.roles?.some((role) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(role))) &&
			checkUserRoles(route.roles)
		);
	});
};

export default getRoutes;

export const routesAsObject = getBaseRoutes().reduce((acc, route) => {
	acc[route.path] = route;
	return acc;
}, {});
