/* eslint-disable no-unreachable */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";

import { useSelector } from "react-redux";

import BoardHelper from "src/js/views/Workspaces/BoardHelper";
import RowModalForm from "src/js/views/Workspaces/components/RowModalForm";
import RowCommentForm from "src/js/views/Workspaces/components/RowCommentForm";
import RowTicketForm from "src/js/views/Workspaces/components/RowTicketForm";
import RowVisitorForm from "src/js/views/Workspaces/components/RowVisitorForm";
import RowContactForm from "src/js/views/Workspaces/components/RowContactForm";
import RowColumnForm from "src/js/views/Workspaces/components/RowColumnForm";
import RowActivityLog from "src/js/views/Workspaces/components/RowActivityLog";
import RowModalFormActions from "src/js/views/Workspaces/BoardRow/RowModalFormActions";
import Tabs from "src/js/components/Tabs";
import Toast from "src/js/components/Toast";

import BoardRowComponent from "src/js/components/BoardRowComponent";
import { formatDeadlineRows } from "src/js/views/Workspaces/boardutils/BoardUtils";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { useQueryClient } from "@tanstack/react-query";

const RowSheetContent = ({
	initialTabIndex = 0,
	row,
	board,
	contact,
	onClose,
	onUpdateRow,
	onUpdateValue,
	onComplete,
	onCompleteAwaited,
	options,
	contacts,
	groups,
	defaultValues,
	defaultOptions,
	external,
	history,
	open,
	onCreateRow,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [tickets, setTickets] = useState<any[]>([]);
	const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);

	const { t } = useTranslation(["row", "common"]);

	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);

	const queryClient = useQueryClient();

	const linkedRowsGroupedByBoardKey = useMemo(() => [`/api/boards/${board?.id}/rows/${row?.id}/linked_rows.json`], [board?.id, row?.id]);
	const { data: linkedRowsGroupedByBoard = [] } = useQuery({
		queryKey: linkedRowsGroupedByBoardKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/boards/${board.id}/rows/${row.id}/linked_rows.json`);
				return res.data;
			} catch (e) {
				Toast.error(e);
			}
		},
		enabled: !!open && !!row?.id,
		initialData: queryClient.getQueryData(linkedRowsGroupedByBoardKey) || [],
	});

	const commentsKey = useMemo(() => [`/api/boards/${board?.id}/rows/${row?.id}/comments.json`], [board?.id, row?.id]);
	const {
		data: comments = [],
		isFetching: loadingComments,
		refetch: refetchCommentsAndTickets,
	} = useQuery({
		queryKey: commentsKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/boards/${board.id}/rows/${row.id}/comments.json`);
				setTickets(res.data.tickets);
				return res.data.comments;
			} catch (e) {
				Toast.error(e);
			}
		},
		enabled: !!open && !!row?.id,
		initialData: queryClient.getQueryData(commentsKey) || [],
	});

	const activitiesKey = useMemo(() => [`/api/boards/${board?.id}/rows/${row?.id}/activities.json`], [board?.id, row?.id]);
	const { data: activities = [], isFetching: loadingActivity } = useQuery({
		queryKey: activitiesKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/boards/${board.id}/rows/${row.id}/activities.json`);

				return res.data.activities;
			} catch (e) {
				Toast.error(e);
			}
		},
		enabled: !!open && !!row?.id,
		initialData: queryClient.getQueryData(activitiesKey) || [],
	});

	const setComments = useCallback(
		(arg) => {
			queryClient.setQueryData(commentsKey, typeof arg === "function" ? arg(comments) : arg);
		},
		[comments, commentsKey, queryClient]
	);
	// const setActivities = (arg) => {
	// 	queryClient.setQueryData(activitiesKey, typeof arg === "function" ? arg(comments) : arg);
	// };
	const setLinkedRowsGroupedByBoard = useCallback(
		(arg) => {
			queryClient.setQueryData(linkedRowsGroupedByBoardKey, typeof arg === "function" ? arg(linkedRowsGroupedByBoard) : arg);
		},
		[linkedRowsGroupedByBoard, linkedRowsGroupedByBoardKey, queryClient]
	);

	useEffect(() => {
		if (open) {
			setSelectedTabIndex(initialTabIndex);
		}
	}, [open, initialTabIndex]);

	const tabs = useMemo(() => {
		const tabs: any[] = [
			{
				id: "row",
				content: user.calendar_board?.id === board?.id ? t("row.terms.info", "Info") : board?.singular || t("row.singular", "Rad"),
				panelID: "row",
				board,
				row,
				contact,
			},
		];

		if (row) {
			tabs.push({
				id: "comment",
				board,
				row,
				content: t("row.terms.internal_comment", "Intern kommentar"),
				panelID: "comment",
			});

			// Process columns
			board?.columns?.forEach((column) => {
				if (column.type === "ticket" || column.type === "visitor" || column.type === "contact") {
					const values = BoardHelper.getColumnValues(row, column);
					values.forEach((value) => {
						value.value.forEach((item) => {
							const entity = BoardHelper[`get${column.type.charAt(0).toUpperCase() + column.type.slice(1)}`](item);
							if (entity) {
								tabs.push({
									id: `${column.type}_${entity.id}`,
									content: column.type === "ticket" ? `#${entity.number}` : entity.name || entity.fullname,
									[column.type]: entity,
									panelId: `${column.type}${entity.id}`,
								});
							}
						});
					});
				}

				if (column.type === "upload") {
					tabs.push({
						id: `upload_${column.id}`,
						column,
						content: column.title,
						panelID: `upload_${column.id}`,
					});
				}
			});

			linkedRowsGroupedByBoard.forEach(({ board: linkedBoard, rows }: { board: BoardType; rows: BoardRowType[] }) => {
				tabs.push({
					id: `board_${linkedBoard.id}_linked_rows`,
					board: linkedBoard,
					rows,
					content: ((linkedBoard.plural || linkedBoard.title) as any)?.capitalize(),
					panelID: `board_${linkedBoard.id}_linked_rows`,
				});
			});

			// Add activity tab
			tabs.push({
				id: "activity",
				board,
				row,
				content: t("row.terms.log", "Logg"),
				panelID: "activity",
			});
		}

		return tabs;
	}, [board, row, linkedRowsGroupedByBoard, t, contact, user.calendar_board?.id]);

	const getContent = useCallback(
		(tab) => {
			if (!tab) return null;

			switch (true) {
				case tab.id === "row":
					return (
						<>
							<RowModalFormActions
								row={row}
								board={board}
								onClose={onClose}
								// onCreateRow={onCreateRow}
								onUpdateRow={onUpdateRow}
								// onUpdateValue={onUpdateValue}
								// onRemoveRow={onRemoveRow}
								// onRemove={onRemove}
								// onUpdate={onUpdate}
								// onChange={onChange}
								// onCreate={onCreate}
								onComplete={onComplete}
							/>
							<InnerWrapper>
								<RowModalForm
									options={options}
									onClose={onClose}
									row={row}
									contact={contact}
									contacts={contacts}
									loading={loadingComments}
									board={board}
									groups={groups}
									defaultValues={defaultValues}
									defaultOptions={defaultOptions}
									onComplete={onComplete}
									onCompleteAwaited={onCompleteAwaited}
									onUpdateRow={onUpdateRow}
									onUpdateValue={onUpdateValue}
									onCreateRow={(row) => {
										if (onCreateRow) onCreateRow(row);
										onClose();
									}}
								/>
								{row && (
									<RowCommentForm
										initialTabIndex={initialTabIndex}
										comments={comments.filter((c) => tab.board?.id === c.board.id)}
										row={tab.row}
										board={tab.board}
										onCreate={(comment) => setComments((prev) => [comment, ...prev])}
										onUpdate={(comment, index) => setComments((prev) => prev.map((c, i) => (i === index ? comment : c)))}
										onRemove={(comment) => setComments((prev) => prev.filter((c) => c.id !== comment.id))}
									/>
								)}
							</InnerWrapper>
						</>
					);

				case !!tab.ticket: {
					const ticket = tickets.find((ticket) => tab?.ticket?.id == ticket.id) || tab?.ticket;
					if (!ticket) refetchCommentsAndTickets();

					return (
						<RowTicketForm
							row={row}
							loading={loadingComments}
							board={board}
							ticket={ticket}
							updateTicket={(updated) =>
								setTickets((prev) =>
									prev.some((t) => t.id === updated.id) ? prev.map((t) => (t.id === updated.id ? updated : t)) : [...prev, updated]
								)
							}
						/>
					);
				}
				case !!tab.visitor:
					return <RowVisitorForm row={row} session_id={tab.visitor.id} board={board} contact={tab.visitor} />;

				case !!tab.contact:
					return <RowContactForm external={external} row={row} loading={loadingComments} board={board} contact={tab.contact} />;

				case !!tab.column:
					return <RowColumnForm row={row} column={tab.column} board={board} history={history} />;

				case tab.id === "activity":
					return <RowActivityLog row={row} loading={loadingActivity} board={board} events={activities} />;

				case !!tab.rows: {
					const deadlineRows = [user.todo_board_id, account.todo_board_id, user.calendar_board_id].includes(tab.board?.id)
						? formatDeadlineRows({ rows: [...tab.rows], boards: [tab.board] }).sort(
								(a, b) => new Date(b.deadline.date).getTime() - new Date(a.deadline.date).getTime()
						  )
						: tab.rows;

					return deadlineRows.map((row) => (
						<BoardRowComponent
							key={row.key}
							row={row}
							board={tab.board}
							onUpdate={(updated) =>
								setLinkedRowsGroupedByBoard((prev: any) =>
									prev
										.map((grp) =>
											grp.board?.id === tab.board?.id
												? {
														...grp,
														rows: grp.rows.map((r) => {
															if (r.id === row.id && !updated) return null;

															return r.id === updated.id ? updated : r;
														}),
												  }
												: grp
										)
										.filter(Boolean)
								)
							}
						/>
					));
				}
				default:
					return (
						<RowCommentForm
							initialTabIndex={initialTabIndex}
							comments={comments.filter((c: any) => tab.board?.id === c.board.id)}
							row={tab.row}
							board={tab.board}
							onCreate={(comment: any) => setComments((prev: any) => [comment, ...prev])}
							onUpdate={(comment: any, index) => setComments((prev: any) => prev.map((c, i) => (i === index ? comment : c)))}
							onRemove={(comment: any) => setComments((prev: any) => prev.filter((c) => c.id !== comment.id))}
						/>
					);
			}
		},
		[
			row,
			board,
			comments,
			activities,
			loadingComments,
			loadingActivity,
			initialTabIndex,
			contact,
			contacts,
			account.todo_board_id,
			defaultOptions,
			defaultValues,
			external,
			groups,
			history,
			onClose,
			onComplete,
			onCompleteAwaited,
			onUpdateRow,
			onUpdateValue,
			options,
			user.calendar_board_id,
			user.todo_board_id,
			setComments,
			setLinkedRowsGroupedByBoard,
			tickets,
			refetchCommentsAndTickets,
			onCreateRow,
		]
	);

	// const tabs = getTabs();
	const isCalendar = user.calendar_board?.id === board?.id;
	const showTabs = row?.id;

	return (
		<div className={`row-sheet ${BoardHelper.isSupportBoard(row?.board_id) && selectedTabIndex === 1 ? "support-ticket" : ""}`}>
			{!isCalendar ? (
				showTabs ? (
					<Tabs tabs={tabs} selected={selectedTabIndex} onSelect={setSelectedTabIndex}>
						<div className="content-wrapper">{getContent(tabs[selectedTabIndex])}</div>
					</Tabs>
				) : (
					<div className="content-wrapper">{getContent(tabs[selectedTabIndex])}</div>
				)
			) : (
				getContent(tabs.find((tab) => tab.id === "row"))
			)}
		</div>
	);
};

export default withTranslation(["row", "common"], { withRef: true })(RowSheetContent);

const InnerWrapper = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;

	> * {
		flex: 1 1 450px;
	}
`;
