import i18n from "src/js/i18n";
import ProspectCreate from "src/js/views/Prospect/Create";
import ProspectList from "src/js/views/Prospect/List";
import ProspectView from "src/js/views/Prospect/View";

export default () => [
	{
		exact: true,
		path: "/admin/prospect_lists/create",
		name: i18n.t("routes.names.prospects.list.create", "Skapa prospects"),
		component: ProspectCreate,
	},
	{
		path: "/admin/prospect_lists/:id/:view?/:prospectId?",
		name: i18n.t("routes.names.prospects.list.view", "Hantera prospects"),
		component: ProspectView,
	},
	{
		path: "/admin/prospect_lists",
		name: i18n.t("routes.names.prospects.list", "Prospects"),
		exact: true,
		component: ProspectList,
	},
];
