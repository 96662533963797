import React from "react";
import { ButtonGroup, Labelled } from "@shopify/polaris";
import Button from "../Button";

type MultiSwitchOption = {
	label: string;
	value: string;
	disabled?: boolean;
};

type MultiSwitchProps = {
	label?: string;
	options: MultiSwitchOption[];
	value: string;
	disabled?: boolean;
	onChange: (value: string) => void;
};

// eslint-disable-next-line react/jsx-props-no-spreading
const MultiSwitch = ({ label, options, value, disabled, onChange }: MultiSwitchProps) => {
	const content = (
		<>
			<ButtonGroup segmented>
				{options?.map((opt) => {
					const active = value === opt.value;

					return (
						<Button
							key={`${opt.label}_${opt.value}`}
							primary={active}
							pressed={active}
							onClick={() => onChange(opt.value)}
							disabled={opt.disabled || disabled}
						>
							{opt.label}
						</Button>
					);
				})}
			</ButtonGroup>
		</>
	);

	if (!label) return content;

	return (
		<Labelled id={label} label={label}>
			{content}
		</Labelled>
	);
};
export default MultiSwitch;
