import { FollowUpEmailMajor, SendMajor, MobileAcceptMajor } from "@shopify/polaris-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Spinner from "src/js/components/Spinner";
import Banner from "src/js/components/banner";
import ProgressSteps from "src/js/components/progressSteps";

const BatchHeader = ({ outBoundEmailBatch, isLoading }: { outBoundEmailBatch: OutboundEmailBatchType; isLoading: boolean }) => {
	const { t } = useTranslation(["outbound"]);
	const statuses = [
		{
			// label: t("outbound.BatchHeader.status.dispatched_at.label", "Utskick påbörjat"),
			active: !!outBoundEmailBatch?.dispatched_at,
			tooltip: t("outbound.BatchHeader.status.dispatched_at.tooltip", "Utskick initierad {{date}}", {
				date: outBoundEmailBatch?.dispatched_at && `(${outBoundEmailBatch?.dispatched_at})`,
			}),
			color: Colors.green,
			icon: isLoading && !outBoundEmailBatch ? <Spinner size="small" /> : SendMajor,
		},
		{
			// label: t("outbound.BatchHeader.status.validation_started_at.label", "Validering påbörjad"),
			active: !!outBoundEmailBatch?.validation_started_at,
			tooltip: t("outbound.BatchHeader.status.validation_started_at.tooltip", "Validering påbörjad {{date}}", {
				date: outBoundEmailBatch?.validation_started_at && `(${outBoundEmailBatch?.validation_started_at})`,
			}),
			color: Colors.green,
			icon: MobileAcceptMajor,
		},
		{
			// label: t("outbound.BatchHeader.status.validation_completed_at.label", "Validering klar"),
			active: !!outBoundEmailBatch?.validation_completed_at,
			tooltip: t("outbound.BatchHeader.status.validation_completed_at.tooltip", "Validering klar {{date}}", {
				date: outBoundEmailBatch?.validation_completed_at && `(${outBoundEmailBatch?.validation_completed_at})`,
			}),
			color: Colors.green,
			icon: MobileAcceptMajor,
		},
		{
			// label: t("outbound.BatchHeader.status.sent_started_at.label", "Utskick till företag/kontakter påbörjat"),
			active: !!outBoundEmailBatch?.sent_started_at,
			tooltip: t("outbound.BatchHeader.status.sent_started_at.tooltip", "Utskick till företag/kontakter påbörjat {{date}}", {
				date: outBoundEmailBatch?.sent_started_at && `(${outBoundEmailBatch?.sent_started_at})`,
			}),
			color: Colors.green,
			icon: FollowUpEmailMajor,
		},
		{
			// label: t("outbound.BatchHeader.status.sent_completed_at.label", "Utskick till företag/kontakter klart"),
			active: !!outBoundEmailBatch?.sent_completed_at,
			tooltip: t("outbound.BatchHeader.status.sent_completed_at.tooltip", "Utskick till företag/kontakter klart {{date}}", {
				date: outBoundEmailBatch?.sent_completed_at && `(${outBoundEmailBatch?.sent_completed_at})`,
			}),
			color: Colors.green,
			icon: FollowUpEmailMajor,
		},
	];
	return (
		<div>
			<ProgressSteps dontFill statuses={statuses} />
			{outBoundEmailBatch?.validation_error && (
				<Banner style={{ marginBottom: "0.635rem" }} title={t("outbound.BatchHeader.validation_error", "Valideringsfel")} status="critical">
					<p>{t("outbound.BatchHeader.validation_error_message", "Valideringen misslyckades. Försök igen eller kontakta support.")}</p>
				</Banner>
			)}
			{outBoundEmailBatch?.sent_error && (
				<Banner style={{ marginBottom: "0.635rem" }} title={t("outbound.BatchHeader.sent_error", "Fel vid skickning av utskick")} status="critical">
					<p>{t("outbound.BatchHeader.sent_error_message", "Skicka misslyckades. Försök igen eller kontakta support.")}</p>
				</Banner>
			)}
		</div>
	);
};
export default BatchHeader;
