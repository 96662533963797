import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@shopify/polaris";
import { FIRST_COLUMN_WIDTH } from "../views/Workspaces/components/BoardUploadSheet";

//! ProgressSteps.tsx is the new component which is the same as this, BUT the label is rendered in the box aswell.
class IntegrationProgress extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { title, statuses, children } = this.props;
		const aciveStatusesIndexes =
			statuses.reduce((acc, i, index) => {
				if (i.active) acc.push(index);
				return acc;
			}, []) || [];
		const lastActiveIndex = aciveStatusesIndexes && !!aciveStatusesIndexes.length && Math.max(...aciveStatusesIndexes);

		return (
			<React.Fragment>
				<SciveItem onClick={this.props.onClick} style={this.props.style}>
					{title && <Title width={FIRST_COLUMN_WIDTH}>{title}</Title>}{" "}
					<ScriveProgressWrapper minWidth={statuses.length * 50}>
						{statuses.map((status, index) => (
							<ProgressStep
								label={status.label}
								tooltip={status.tooltip}
								zIndex={statuses.length - index}
								key={index}
								className={
									this.props.dontFill
										? status.active
											? "active"
											: ""
										: typeof lastActiveIndex === "number" && index <= lastActiveIndex
										? "active"
										: ""
								}
								backgroundColor={status.backgroundColor}
								color={status.color}
							>
								<LabelWrapper>{React.isValidElement(status.icon) ? status.icon : <Icon source={status.icon} />}</LabelWrapper>
							</ProgressStep>
						))}
					</ScriveProgressWrapper>
				</SciveItem>
				{children}
			</React.Fragment>
		);
	}
}
export default IntegrationProgress;

const SciveItem = styled.div`
	display: flex;
	gap: 0.625rem;
	padding: 0.625rem 0;
	align-items: center;

	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}
`;

const ScriveProgressWrapper = styled.div`
	border-radius: 6px;
	background-color: var(--main3);
	display: flex;
	width: 100%;
	height: 35px;
	box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
	min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "400px")};

	div.active:last-of-type {
		/* background-color: red; */
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

const ProgressStep = styled.div`
	transition: background 500ms;
	background-color: transparent;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	z-index: ${({ zIndex }) => zIndex};

	&::after {
		content: "${({ label, tooltip }) => tooltip || label}";
		text-transform: capitalize;
		/* content: "asdasd"; */
		position: absolute;
		bottom: 0;
		transform: translateY(50%);
		background: var(--main3);
		padding: 0.3125rem 0.4688rem;
		border-radius: 5px;
		font-size: 0.7188rem;
		transition: opacity 100ms, transform 200ms;
		pointer-events: none;
		opacity: 0;
	}

	&:hover::after {
		transform: translateY(125%);
		opacity: 1;
		/* pointer-events: auto; */

		svg {
			opacity: 1;
		}
	}

	&:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	&::before {
		content: "";
		inset: 0;
		transition: all 500ms;
		position: absolute;
		transform: translateX(-100%);
	}

	&.active {
		&::before {
			background-color: ${({ backgroundColor, color }) => backgroundColor || color || "#5ac57d"};
			transform: translateX(0%);
		}
		/* &.last {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		} */

		svg {
			opacity: 1;
		}
	}

	.Polaris-Icon {
		height: 15px;
		width: 15px;
	}

	svg {
		transition: opacity 250ms;
		opacity: 0.75;
		width: 15px;
		height: 15px;

		fill: #ffffff !important;
	}
`;

const Title = styled.span`
	width: ${({ width }) => width};
	text-align: center;
	/* margin-right: 0.6250rem; */
`;

const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3125rem;
	justify-content: center;
	font-weight: 500;
`;
