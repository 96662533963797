import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Badge from "src/js/components/Badge";
import IndexTable from "src/js/components/IndexTable";

const OutboundEmailList = () => {
	const { t } = useTranslation(["outreach", "common"]);
	const history = useHistory();

	const columns = [
		{
			handle: "TITLE",
			label: t("outreach.outbound_email.fields.title", "Titel"),
			render: (row) => (
				<>
					{row.title}
					{row.removed && <Badge status="critical">{t("outreach.outbound_email.removed", "Borttagen")}</Badge>}
				</>
			),
		},
		{
			handle: "LATEST_BATCH_CREATED_AT",
			label: t("outreach.outbound_email.fields.latest_batch_created_at", "Senaste utskick"),
			render: (row) => row.latest_batch?.dispatched_at,
			sortable: true,
		},
		{
			handle: "UPDATED_AT",
			label: t("outreach.outbound_email.fields.updated_at", "Uppdaterad"),
			render: (row) => row.updated_at,
			sortable: true,
		},
		{
			handle: "CREATED_AT",
			label: t("outreach.outbound_email.created_at.title", "Skapad"),
			render: (row) => row.created_at,
			sortable: true,
		},
	];

	return (
		<IndexTable
			filterKey="outbound_emails"
			title={t("outreach.outbound_email.plural", "E-postutskick") as string}
			savedSearchHandle="outbound_emails"
			resourceUrl="/api/outbound_emails.json"
			resourceHandle="outbound_emails"
			resourceName={{
				singular: t("outreach.outbound_email.singular", "E-postutskick"),
				plural: t("outreach.outbound_email.plural", "E-postutskick"),
			}}
			columns={columns}
			primaryAction={{
				content: t("outreach.outbound_email.create", "Skapa e-postutskick") as string,
				url: "/admin/outreach/outbound_emails/create",
			}}
			onClick={(row) => {
				history.push(`/admin/outreach/outbound_emails/${row.id}`);
			}}
		/>
	);
};
export default OutboundEmailList;
