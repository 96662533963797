import React, { useState, useRef, useCallback } from "react";
import { Checkbox, FormLayout } from "@shopify/polaris";
import EmailEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { EmailTemplateContextProvider, useEmailTemplateContext } from "src/js/views/EmailTemplates/Context";
import Page from "src/js/components/page";
import TextField from "src/js/components/TextField";
import styled, { css } from "styled-components";
import Banner from "src/js/components/banner";

const EmailTemplate = () => {
	const { id, emailTemplate, form, isLoading, handleSave, isSaving, setForm, handleDuplicate, isFetched, isFetchedAfterMount, isPlaceholderData } =
		useEmailTemplateContext();

	const { t } = useTranslation(["email_templates", "common"]);

	const [loaded, setLoaded] = useState(false);

	const editorRef = useRef(null);
	const designLoaded = useRef(false);

	const onLoadEditor = useCallback(() => {
		if (editorRef.current && id && !designLoaded.current && emailTemplate?.design) {
			(editorRef.current as any).loadDesign(emailTemplate?.design);
			designLoaded.current = true;
		}
	}, [editorRef, id, emailTemplate]);

	// useEffect(() => {
	// 	onLoadEditor();
	// }, [onLoadEditor]);

	const onDesignLoaded = () => {
		onLoadEditor();
		setLoaded(true);
	};

	const saveForm = async () => {
		if (editorRef.current) {
			const editorData = (await new Promise((resolve, reject) => {
				(editorRef.current as any).exportHtml((data) => {
					resolve(data);
				});
			})) as any;

			handleSave({ content: editorData.html, design: editorData.design });
		}
	};

	const getChangeHandler = (key) => (value) => {
		setForm((form) => ({
			...form,
			[key]: value,
		}));
	};

	const title = (() => {
		if (form?.is_template) {
			return id ? t("email_templates.edit.title", "Redigera e-postmall") : t("email_templates.create.title", "Skapa e-postmall");
		}

		return id ? t("email_templates.edit.title_design", "Redigera design") : t("email_templates.create.title", "Skapa e-postmall");
	})();

	return (
		<Page
			title={title}
			loading={isLoading}
			primaryAction={{
				content: t("common.actions.save", "Spara") as string,
				disabled: !loaded || isLoading || isSaving || form?.is_default,
				onClick: saveForm,
			}}
			secondaryActions={[
				{
					content: t("email_template.actions.duplicate.label", "Duplicera e-postmall"),
					onAction: handleDuplicate,
					enabled: !!id,
				},
			]}
		>
			{form.is_default && (
				<Banner
					title={t("email_template.fields.is_default.warning", `Kan inte redigera "Standard mallar"`)}
					status="warning"
					style={{ marginBottom: "2rem" }}
				>
					<p>Detta är en standard mall, för att redigera klicka först på duplicera.</p>
				</Banner>
			)}
			<FormLayout>
				<TextField
					label={t("email_template.fields.title", "Titel")}
					value={form?.title}
					onChange={getChangeHandler("title")}
					disabled={form?.is_default}
				/>
				{id && (
					<Checkbox
						label={t("email_template.fields.is_template.label", "Är mall")}
						checked={form?.is_template}
						onChange={getChangeHandler("is_template")}
						helpText={t("email_template.fields.is_template.helpText", "Kan väljas vid skapning av e-postutskick")}
						disabled={!id || form?.is_default}
					/>
				)}

				{((isFetched && isFetchedAfterMount) || !id) && !isPlaceholderData && (
					<EmailEditorWrapper>
						<EmailEditor
							ref={editorRef}
							locale="sv-SE"
							appearance={{
								theme: "dark",
								panels: {
									tools: {
										dock: "left",
									},
								},
							}}
							onLoad={onLoadEditor}
							onDesignLoad={onDesignLoaded}
						/>
					</EmailEditorWrapper>
				)}
			</FormLayout>
		</Page>
	);
};

export default (props) => {
	return (
		<EmailTemplateContextProvider {...props}>
			<EmailTemplate {...props} />
		</EmailTemplateContextProvider>
	);
};

const EmailEditorWrapper = styled.div`
	${() => {
		const topbarHeight = document.querySelector("#AppFrameTopBar")?.getBoundingClientRect()?.height;

		return css`
			height: ${() => `calc(100vh - ${topbarHeight}px - 10px)`};
			& > div {
				height: ${() => `calc(100vh - ${topbarHeight}px - 10px)`};
			}
		`;
	}}
`;
