import React, { use, useCallback, useEffect, useMemo, useRef, useState } from "react";
import OutboundEmailContext, { OutboundEmailContextProvider } from "../Context/OutboundEmailContext";
import Page from "src/js/components/page";
import TextField from "src/js/components/TextField";
import { ButtonGroup, FormLayout, Layout } from "@shopify/polaris";
import Button from "src/js/components/Button";
import EmailTemplateModal from "../components/EmailTemplateModal";
import { ButtonsWrapper, IframeWrapper } from "../styles";
import BASE_URL from "src/js/API/BASE_URL";
import { useHistory } from "react-router-dom";
import Tabs from "src/js/components/Tabs";
import Banner from "src/js/components/banner";
import Header from "./Header";
import Batches from "./Batches";
import Colors, { colorToRgba } from "src/js/Colors";
import Badge from "src/js/components/Badge";
import CustomCard from "src/js/components/CustomCard";
import SendTestEmailModal from "../components/SendTestEmailModal";
import EntityLogs from "src/js/components/EntityLogs";
import Select from "src/js/components/select/Select";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import IconWithBackround from "src/js/components/IconWithBackround";
import { BehaviorMajor, CircleDisabledMajor, CustomersMajor, EditMajor, EmailMajor, LegalMajor, SendMajor } from "@shopify/polaris-icons";
import { getValidationStatuses } from "../Utilities/getValidationStatus";
import { getDispatchedStatuses } from "../Utilities/getDispatchedStatus";
import Collapsible from "src/js/components/Collapsible";

const OutboundEmailEdit = () => {
	const history = useHistory();

	const { form, setForm, t, handleSave, id, outboundEmail, handlePreSend, handleRemove, isFetching, stats, isFetchingStats } =
		use(OutboundEmailContext);

	const [emailTemplateModalIsOpen, setEmailTemplateModalIsOpen] = useState(false);
	const [sendTestEmailModalIsOpen, setSendTestEmailModalIsOpen] = useState(false);
	const [selectedBatchId, setSelectedBatchId] = useState<any>(form?.batches?.[0]?.id || null);

	const iframeRef = useRef<any>(null);

	const getChangeHandler = useCallback(
		(key: string) => (value: any) => {
			setForm((prev) => {
				const newForm = { ...prev, [key]: value };

				return newForm;
			});
		},
		[setForm]
	);

	const handleIframeLoad = () => {
		const iframe = iframeRef.current;
		if (iframe) {
			try {
				// Access the iframe's document and measure its body height
				const iframeHeight = iframe.contentWindow.document.body.scrollHeight;
				iframe.style.height = `${iframeHeight}px`;
			} catch (error) {
				console.error("Unable to access iframe content:", error);
			}
		}
	};

	const onSave = async () => {
		const newForm = { ...form };
		setForm(newForm);

		return await handleSave(newForm);
	};

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "overview",
					content: t("outreach.outbound_emails.tabs.overview", "Grunduppgifter"),
					render: (
						<>
							<Layout>
								<Layout.Section oneHalf>
									<CustomCard sectioned>
										<FormLayout>
											<TextField
												label={t("outreach.outbound_emails.fields.title", "Benämning") as string}
												value={form.title}
												onChange={getChangeHandler("title")}
											/>

											<TextField
												label={t("outreach.outbound_emails.fields.subject", "Ämnesrad") as string}
												value={form.subject}
												onChange={getChangeHandler("subject")}
											/>

											<Select
												required={!isFetching}
												disabled={!!id}
												placeholder="Välj typ.."
												value={form.type}
												onChange={getChangeHandler("type")}
												options={[
													{
														label: t("outreach.outbound_emails.fields.type.promotional.label", "Nyhetsbrev"),
														value: "promotional",
													},
													{
														label: t("outreach.outbound_emails.fields.type.transactional.label", "Transaktionell"),
														value: "transactional",
													},
												]}
											/>
										</FormLayout>
									</CustomCard>
								</Layout.Section>
								<Layout.Section oneHalf>
									{!form.email_template && !isFetching && (
										<Banner
											status="warning"
											title={t("outreach.outbound_emails.edit.no_template", "Ingen e-postmall vald")}
											action={{
												content: t("outreach.outbound_emails.edit.add_template", "Välj e-postmall"),
												onAction: () => setEmailTemplateModalIsOpen(true),
											}}
										/>
									)}

									{form.email_template && (
										<IframeWrapper>
											<ButtonsWrapper style={{ justifyContent: "space-between" }}>
												<ButtonsWrapper>
													{!id && (
														<Button
															primary
															onClick={() => {
																setEmailTemplateModalIsOpen(true);
															}}
														>
															{t("outreach.outbound_emails.edit.change_template", "Byt mall")}
														</Button>
													)}
													{id && (
														<Button
															primary
															disabled={!form.id}
															onClick={() => {
																setSendTestEmailModalIsOpen(true);
															}}
														>
															{t("outreach.outbound_emails.actions.send_test_email", "Skicka test mail")}
														</Button>
													)}
												</ButtonsWrapper>
												{id && (
													<Button
														onClick={() => {
															history.push(`/admin/email_templates/${form.email_template.id}`, { email_campaign: form });
														}}
													>
														{t("outreach.outbound_emails.edit.edit_template", "Redigera design")}
													</Button>
												)}
											</ButtonsWrapper>
											<iframe
												ref={iframeRef}
												title={form.title}
												src={`${BASE_URL}/api/email_templates/${form.email_template?.id}/preview.html`}
												id="preview-iframe"
												frameBorder="0"
												scrolling="no"
												onLoad={handleIframeLoad}
											/>
										</IframeWrapper>
									)}
								</Layout.Section>
							</Layout>

							<SendTestEmailModal
								isOpen={sendTestEmailModalIsOpen}
								onClose={() => setSendTestEmailModalIsOpen(false)}
								outboundEmail={outboundEmail}
							/>
						</>
					),
				},
				{
					id: "batches",
					content: t("outreach.outbound_emails.tabs.batches", "Utskick"),
					badge: <Badge color={Colors.accent}>{outboundEmail?.batches?.length || 0}</Badge>,
					render: <Batches />,
					enabled: !!id,
				},
				{
					id: "stats",
					content: t("outreach.outbound_emails.tabs.stats", "Statistik"),
					render: () => {
						const validationStatuses = getValidationStatuses();
						const dispatchedStatuses = getDispatchedStatuses();

						// mottagare, skickat, levererat, öppnat, klickat, avregistrerat
						const mainBoxes = [
							{
								title: t("outbound.ouboundEmailLog.status.total_logs.label", "Mottagare"),
								loading: isFetchingStats,
								text: stats?.total_logs || 0,
								icon: <IconWithBackround icon={CustomersMajor} backgroundColor={colorToRgba(Colors.grey, 0.2)} />,
								helpText: "Företag/kontakter ifrån målgruperna med eller utan email",
							},
							{
								title: dispatchedStatuses.sent_at.label,
								loading: isFetchingStats,
								text: stats?.sent_at || 0,
								icon: <IconWithBackround icon={SendMajor} backgroundColor={colorToRgba(dispatchedStatuses.sent_at.color, 0.2)} />,
							},
							{
								title: dispatchedStatuses.delivered_at.label,
								loading: isFetchingStats,
								text: stats?.delivered_at || 0,
								icon: <IconWithBackround icon={EmailMajor} backgroundColor={colorToRgba(dispatchedStatuses.delivered_at.color, 0.2)} />,
							},
							{
								title: dispatchedStatuses.opened_at.label,
								loading: isFetchingStats,
								text: stats?.opened_at || 0,
								icon: <IconWithBackround icon={LegalMajor} backgroundColor={colorToRgba(dispatchedStatuses.opened_at.color, 0.2)} />,
							},
							{
								title: dispatchedStatuses.clicked_at.label,
								loading: isFetchingStats,
								text: stats?.clicked_at || 0,
								icon: <IconWithBackround icon={BehaviorMajor} backgroundColor={colorToRgba(dispatchedStatuses.clicked_at.color, 0.2)} />,
							},
							{
								title: dispatchedStatuses.unsubscribed_at.label,
								loading: isFetchingStats,
								text: stats?.unsubscribed_at || 0,
								icon: <IconWithBackround icon={CircleDisabledMajor} backgroundColor={colorToRgba(dispatchedStatuses.unsubscribed_at.color, 0.2)} />,
							},
						];

						return (
							<div>
								<StatsBoxWrapper
									style={{ justifyContent: "flex-start" }}
									// perRow="auto-fit"
									minWidth={300}
									items={mainBoxes}
								/>
								<br />

								<Collapsible activator={<Button>Se mer stats</Button>}>
									<br />
									<StatsBoxWrapper
										style={{ justifyContent: "flex-start" }}
										// perRow="auto-fit"
										minWidth={300}
										items={[
											{
												title: validationStatuses.pending.label,
												loading: isFetchingStats,
												text: stats?.pending || 0,
												icon: <IconWithBackround icon={EditMajor} backgroundColor={colorToRgba(validationStatuses.pending.color, 0.2)} />,
												helpText: validationStatuses.pending.helpText,
											},
											{
												title: validationStatuses.invalid.label,
												loading: isFetchingStats,
												text: stats?.invalid || 0,
												icon: <IconWithBackround icon={EditMajor} backgroundColor={colorToRgba(validationStatuses.invalid.color, 0.2)} />,
												helpText: validationStatuses.invalid.helpText,
											},
											{
												title: validationStatuses.risky.label,
												loading: isFetchingStats,
												text: stats?.risky || 0,
												icon: <IconWithBackround icon={EditMajor} backgroundColor={colorToRgba(validationStatuses.risky.color, 0.2)} />,
												helpText: validationStatuses.risky.helpText,
											},
											{
												title: validationStatuses.unknown.label,
												loading: isFetchingStats,
												text: stats?.unknown || 0,
												icon: <IconWithBackround icon={EditMajor} backgroundColor={colorToRgba(validationStatuses.unknown.color, 0.2)} />,
												helpText: validationStatuses.unknown.helpText,
											},
										]}
									/>
									<br />

									<StatsBoxWrapper
										style={{ justifyContent: "flex-start" }}
										minWidth={300}
										// perRow="auto-fit"
										items={Object.entries(dispatchedStatuses).map(([key, s]) => ({
											title: s.label,
											loading: isFetchingStats,
											text: stats?.[key] || 0,
											icon: <IconWithBackround icon={EditMajor} backgroundColor={colorToRgba(s.color, 0.2)} />,
										}))}
									/>
								</Collapsible>
							</div>
						);
					},
					enabled: !!id,
				},
				{
					id: "logs",
					content: t("outreach.outbound_emails.tabs.logs", "Loggar"),
					render: <EntityLogs url={`/api/outbound_emails/${outboundEmail?.id}/logs.json`} />,
					enabled: !!id,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[id, t, form, isFetching, getChangeHandler, history, outboundEmail, sendTestEmailModalIsOpen, isFetchingStats, stats]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "overview";
	}, [tabs]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};
	const [tabID, setTabID] = useState(getTabIdFromHash());

	useEffect(() => {
		const firstBatchId = outboundEmail?.batches?.[0]?.id;
		if (firstBatchId && !selectedBatchId) {
			setSelectedBatchId(firstBatchId);
		}
	}, [outboundEmail, selectedBatchId]);

	const title = id
		? t("outreach.outbound_emails.edit.title", "Redigera E-postutskick: {{title}}", { title: outboundEmail?.title })
		: t("outreach.outbound_emails.create.title", "Skapa nytt E-postutskick");

	const tab = tabs.find((tab) => tab.id === tabID);

	const sendButton = (
		<Button primary onClick={handlePreSend}>
			{t("outreach.outbound_email_batches.create_send", "Skicka")}
		</Button>
	);

	return (
		<Page
			loading={isFetching}
			title={title as string}
			primaryAction={
				<ButtonGroup>
					{id && sendButton}

					<Button primary onClick={onSave}>
						{id ? t("common.save", "Spara") : t("common.create", "Skapa")}
					</Button>
				</ButtonGroup>
			}
			secondaryActions={[
				{
					content: t("common.actions.remove", "Ta bort"),
					onAction: handleRemove,
					destructive: true,
					confirmation: {
						title: t("common.actions.remove", "Ta bort"),
						content: t("outreach.outbound_emails.remove_confirmation", "Är du säker på att du vill ta bort detta utskick?"),
						confirm: t("common.actions.remove", "Ta bort"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
					enabled: !!id && !form.removed,
				},
			]}
		>
			<Header />
			<div style={{ marginBottom: "1rem" }}>
				<Tabs tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			</div>
			{typeof tab?.render === "function" ? tab?.render() : tab?.render}

			<EmailTemplateModal
				isOpen={emailTemplateModalIsOpen}
				onClose={() => setEmailTemplateModalIsOpen(false)}
				onChange={(emailTemplate) => setForm((prev) => ({ ...prev, email_template: emailTemplate }))}
				form={form}
			/>
		</Page>
	);
};

export default (props) => (
	<OutboundEmailContextProvider {...props}>
		<OutboundEmailEdit {...props} />
	</OutboundEmailContextProvider>
);
