import i18n from "src/js/i18n";

import OutboundEmailCreate from "src/js/views/Outreach/OutboundEmail/Create";
import OutboundEmailEdit from "src/js/views/Outreach/OutboundEmail/Edit";
import OutboundEmailList from "src/js/views/Outreach/OutboundEmail/List";

export default () => [
	{
		path: "/admin/outreach/outbound_emails/create",
		name: i18n.t("routes.names.outreach.outbound_emails.create1", "Skapa E-postutskick"),
		exact: true,
		component: OutboundEmailCreate,
	},
	{
		path: "/admin/outreach/outbound_emails/:id",
		name: i18n.t("routes.names.outreach.outbound_emails.edit1", "Redigera E-postutskick"),
		exact: true,
		component: OutboundEmailEdit,
	},
	{
		path: "/admin/outreach/outbound_emails",
		name: i18n.t("routes.names.outreach.outbound_emails.list1", "E-postutskick"),
		exact: true,
		component: OutboundEmailList,
	},
];
