import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";
import SendModal from "../components/SendModal";
import { SocketContext } from "src/js/SocketProvider";
import debugSocket from "src/js/Utilities/debugSocket";

const OutboundEmailContext = React.createContext({} as any);

type OutboundEmailFormType = OutboundEmailType | Partial<OutboundEmailType>;

const REQUIRED_FIELDS = [
	{
		label: "Typ",
		value: "type",
	},
];

export const OutboundEmailContextProvider = ({ id: propsId, match, children, defaultData = null }) => {
	const { t } = useTranslation(["outreach", "outbound_email", "common"]);
	const history: any = useHistory();
	const queryClient = useQueryClient();
	const { socket } = useContext(SocketContext);
	const timerRef = React.useRef<any>(null);

	const defaultForm = useMemo(() => defaultData || { title: "" }, [defaultData]);

	const [form, setForm] = useState<OutboundEmailFormType>(defaultForm as OutboundEmailFormType);
	const [sendModalIsOpen, setSendModalIsOpen] = useState(false);
	const [selectedBatchId, setSelectedBatchId] = useState<any>();

	const id = propsId || match?.params?.id || null;

	const queryKey = [id && `outbound_email-${id}`].filter(Boolean);

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/outbound_emails/${id}.json`);

			return res.data.outbound_email;
		} catch (error) {
			Toast.error(error);
			return defaultForm || null;
		}
	}, [id, defaultForm]);

	const queryEnabled = !!id;

	const {
		data: outboundEmail = null,
		isFetching,
		refetch,
		isInitialLoading,
	}: UseQueryResult<OutboundEmailType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || defaultForm || null,
		enabled: queryEnabled,
	});

	useEffect(() => {
		if (outboundEmail) {
			setForm(outboundEmail);
		}
	}, [outboundEmail]);

	const {
		data: stats,
		isFetching: isFetchingStats,
		refetch: refetchStats,
	}: UseQueryResult<any | null> = useQuery({
		queryKey: [...queryKey, "stats"],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/outbound_emails/${id}/stats.json`);

				return res.data.stats;
			} catch (error) {
				Toast.error(error);
			}
		},
		refetchOnWindowFocus: false,
		initialData: {},
		enabled: queryEnabled,
	});
	// socket.on("outboundEmail.update", (newOutboundEmail) => {
	// 	debugSocket("outboundEmail.update", newOutboundEmail);

	// 	if (newOutboundEmail?.id === outboundEmail?.id) {
	// 		const queryData: any = queryClient.getQueryData(queryKey);
	// 		if (!queryData || queryData?.updated_at < newOutboundEmail?.updated_at) {
	// 			// queryClient.invalidateQueries([`newOutboundEmail_${newOutboundEmail.id}`]);
	// 			queryClient.setQueryData(queryKey, newOutboundEmail);
	// 		}
	// 	}
	// });

	socket.on("outboundEmail.refetch", (data) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { outbound_email: newOutboundEmail, batch } = data;
		debugSocket("outboundEmail.refetch", data);

		if (
			newOutboundEmail?.id === outboundEmail?.id
			// && newOutboundEmail?.updated_at > outboundEmail.updated_at
		) {
			if (timerRef.current) clearTimeout(timerRef.current);

			timerRef.current = setTimeout(() => {
				refetch();
				refetchStats();
			}, 5000);
		}
	});

	const saveFunction = useCallback(
		async (outboundEmailForm: OutboundEmailFormType) => {
			const missingFields = REQUIRED_FIELDS.filter((f) => !outboundEmailForm[f.value]);
			if (missingFields?.length) {
				const labels = missingFields.map((f) => `"${f.label}"`);
				Toast.error(`Saknar nödvändig data för ${labels.join(",")}`);
				return;
			}

			const endpoint = !id ? `/api/outbound_emails.json` : `/api/outbound_emails/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...outboundEmailForm }) : API.post(endpoint, { ...outboundEmailForm }));

			const successMessage = id
				? t("outreach.outbound_email.responses.saved", "E-postutskick uppdaterat")
				: t("outreach.outbound_email.responses.created", "E-postutskick skapad");

			Toast.success(successMessage);

			history.replace(`/admin/outreach/outbound_emails/${res.data.outbound_email.id}`, {
				data: res.data.outbound_email,
			});

			return res;
		},
		[id, t, history]
	);

	const mutation = useMutation(
		async ({ outboundEmail }: { outboundEmail: OutboundEmailFormType }) => {
			const response = await saveFunction(outboundEmail);
			return response?.data.outbound_email;
		},
		{
			onSuccess: (data) => {
				if (queryEnabled) queryClient.setQueryData(queryKey, data);
			},
		}
	);

	const handleSave = useCallback(
		async (outboundEmail: OutboundEmailFormType = form) => {
			try {
				if (!outboundEmail) {
					throw new Error("No outboundEmail");
				}

				return await mutation.mutateAsync({ outboundEmail });
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		},
		[mutation, form]
	);

	const removeMutation = useMutation(
		async () => {
			try {
				const res = await API.delete(`/api/outbound_emails/${id}.json`);
				Toast.success(t("outreach.outbound_email.responses.removed", "E-postutskick borttagen"));
				history.replace("/admin/outreach/outbound_emails");

				return res.data.outbound_email;
			} catch (e) {
				Toast.error(e);
			}
		},
		{
			onSuccess: (data) => {
				if (queryEnabled) queryClient.setQueryData(queryKey, data);
			},
		}
	);

	const handleRemove = useCallback(async () => await removeMutation.mutateAsync(), [removeMutation]);

	const handlePreSend = useCallback(async () => {
		setSendModalIsOpen(true);
	}, []);

	useEffect(() => {
		const lastBatchId = outboundEmail?.batches?.at(-1)?.id;
		if (lastBatchId && !selectedBatchId) {
			setSelectedBatchId(lastBatchId);
		}
	}, [outboundEmail, selectedBatchId]);

	const isSaving = mutation.isLoading;

	const value = useMemo(() => {
		return {
			t,
			id,
			form,
			setForm: setForm as any,
			isFetching,
			outboundEmail,
			history,
			handleSave,
			isSaving,
			handlePreSend,
			handleRemove,
			selectedBatchId,
			setSelectedBatchId,
			stats,
			isFetchingStats,
			isInitialLoading,
			queryKey,
			queryClient,
			refetch,
		};
	}, [
		form,
		id,
		setForm,
		history,
		isFetching,
		outboundEmail,
		handleSave,
		isSaving,
		t,
		handlePreSend,
		handleRemove,
		selectedBatchId,
		setSelectedBatchId,
		stats,
		isFetchingStats,
		isInitialLoading,
		queryKey,
		queryClient,
		refetch,
	]);

	return (
		<OutboundEmailContext value={value}>
			{children}
			<SendModal
				open={sendModalIsOpen}
				onClose={() => setSendModalIsOpen(false)}
				outboundEmail={form}
				onSend={(outboundEmail: OutboundEmailType, outboundEmailBatch: OutboundEmailBatchType) => {
					queryClient.setQueryData(queryKey, outboundEmail);
					setSelectedBatchId(outboundEmailBatch.id);

					refetch();
				}}
			/>
		</OutboundEmailContext>
	);
};

export default OutboundEmailContext;
