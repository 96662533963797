import React, { use } from "react";
import Banner from "src/js/components/banner";
import OutboundEmailContext from "../Context/OutboundEmailContext";
import styled from "styled-components";

const Header = () => {
	const { form, t } = use(OutboundEmailContext);

	return <Wrapper>{form.removed && <Banner title={t("outreach.outbound_email.removed", "E-postutskick borttagen")} status="critical" />}</Wrapper>;
};
export default Header;

const Wrapper = styled.div`
	margin-bottom: 1rem;
`;
