import React, { Component } from "react";
import { Page, Layout, LegacyCard, Spinner, Checkbox, FormLayout, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";

import TicketDepartmentModal from "../../components/TicketDepartmentModal.js";
import Toast from "src/js/components/Toast";

//! DEPRECATED, use SupportSettings.tsx instead
class SupportSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			departments: [],
			// chat_color_hsl: store.getState().account.chat_color_hsl,
			// chat_color_hsl_orig: store.getState().account.chat_color_hsl,
			// chat_auto_phrase: store.getState().account.chat_auto_phrase,
			// chat_auto_phrase_orig: store.getState().account.chat_auto_phrase,
			currentDepartment: {
				title: "",
			},
		};
	}

	componentDidMount() {
		this.fetchDepartments();
	}

	fetchDepartments() {
		this.setState({ loading: true });
		API.get("/api/ticket_departments.json")
			.then((result) => {
				this.setState({ loading: false, departments: result.data.ticket_departments });
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ loading: false });
			});
	}

	openTicketDepartmentModal(item) {
		this.setState({ openTicketDepartmentModal: true, currentDepartment: item });
	}

	closeTicketDepartmentModal() {
		this.setState({ openTicketDepartmentModal: false });
	}

	updateDepartment(index, field, save, value) {
		this.state.departments[index][field] = value;
		this.setState({ departments: this.state.departments });

		if (save) {
			this.saveDepartment(this.state.departments[index]);
		}
	}

	saveDepartment(department) {
		API.put("/api/ticket_departments/" + department.id + ".json", department)
			.then((result) => {
				Toast.success(this.props.t("ticket.departments.responses.updated", "Köinställning uppdaterades"));
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	render() {
		return (
			<div>
				<Page
					title={("ticket.support.title", "Supportinställningar")}
					primaryAction={{
						content: this.props.t("ticket.departments.actions.new", "Ny kö"),
						onAction: this.openTicketDepartmentModal.bind(this, { title: "" }),
					}}
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				>
					<Layout>
						{this.state.loading ? <Spinner /> : null}
						{this.state.departments.map((department, index) => (
							<Layout.AnnotatedSection
								key={index}
								title={department.title}
								description={this.props.t("ticket.departments.section.description", "Inställningar för denna kö")}
							>
								<LegacyCard sectioned>
									<FormLayout>
										<Checkbox
											onChange={this.updateDepartment.bind(this, index, "auto_remind_pending", true)}
											checked={department.auto_remind_pending}
											label={this.props.t("ticket.fields.auto_remind_pending.label", "Skicka påminnelse till ärenden som väntar på svar")}
										/>
										{department.auto_remind_pending ? (
											<TextField
												onBlur={this.saveDepartment.bind(this, department)}
												type="number"
												onChange={this.updateDepartment.bind(this, index, "auto_remind_pending_days", false)}
												value={department.auto_remind_pending_days + ""}
												label={this.props.t("ticket.departments.auto_remind_pending_days.label", "Dagar sedan senast aktivitet")}
											/>
										) : null}
										{department.auto_remind_pending ? (
											<TextField
												onBlur={this.saveDepartment.bind(this, department)}
												onChange={this.updateDepartment.bind(this, index, "auto_remind_pending_message", false)}
												value={department.auto_remind_pending_message}
												multiline
												label={this.props.t("ticket.departments.auto_remind_pending_message.label", "Meddelande")}
											/>
										) : null}
									</FormLayout>
								</LegacyCard>
								<LegacyCard sectioned>
									<FormLayout>
										<Checkbox
											onChange={this.updateDepartment.bind(this, index, "auto_close_pending", true)}
											checked={department.auto_close_pending}
											label={this.props.t("ticket.departments.auto_close_pending.label", "Avsluta automatiskt ärenden som väntar på svar")}
										/>
										{department.auto_close_pending ? (
											<TextField
												onBlur={this.saveDepartment.bind(this, department)}
												type="number"
												onChange={this.updateDepartment.bind(this, index, "auto_close_pending_days", false)}
												value={department.auto_close_pending_days + ""}
												label={this.props.t("ticket.departments.auto_close_pending_days.label", "Dagar sedan senast aktivitet")}
											/>
										) : null}
										{department.auto_close_pending ? (
											<TextField
												onBlur={this.saveDepartment.bind(this, department)}
												onChange={this.updateDepartment.bind(this, index, "auto_close_pending_message", false)}
												value={department.auto_close_pending_message}
												multiline
												label={this.props.t("ticket.departments.auto_close_pending_message.label", "Meddelande")}
											/>
										) : null}
									</FormLayout>
								</LegacyCard>
							</Layout.AnnotatedSection>
						))}

						{/* <Layout.AnnotatedSection
              title="Chatt & Widget"
              description="Besvara dina besökares frågor direkt på din hemsida"
            >
              <AccountConnection
                connected={((this.state.chat_enabled) ? true:false)}
                title="Chatt-widget"
                action={{
                  content: ((this.state.chat_enabled) ? 'Avaktivera':'Aktivera'),
                  loading: this.state.saving,
                  onAction: this.toggleChatEnabled.bind(this),
                }}
                details={((this.state.chat_enabled) ? 'Aktiverad':'Inaktiverad')}
                termsOfService='Spårningskoden måste vara installerad på din hemsida för att chatten ska fungera'
              />
              <LegacyCard sectioned>
                <FormLayout>
                  <TextField
                    label="Automatisk hälsning"
                    value={this.state.chat_auto_phrase}
                    onChange={(value) => {this.setState({chat_auto_phrase:value});}}
                  />
                  <Labelled label="Färg">
                    <ColorPicker onChange={(color) => {this.setState({chat_color_hsl: color})}} color={this.state.chat_color_hsl} />
                  </Labelled>
                  {(((this.state.chat_auto_phrase!=this.state.chat_auto_phrase_orig) || (JSON.stringify(this.state.chat_color_hsl)!=JSON.stringify(this.state.chat_color_hsl_orig))) ? <Button onClick={this.saveChatSettings.bind(this)} loading={this.state.saving} primary>Spara</Button>:null)}
                </FormLayout>
              </LegacyCard>
            </Layout.AnnotatedSection> */}
					</Layout>
				</Page>
				{/* <FooterHelp>
          Ärenden skapas automatiskt när du tar emot mail till <strong>{store.getState().account.support_mail}</strong>
        </FooterHelp>
      */}
				<TicketDepartmentModal
					open={this.state.openTicketDepartmentModal}
					department={this.state.currentDepartment}
					onCreate={(type) => {
						this.closeTicketDepartmentModal();
						this.fetchDepartments();
					}}
					onClose={this.closeTicketDepartmentModal.bind(this)}
				/>
			</div>
		);
	}
}

export default withTranslation(["ticket", "common"], { withRef: true })(SupportSettings);
