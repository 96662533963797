import i18n from "src/js/i18n";
import FortnoxArticlesList from "src/js/views/Fortnox/Articles/List/FortnoxArticlesList";
import FortnoxArticle from "src/js/views/Fortnox/Articles";

import { store } from "src/js/store";

export default () => [
	// {
	// 	path: "/admin/fortnox/articles/create",
	// 	exact: true,
	// 	enabled: store.getState().account.fortnox_integration,
	// 	component: FortnoxArticleCreate,
	// },
	{
		path: "/admin/fortnox/articles/:id",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxArticle,
		name: i18n.t("routes.names.fortnox_article", "Artikel"),
	},
	{
		path: "/admin/fortnox/articles",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxArticlesList,
		name: i18n.t("routes.names.fortnox_articles", "Artiklar"),
	},
];
