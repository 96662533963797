import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";

const TargetAudienceContext = React.createContext({} as any);

type TargetAudienceFormType = TargetAudienceType | Partial<TargetAudienceType>;

export const TargetAudienceContextProvider = ({ id: propsId, match, children, defaultData = null }) => {
	const [form, setForm] = useState<Partial<TargetAudienceType>>(defaultData || { title: "", type: "companies", contact_filters: [] });
	const history: any = useHistory();
	const { t } = useTranslation(["outreach", "target_audience", "common"]);

	const id = propsId || match?.params?.id || null;
	const queryClient = useQueryClient();

	const queryKey = [id && `target_audience-${id}`].filter(Boolean);

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/target_audiences/${id}.json`);

			return res.data.target_audience;
		} catch (error) {
			Toast.error(error);
			return defaultData || null;
		}
	}, [id, defaultData]);

	const queryEnabled = !!id;

	const { data: targetAudience = null, isFetching }: UseQueryResult<TargetAudienceType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || defaultData || null,
	});

	useEffect(() => {
		if (targetAudience) {
			setForm(targetAudience);
		}
	}, [targetAudience]);

	const saveFunction = useCallback(
		async (targetAudience: TargetAudienceFormType) => {
			const endpoint = !id ? `/api/target_audiences.json` : `/api/target_audiences/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...targetAudience }) : API.post(endpoint, { ...targetAudience }));

			const successMessage = id
				? t("outreach.target_audience.responses.saved", "Målgrupp uppdaterat")
				: t("outreach.target_audience.responses.created", "Målgrupp skapad");

			Toast.success(successMessage);

			history.replace(`/admin/outreach/target_audiences/${res.data.target_audience.id}`, {
				data: res.data.target_audience,
			});

			return res;
		},
		[id, t, history]
	);

	const mutation = useMutation(
		async ({ targetAudience }: { targetAudience: TargetAudienceFormType }) => {
			const response = await saveFunction(targetAudience);
			return response?.data.target_audience;
		},
		{
			onSuccess: (data) => {
				if (queryEnabled) queryClient.setQueryData(queryKey, data);
			},
		}
	);

	const handleSave = useCallback(
		async (targetAudience: TargetAudienceFormType = form) => {
			try {
				if (!targetAudience) {
					throw new Error("No targetAudience");
				}

				return await mutation.mutateAsync({ targetAudience });
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		},
		[mutation, form]
	);

	const handleRemove = useCallback(async () => {
		try {
			await API.delete(`/api/target_audiences/${id}.json`);

			Toast.success(t("outreach.target_audience.responses.deleted", "Målgrupp borttagen"));
		} catch (e) {
			Toast.error(e);
		}
	}, [id, t]);

	const isSaving = mutation.isLoading;

	const value = useMemo(() => {
		return {
			id,
			form,
			setForm: setForm as any,
			isFetching,
			targetAudience,
			history,
			handleSave,
			isSaving,
			handleRemove,
			t,
		};
	}, [form, id, setForm, history, isFetching, targetAudience, handleSave, isSaving, t, handleRemove]);

	return <TargetAudienceContext value={value}>{children}</TargetAudienceContext>;
};

export default TargetAudienceContext;
