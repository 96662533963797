import React, { use, useEffect, useRef } from "react";
import TargetAudienceContext, { TargetAudienceContextProvider } from "../Context/TargetAudienceContext";
import Page from "src/js/components/page";
import { FormLayout } from "@shopify/polaris";
import TextField from "src/js/components/TextField";
import MultiSwitch from "src/js/components/MultiSwitch";
import People from "../../../Contacts/People";
import Companies from "../../../Contacts/Companies";

const TargetAudienceEdit = () => {
	const { form, setForm, t, handleSave, id, targetAudience, handleRemove } = use(TargetAudienceContext);
	const ref = useRef<any>(null);

	useEffect(() => {
		if (targetAudience?.contact_filters) {
			const { q: searchValue, ...filters } = targetAudience.contact_filters || {};

			const filtersAsArray = Object.entries(filters).map(([key, value]) => ({ key, value }));
			ref.current?.setFilters({ filters: filtersAsArray, search: searchValue });
		}
	}, [targetAudience?.contact_filters, form]);

	const getChangeHandler = (key: string) => (value: any) => {
		setForm((prev) => {
			const newForm = { ...prev, [key]: value };

			if (key === "type" && value !== prev.type) {
				newForm.filters = [
					{
						key: "is_company",
						value: value === "companies",
					},
					{
						key: "is_visitor",
						value: 0,
					},
				];

				// ref.current?.setFilters({ filters: newForm.filters });
			}

			return newForm;
		});
	};

	const typeOptions = [
		{ label: t("outreach.target_audience.types.companies", "Företag"), value: "companies" },
		{ label: t("outreach.target_audience.types.people", "Kontakter"), value: "people" },
	];

	const onSave = async () => {
		const filters = ref.current.getFilters();

		const newForm = { ...form, contact_filters: filters };
		setForm(newForm);

		return await handleSave(newForm);
	};

	const title = id
		? t("outreach.target_audience.edit.title", "Redigera målgrupp: {{target}}", { target: targetAudience?.title })
		: t("outreach.target_audience.create.title", "Skapa ny målgrupp");

	// const filtersArray = Object.entries(form.contact_filters)?.map(([key, value]: any) => ({ key, value }));
	// const disambiguatedFilters = filtersArray
	// 	?.map(({ key, value }) =>
	// 		disambiguateLabel(
	// 			[
	// 				...getDefaultFilters(form.type),
	// 				{
	// 					key: "q",
	// 					label: i18n.t("filters.type.q", "Sök"),
	// 				},
	// 			],
	// 			key,
	// 			value
	// 		)
	// 	)
	// 	.filter(Boolean);

	const { q: searchValue, ...filters } = targetAudience?.contact_filters || {};
	const filtersAsArray = Object.entries(filters).map(([key, value]) => ({ key, value }));

	return (
		<Page
			title={title as string}
			primaryAction={{
				content: id ? t("common.save", "Spara") : t("common.create", "Skapa"),
				onAction: onSave,
			}}
			secondaryActions={[
				{
					content: t("common.actions.remove", "Ta bort"),
					onAction: handleRemove,
					destructive: true,
					confirmation: {
						title: t("common.actions.remove", "Ta bort"),
						content: t("outreach.target_audience.remove.confirmation", "Är du säker på att du vill ta bort denna målgrupp?"),
						confirm: t("common.actions.remove", "Ta bort"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
					enabled: !!id && !form.removed,
				},
			]}
		>
			<FormLayout>
				<TextField label={t("outreach.target_audience.fields.title", "Titel") as string} value={form.title} onChange={getChangeHandler("title")} />
				<MultiSwitch label="Typ" options={typeOptions} value={form.type} onChange={getChangeHandler("type")} />

				{/* <div>
					{disambiguatedFilters?.map((f) => (
						<p key={f}>{f}</p>
					))}
				</div> */}

				<div style={{ paddingTop: "1rem" }}>
					{form?.type === "companies" && (
						<Companies ref={ref} inline urlParamsEnabled={false} appliedFilters={filtersAsArray} searchValue={searchValue} />
					)}
					{form?.type === "people" && <People ref={ref} inline urlParamsEnabled={false} appliedFilters={filtersAsArray} searchValue={searchValue} />}
				</div>
			</FormLayout>
		</Page>
	);
};

export default (props) => (
	<TargetAudienceContextProvider {...props}>
		<TargetAudienceEdit {...props} />
	</TargetAudienceContextProvider>
);
