import React, { use, useEffect, useRef, useState } from "react";
import OutboundEmailContext from "../Context/OutboundEmailContext";
import { EmptyState } from "@shopify/polaris";
import Tabs from "src/js/components/Tabs";
import IndexTable from "src/js/components/IndexTable";
import Badge from "src/js/components/Badge";
import getValidationStatus from "../Utilities/getValidationStatus";
import BatchHeader from "./BatchHeader";
import getReceiveStatus from "../Utilities/getDispatchedStatus";
import ResendBatchModal from "../components/ResendBatchModal";

const Batches = () => {
	const { t, outboundEmail, isFetching, selectedBatchId, setSelectedBatchId, refetch, queryKey, queryClient } = use(OutboundEmailContext);
	const indexTableRef = useRef<any>(null);
	const [selectedBatchToResend, setSelectedBatchToResend] = useState<any>();

	const outBoundEmailBatches = outboundEmail?.batches || [];
	const outBoundEmailBatch = outBoundEmailBatches.find((batch) => batch.id === selectedBatchId);

	const batchStatuses = useRef<any>({
		validation_started_at: outBoundEmailBatch?.validation_started_at,
		validation_webhook_received_at: outBoundEmailBatch?.validation_webhook_received_at,
		sent_started_at: outBoundEmailBatch?.sent_started_at,
		sent_completed_at: outBoundEmailBatch?.sent_completed_at,
	});

	useEffect(() => {
		if (
			outBoundEmailBatch &&
			indexTableRef.current &&
			Object.keys(batchStatuses.current).some((key) => batchStatuses.current[key] !== outBoundEmailBatch[key])
		) {
			indexTableRef.current.refresh(false);
			batchStatuses.current = {
				validation_started_at: outBoundEmailBatch.validation_started_at,
				validation_webhook_received_at: outBoundEmailBatch.validation_webhook_received_at,
				sent_started_at: outBoundEmailBatch.sent_started_at,
				sent_completed_at: outBoundEmailBatch.sent_completed_at,
			};
		}
	}, [outBoundEmailBatch]);

	const tabs = outBoundEmailBatches.map((batch, index) => ({
		id: batch.id,
		content: `${index + 1} ${batch.title || ""}`,
		badge: <Badge>{batch.count}</Badge>,
		actions: [
			{
				type: "",
				content: t("outreach.outbound_email_batches.actions.send_again", "Skicka igen"),
				onAction: () => {
					setSelectedBatchToResend(batch);
				},
			},
		],
	}));

	if (!outBoundEmailBatches.length) {
		tabs.push({
			id: null,
			content: t("outreach.outbound_email_batches.no_batches", "Inga utskick skickade än"),
			disabled: true,
		});
	}
	const columns = [
		// {
		// 	handle: "ID",
		// 	label: t("outreach.outbound_email_batches.fields.id", "ID"),
		// 	render: (log: OutboundEmailLogType) => log.id,
		// },
		{
			handle: "NAME",
			label: t("outreach.outbound_email_batches.columns.name", "Namn"),
			render: (log: OutboundEmailLogType) => log.contact?.name || "-",
		},
		{
			handle: "EMAIL",
			label: t("outreach.outbound_email_batches.columns.email", "Email"),
			render: (log: OutboundEmailLogType) => log.email_validation_result?.email,
		},
		{
			handle: "VALIDATION_STATUS",
			label: t("outreach.outbound_email_batches.columns.status", "Validering status"),
			render: (log: OutboundEmailLogType) => {
				const status = getValidationStatus(log.email_validation_result as EmailValidationResult, outBoundEmailBatch);
				if (!status) return null;

				return (
					<Badge
						color={status.color}
						tooltip={
							<>
								<p>{status.helpText}</p>

								{status.subStatus && (
									<>
										<br />
										<p>{status.subStatus?.helpText}</p>
									</>
								)}
							</>
						}
					>
						{status.label}
					</Badge>
				);
			},
		},
		{
			handle: "SENT_STATUS",
			label: t("outreach.outbound_email_batches.fields.dispatched_status", "Skickad status"),
			render: (log: OutboundEmailLogType) => {
				const status = getReceiveStatus((log as any).dispatched_email as DispatchedEmailType, log);
				if (!status) return null;

				return (
					<Badge
						color={status.color}
						tooltip={
							<>
								<p>{status.tooltip}</p>
							</>
						}
					>
						{status.label}
					</Badge>
				);
			},
		},
	];

	const batchToResend = outBoundEmailBatches.find((batch) => batch.id === selectedBatchToResend?.id);

	return (
		<div>
			<Tabs
				tabs={tabs}
				selected={tabs.findIndex((batch) => batch.id === selectedBatchId)}
				onSelect={(index) => {
					setSelectedBatchId(outBoundEmailBatches[index].id);
				}}
				disclosureText={t("outreach.outbound_email_batches.more", "Fler utskick")}
			/>
			<IndexTable
				ref={indexTableRef}
				extraHeader={<BatchHeader isLoading={isFetching} outBoundEmailBatch={outBoundEmailBatches.find((batch) => batch.id === selectedBatchId)} />}
				filterKey="outbound_email_logs"
				// defaultSavedSearches={tabs}
				enabled={!!selectedBatchId}
				inline
				columns={columns}
				resourceUrl="/api/outbound_email_logs.json"
				resourceHandle="outbound_email_logs"
				params={{ outbound_email_batch_id: selectedBatchId }}
				resourceName={{
					singular: t("outreach.outbound_email_logs", "Email Batch"),
					plural: t("outreach.outbound_email_logs", "Email Batches"),
				}}
				emptyState={
					<EmptyState
						heading={"Inga utskick skickade än"}
						// action={primaryAction as ComplexAction}
						image={"/assets/images/empty_state/NoResults.png"}
					>
						<p>Det finns inga utskick skickade än.</p>
						{/* {sendButton} */}
					</EmptyState>
				}
			/>

			<ResendBatchModal
				batch={batchToResend}
				onClose={() => {
					setSelectedBatchToResend(null);
				}}
				onSend={(outboundEmail: OutboundEmailType, outboundEmailBatch: OutboundEmailBatchType) => {
					queryClient.setQueryData(queryKey, outboundEmail);
					setSelectedBatchId(outboundEmailBatch.id);

					refetch();
				}}
			/>
		</div>
	);
};
export default Batches;
