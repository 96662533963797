import i18n from "src/js/i18n";
import OrdersList from "src/js/views/OrderFlow/Orders/List";
import OrderCreate from "src/js/views/OrderFlow/Orders/Create";
import OrderEdit from "src/js/views/OrderFlow/Orders/Edit";
import OrderSettings from "src/js/views/OrderFlow/Orders/OrderSettings";

export default () => [
	{
		path: "/admin/orders",
		exact: true,
		component: OrdersList,
		name: i18n.t("routes.names.orders.list", "Ordrar"),
	},
	{
		path: "/admin/orders/settings",
		exact: true,
		component: OrderSettings,
		name: i18n.t("routes.names.order_settings", "Orderinställningar"),
		settings: true,
		description: i18n.t("routes.names.order_settings_desc", "Hantera inställningar för ordrar"),
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
	},
	{
		path: "/admin/orders/create",
		exact: true,
		component: OrderCreate,
		name: i18n.t("routes.names.orders.create", "Skapa order"),
	},
	{
		path: "/admin/orders/:id",
		exact: true,
		component: OrderEdit,
		name: i18n.t("routes.names.orders.edit", "Redigera order"),
	},
];
