import i18n from "src/js/i18n";
import DialForm from "src/js/views/DialList/Form";
import DialList from "src/js/views/DialList/List";
import Routes from "src/js/views/DialList/List/Routes";

export default () => [
	{
		path: "/admin/dial_lists/create",
		name: i18n.t("routes.names.dial_list.list.create", "Skapa ringlista"),
		component: DialForm,
		exact: true,
	},
	{
		path: "/admin/dial_lists/:id/:view?/:dialEntryId?",
		name: i18n.t("routes.names.dial_list.list.view", "Hantera Ringlista"),
		component: Routes,
	},
	{
		path: "/admin/dial_lists",
		name: i18n.t("routes.names.dial_list.list", "Ringlistor"),
		exact: true,
		component: DialList,
	},
];
