import i18n from "src/js/i18n";
import ArticleSettings from "src/js/views/OrderFlow/Settings";
import ArticleList from "src/js/views/OrderFlow/Articles/List";
import ArticleCreate from "src/js/views/OrderFlow/Articles/Create";
import Article from "src/js/views/OrderFlow/Articles";

export default () => [
	{
		path: "/admin/articles/settings",
		exact: true,
		component: ArticleSettings,
		name: i18n.t("routes.names.article_settings", "Artikelinställningar"),
	},
	{
		path: "/admin/articles",
		exact: true,
		component: ArticleList,
		name: i18n.t("routes.names.articles", "Artiklar"),
	},
	{
		path: "/admin/articles/create",
		exact: true,
		component: ArticleCreate,
		name: i18n.t("routes.names.article_create", "Skapa artikel"),
	},
	{
		path: "/admin/articles/:id(\\d+)",
		exact: true,
		component: Article,
		name: i18n.t("routes.names.article", "Artikel"),
	},
];
