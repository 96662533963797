import i18n from "src/js/i18n";

import FortnoxOffer from "src/js/views/Fortnox/Offers";
import FortnoxOfferCreate from "src/js/views/Fortnox/Offers/Create";
import { store } from "src/js/store";
import FortnoxOffersList from "src/js/views/Fortnox/Offers/List/FortnoxOffersList";

export default () => [
	{
		path: "/admin/fortnox/contacts/:contactId/offers/create",
		mainMenu: false,
		exact: true,
		component: FortnoxOfferCreate,
		name: i18n.t("routes.names.fortnox_offer_create", "Skapa offert"),
	},
	{
		path: "/admin/fortnox/offers/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxOffer,
		name: i18n.t("routes.names.fortnox_offer", "Offert"),
	},
	{
		path: "/admin/fortnox/offers",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxOffersList,
		name: i18n.t("routes.names.fortnox_offers", "Offerter"),
	},
];
