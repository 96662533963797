import i18n from "src/js/i18n";

import FortnoxInvoice from "src/js/views/Fortnox/Invoices";
import FortnoxInvoiceCreate from "src/js/views/Fortnox/Invoices/Create";
import FortnoxInvoicesList from "src/js/views/Fortnox/Invoices/List/FortnoxInvoicesList";
import { store } from "src/js/store";

export default () => [
	{
		path: "/admin/fortnox/contacts/:contactId/invoices/create",
		mainMenu: false,
		exact: true,
		component: FortnoxInvoiceCreate,
		name: i18n.t("routes.names.fortnox_invoice_create", "Skapa faktura"),
	},
	{
		path: "/admin/fortnox/invoices/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxInvoice,
		name: i18n.t("routes.names.fortnox_invoice", "Faktura"),
	},
	{
		path: "/admin/fortnox/invoices",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxInvoicesList,
		name: i18n.t("routes.names.fortnox_invoices", "Fakturor"),
	},
];
