import i18n from "src/js/i18n";
import FortnoxSettings from "src/js/views/Fortnox/Settings";

import { store } from "src/js/store";

export default () => [
	{
		path: "/admin/fortnox/settings",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxSettings,
		name: i18n.t("routes.names.fortnox_settings", "Fortnox inställningar"),
	},
];
