import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Modal from "src/js/components/modal";
import SelectSearchField from "src/js/components/SelectSearchField";
import Toast from "src/js/components/Toast";
import styled from "styled-components";
import SendConfirmModal from "./SendConfirmModal";
import { Layout } from "@shopify/polaris";

const SendModal = ({ open, onClose, outboundEmail, onSend }) => {
	const { t } = useTranslation(["outreach", "common"]);
	const [targetAudiences, setTargetAudiences] = useState<TargetAudienceType[]>([]);
	const [selectedContacts, setSelectedContacts] = useState<ContactType[]>([]);
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

	const handleClose = () => {
		onClose();
		setTargetAudiences([]);
		setSelectedContacts([]);
		setConfirmModalIsOpen(false);
	};

	const handleSend = async () => {
		try {
			const res = await API.post(`/api/outbound_emails/${outboundEmail.id}/send.json`, {
				target_audience_ids: targetAudiences.map((targetAudience) => targetAudience.id),
				contact_ids: selectedContacts.map((contact) => contact.id),
			});

			const count = res.data.count;
			Toast.success(t("outreach.SendModal.success", "Utskick kommer skickas till {{count}} mottagare", { count }));

			onSend(res.data.outbound_email, res.data.outbound_email_batch);
			handleClose();
		} catch (e) {
			Toast.error(e);
		}
	};

	return (
		<>
			<Modal
				large
				style={{
					minHeight: "50vh",
				}}
				open={open}
				onClose={handleClose}
				title={t("outreach.SendModal.title", "Skicka utskick") as string}
				primaryAction={{
					content: t("outreach.SendModal.send", "Bekräfta") as string,
					onAction: () => {
						setConfirmModalIsOpen(true);
					},
				}}
			>
				<Layout>
					<Layout.Section secondary>
						<SelectSearchField
							label={t("outreach.SendModal.target_audience.label", "Välj målgrupper att skicka detta utskick till") as string}
							labelHandle="title"
							// value={targetAudiences.map((targetAudience: TargetAudienceType) => targetAudience.id)}
							value={targetAudiences}
							onSelect={setTargetAudiences}
							resource="target_audiences"
							resourceHandle="target_audiences"
							allowMultiple
							resourceName={{
								singular: t("outreach.target_audience.singular", "Målgrupp"),
								plural: t("outreach.target_audience.plural", "Målgrupper"),
							}}
							renderValue={(value) => targetAudiences.map((targetAudience) => targetAudience.title).join(", ")}
						/>

						{!!targetAudiences?.length && (
							<ListWrapper>
								Valda målgrupper:
								<ul>
									{targetAudiences.slice(0, 5).map((targetAudience) => (
										<li key={targetAudience.id}>{targetAudience.title}</li>
									))}
									{targetAudiences.length > 5 && <li>...+({targetAudiences.length - 5})</li>}
								</ul>
							</ListWrapper>
						)}
					</Layout.Section>
					<Layout.Section secondary>
						<SelectSearchField
							label={t("outreach.SendModal.contact.label", "Välj företag/kontakter att skicka detta utskick till") as string}
							labelHandle="fullname"
							// value={targetAudiences.map((targetAudience: TargetAudienceType) => targetAudience.id)}
							value={selectedContacts}
							onSelect={setSelectedContacts}
							resource="contacts_verbose"
							resourceHandle="contacts"
							allowMultiple
							resourceName={{
								singular: t("outreach.contact.singular", "Kontakt"),
								plural: t("outreach.contact.plural", "Kontakter"),
							}}
							renderValue={(value) => selectedContacts.map((targetAudience) => targetAudience.fullname).join(", ")}
						/>

						{!!selectedContacts?.length && (
							<ListWrapper>
								Valda kontakter:
								<ul>
									{selectedContacts.slice(0, 5).map((contact) => (
										<li key={contact.id}>{contact.fullname}</li>
									))}
									{selectedContacts.length > 5 && <li>...+({selectedContacts.length - 5})</li>}
								</ul>
							</ListWrapper>
						)}
					</Layout.Section>
				</Layout>

				<SendConfirmModal
					isOpen={confirmModalIsOpen}
					targetAudiences={targetAudiences}
					selectedContacts={selectedContacts}
					outboundEmail={outboundEmail}
					handleSend={handleSend}
					onClose={() => {
						setConfirmModalIsOpen(false);
					}}
				/>
			</Modal>
		</>
	);
};
export default SendModal;

const ListWrapper = styled.div`
	margin-top: 20px;
`;
