import React, { useState } from "react";
import OptionList from "src/js/components/OptionList/OptionList";
import Modal from "src/js/components/modal";
import { getDispatchedStatuses } from "../Utilities/getDispatchedStatus";
import { Labelled } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import ResendBatchConfirmModal from "./ResendBatchConfirmModal";
import API from "src/js/API";
import Toast from "src/js/components/Toast";

const ResendBatchModal = ({ batch, onClose, onSend }) => {
	const { t } = useTranslation(["outreach", "common"]);
	const [selectedStatuses, setSelectedStatuses] = useState([]);
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

	const handleChangeStatuses = (statuses) => {
		setSelectedStatuses(statuses);
	};

	const handleSend = async () => {
		try {
			const res = await API.post(`/api/outbound_email_batches/${batch.id}/resend.json`, {
				statuses: selectedStatuses,
			});

			const count = res.data.count;
			Toast.success(t("outreach.ResendBatchModal.success", "Utskick kommer skickas till {{count}} mottagare", { count }));

			onSend(res.data.outbound_email, res.data.outbound_email_batch);
			handleClose();
		} catch (e) {
			Toast.error(e);
		}
	};
	const handleClose = async () => {
		onClose();
		setSelectedStatuses([]);
		setConfirmModalIsOpen(false);
	};

	return (
		<Modal
			title={t("outreach.ResendBatchModal.title", "Skicka utskick igen") as string}
			open={!!batch}
			onClose={handleClose}
			primaryAction={{
				content: t("outreach.ResendBatchModal.confirm", "Bekräfta") as string,
				onAction: () => {
					setConfirmModalIsOpen(true);
				},
				disabled: !selectedStatuses?.length,
			}}
		>
			<Labelled
				id="statuses"
				label={t("outreach.ResendBatchModal.label", "Välj statusar att skicka till") as string}
				helpText={t("outreach.ResendBatchModal.helpText", "Välj de statusar du vill skicka detta utskick till igen.") as string}
			>
				<OptionList
					style={{ background: "var(--main3)" }}
					search
					labelPrefix={t("outreach.ResendBatchModal.labelPrefix", "Statusar: ") as string}
					popover
					allowMultiple
					selected={selectedStatuses}
					onChange={handleChangeStatuses}
					options={Object.entries(getDispatchedStatuses())?.map(([key, value]) => ({
						value: key,
						label: value.label,
					}))}
				/>
			</Labelled>

			<ResendBatchConfirmModal
				isOpen={confirmModalIsOpen}
				onClose={() => setConfirmModalIsOpen(false)}
				batch={batch}
				statuses={selectedStatuses}
				handleSend={handleSend}
			/>
		</Modal>
	);
};
export default ResendBatchModal;
