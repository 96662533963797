import { SortButtonChoice } from "@shopify/polaris";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { store } from "../../store";
import type { IndexTableColumn, SavedSearch, SavedSearchFilter, SortValue } from "./types";

export const sortValueToPolarisSortValue = (sortValue: `${string}_${"ASC" | "DESC"}`) => {
	const arr = sortValue.split("_");
	const direction = arr.pop();
	const handle = arr.join("_");

	return `${handle} ${direction?.toLowerCase() || ""}`;
};

export const getDirectionFromSort = (sortValue: `${string}_${"ASC" | "DESC"}` | any) => {
	const arr = sortValue.split("_");
	const direction = arr.pop();

	return direction;
};
export const getHandleFromSort = (sortValue: `${string}_${"ASC" | "DESC"}` | any) => {
	const arr = sortValue.split("_");
	arr.pop();
	const handle = arr.join("_");
	return handle;
};
export const polarisSortValueTosSortValue = (sortValue: SortButtonChoice["value"] | string) => {
	return sortValue.split(" ").join("_").toUpperCase();
};
export const columnsToSortOptions = (columns: IndexTableColumn[], defaultSortValue?: string) => {
	return columns?.reduce(
		(acc, column: any) => {
			if (!column.sortable) return acc;

			return [
				...acc,
				{
					label: column.label,
					value: `${column.handle}_asc`.toUpperCase(),
					directionLabel: "Stigande",
				},
				{
					label: column.label,
					value: `${column.handle}_desc`.toUpperCase(),
					directionLabel: "Fallande",
				},
			];
		},
		defaultSortValue
			? [
					{
						label: disambiguateSortLabel(defaultSortValue),
						value: `${getHandleFromSort(defaultSortValue)}_ASC`,
						directionLabel: "Stigande",
					},
					{
						label: disambiguateSortLabel(defaultSortValue),
						value: `${getHandleFromSort(defaultSortValue)}_DESC`,
						directionLabel: "Fallande",
					},
			  ]
			: []
	);
};

export const disambiguateSortLabel = (sort: string) => {
	switch (sort) {
		case "LAST_ACTIVITY_DESC":
			return "Senast aktivitet";
		case "CREATED_AT_DESC":
			return "Skapad";
		case "UPDATE_AT_DESC":
			return "Uppdaterad";
		case "ID_DESC":
		case "ID_ASC":
			return "Skapad";
		default:
			return getHandleFromSort(sort).toLowerCase();
	}
};

export const columnsToHeadings = (columns: IndexTableColumn[]): NonEmptyArray<IndexTableHeading> | any => {
	return columns.map((column: IndexTableColumn) => ({
		id: column.handle,

		title: column.title || column.label || "",
		alignment: column.alignment,
		flush: column.flush,
		new: column.new,
		hidden: column.hidden,

		tooltipContent: column.tooltipContent,
		tooltipWidth: column.tooltipWidth,
		tooltipPersistsOnClick: column.tooltipPersistsOnClick,
		defaultSortDirection: column.defaultSortDirection,
	}));
};

export const parseQuery = (queryString: string) => {
	const query = new Map();
	const params = new URLSearchParams(queryString);
	const regex = /\[\d+\]$/;

	params.forEach((value, key) => {
		const keyIsPartOfArray = regex.test(key);
		const modifiedKey = keyIsPartOfArray ? key.replace(regex, "") : key;

		if (keyIsPartOfArray) {
			if (query.has(modifiedKey)) {
				const existingValue = query.get(modifiedKey);

				if (Array.isArray(existingValue)) {
					if (!existingValue.includes(value)) {
						existingValue.push(value);
						return;
					}
				}

				query.set(modifiedKey, [existingValue, value]);
				return;
			}

			query.set(modifiedKey, [value]);
			return;
		}

		query.set(modifiedKey, value);
	});

	return Object.fromEntries(query);
};

export const getSearchParams = (search, { defaultSortValue, urlParamsEnabled = true }) => {
	const defaultParams = { offset: 0, sortValue: defaultSortValue, searchValue: "", appliedFilters: [] };
	if (!urlParamsEnabled) return defaultParams;

	const params = Object.entries(parseQuery(search))?.reduce((acc: any, [key, value]: any) => {
		if (key === "offset") {
			acc.offset = parseInt(value);
		} else if (key === "sort") {
			acc.sortValue = value;
		} else if (key === "q") {
			acc.searchValue = value;
		} else {
			acc.appliedFilters.push({ key, value });
		}

		return acc;
	}, defaultParams);

	return params;
};

export const filterArrayToObject = (array: SavedSearchFilter[]) => {
	return (
		array?.reduce((acc: { [key: string]: any }, obj) => {
			const [key, value]: any = Object.values(obj);
			return { ...acc, [key]: value };
		}, {}) || {}
	);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isSameSearch = (savedSearch: SavedSearch, filters: SavedSearchFilter[], search: string, sorting: SortValue) => {
	if (savedSearch.search != search) {
		return false;
	}

	if (savedSearch.filters.length != filters.length) {
		return false;
	}

	return filters.every((filter) => savedSearch.filters.some((savedFilter) => savedFilter.key == filter.key && savedFilter.value == filter.value));
};

export const isEmpty = (value: string | any[]) => {
	if (Array.isArray(value)) {
		return value.length === 0;
	} else {
		return value === "" || value == null;
	}
};

export const getGroupFromId = (id: number | string | any) => {
	return store.getState().groups.find((group) => group.id == id);
};

export const getUserFromId = (id: number | string | any) => {
	return store.getState().users.find((user) => user.id == id);
};

export const generateTotalRow = (items: any[]) => {
	const res = items.reduce((acc: any, item: any) => {
		return Object.keys(item).reduce((accum, key) => {
			if (typeof item[key] === "number") {
				accum[key] = (accum[key] || 0) + item[key];
			}

			return accum;
		}, acc);
	}, {});
	return res;
};

export const getProjectFromId = (id: number | string | any, projects: any) => {
	return projects?.[id] || { title: id };
};

export const getDateOptions = (filter, appliedFilters: any[] = []) => {
	let options: any = [];
	if (filter.future) {
		options = [
			{ value: "past_week_and_earlier", label: "förra veckan och tidigare" },
			{ value: "this_week", label: "denna veckan" },
			{ value: "next_week", label: "nästa vecka" },
			{ value: "this_month", label: "denna månaden" },
			{ value: "next_month", label: "nästa månad" },
		];
	} else {
		options = [
			{ value: "this_week", label: "denna veckan" },
			{ value: "last_week", label: "förra veckan" },
			{ value: "this_month", label: "denna månaden" },
			{ value: "last_month", label: "förra månaden" },
			{ value: "last_30_days", label: "senaste 30 dagarna" },
			{ value: "last_90_days", label: "senaste 90 dagarna" },
		];
	}
	const appliedFilter = appliedFilters.find((appliedFilter) => appliedFilter.key == filter.key);

	if (appliedFilter && (appliedFilter?.value?.substring(0, 1) == "2" || appliedFilter?.value?.substring(0, 1) == "1")) {
		options.push({ value: appliedFilter?.value, label: (filter.operatorText ? filter.operatorText + " " : "") + (appliedFilter?.value || "") });
	} else {
		options.push({ value: "custom", label: "annan period" });
	}

	return options;
};

export const getContactFromId = (id: number | string | any) => {
	return store.getState().board_contacts[id];
};
