import styled from "styled-components";

export const IframeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 10px;
`;
