import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Badge from "src/js/components/Badge";
import IndexTable from "src/js/components/IndexTable";
import { TemplateButton } from "../../Outreach/OutboundEmail/components/EmailTemplateModal";
import Colors from "src/js/Colors";

const EmailTemplateList = () => {
	const { t } = useTranslation(["email_templates", "common"]);
	const history = useHistory();

	const handleOnClick = (item) => {
		history.push(`/admin/email_templates/${item.id}`);
	};

	const columns = [
		{
			handle: "TITLE",
			label: t("email_templates.columns.title.label", "Titel"),
			render: (i) => (
				<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					<TemplateButton>
						<img alt="" src={i.template_thumbnail} style={{ height: 100, width: "unset" }} />
					</TemplateButton>
					<p>{i.title}</p>
				</div>
			),
		},
		{
			handle: "IS_TEMPLATE",
			label: t("email_templates.columns.is_template.label", "Är mall"),
			render: (i) => (i.is_template ? <Badge color={Colors.green}>Är mall</Badge> : null),
		},
		{
			handle: "IS_DEFAULT",
			label: t("email_templates.columns.is_default.label", "Är standardmall"),
			render: (i) => (i.is_default ? <Badge color={Colors.green}>är standard mall</Badge> : null),
		},
	];

	return (
		<IndexTable
			title={t("email_templates.list.title", "E-postmallar") as string}
			history={history}
			primaryAction={{
				content: t("email_templatess.new", "Ny E-postmall") as string,
				url: "/admin/email_templates/create",
			}}
			// ref={ref}
			// params={params}
			onClick={handleOnClick}
			defaultFilters={[
				{
					key: "is_template",
					value: 1,
				},
			]}
			// params={{
			// 	is_template: 1,
			// }}
			// filters={filters}
			resourceUrl={"/api/email_templates.json"}
			resourceName={{
				singular: t("email_templates.singular", "E-postmall") as string,
				plural: t("email_templates.plural", "E-postmallar") as string,
			}}
			resourceHandle={"email_templates"}
			filterKey={"email_templates"}
			columns={columns}
			// promotedBulkActions={promotedBulkActions}
			bulkActions={[]}
		/>
	);
};
export default EmailTemplateList;
