import { Tooltip } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import Button from "../Button";
import getValidationStatus from "src/js/views/Outreach/OutboundEmail/Utilities/getValidationStatus";
import API from "src/js/API";
import Toast from "../Toast";
import useQuery from "src/js/hooks/useQuery";
import { ExchangeMajor } from "@shopify/polaris-icons";
import Spinner from "../Spinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Colors, { getContrastingTextColor } from "src/js/Colors";

type EmailValidationButtonProps = {
	onValidated?: () => EmailValidationResult;
	emailValidationResult?: EmailValidationResult;
	email?: EmailAddressType;
	showOnlyIcon?: boolean;
};

const EmailValidationButton = ({
	onValidated,
	emailValidationResult: propsEmailValidationResult,
	email: propsEmail,
	showOnlyIcon,
}: EmailValidationButtonProps) => {
	const { t } = useTranslation(["outreach"]);
	const queryClient = useQueryClient();
	const email = propsEmail?.toLowerCase();

	const queryKey = email ? ["emailValidationResult", email] : [];
	const { data: emailValidationResult, isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				if (propsEmailValidationResult?.email === email) return propsEmailValidationResult;

				const res = await API.get(`/api/email_result.json`, {
					params: {
						email,
					},
				});

				return res.data.email_validation_result;
			} catch (error) {
				Toast.error(error);
			}
		},
		enabled: !!email,
		initialData: propsEmailValidationResult,
	});

	const handleValidateMuation = useMutation(
		async () => {
			try {
				const res = await API.post(`/api/validate_email.json`, {
					email,
				});

				const emailValidationResult = res.data.email_validation_result;
				const status = getValidationStatus(emailValidationResult as EmailValidationResult);

				Toast.success(status.label, {
					style: {
						backgroundColor: status.color,
					},
				});

				return emailValidationResult;
			} catch (e) {
				Toast.error(e);
			}
		},
		{
			onSuccess: (emailValidationResult) => {
				if (queryKey) queryClient.setQueryData(queryKey, emailValidationResult);
				if (onValidated) onValidated();
			},
		}
	);

	//if email and emailValidationResult is not null and emailValidationResult.email is not equal to email then return null, becuase we dont wanna show the the email they are currently writing is valid if it havent been verified yet
	if (email && emailValidationResult && String(emailValidationResult.email).toLowerCase() !== email) return null;

	const status = getValidationStatus(emailValidationResult as EmailValidationResult);
	const isDisabled = !!emailValidationResult;
	const isLoading = isFetching || handleValidateMuation.isLoading;

	const handleValidate = async () => {
		return await handleValidateMuation.mutate();
	};

	const content = status ? (
		<>
			<p>{status?.helpText}</p>

			{status?.subStatus && (
				<>
					<br />
					<p>{status?.subStatus?.helpText}</p>
				</>
			)}
		</>
	) : (
		"Validera e-postadress"
	);

	const color = !emailValidationResult && !isFetching ? Colors.accent : status?.color;
	const buttonText = showOnlyIcon
		? undefined
		: !emailValidationResult && !isFetching
		? t("outreach.outbound_email_batches.fields.status.pending_action", "Validera e-postadress")
		: status?.label;

	return (
		<Wrapper color={color} disabled={isDisabled} isFetching={isLoading}>
			<Tooltip content={content}>
				<Button onClick={handleValidate} icon={isLoading ? <Spinner size="small" /> : !status ? ExchangeMajor : status.icon} disabled={isLoading}>
					{buttonText}
				</Button>
			</Tooltip>
		</Wrapper>
	);
};
export default EmailValidationButton;

const Wrapper = styled.div<{ color: string; disabled: boolean; isFetching: boolean }>`
	&&&&& {
		button {
			border-radius: 6px;
			background-color: ${({ color }) => color};
			cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
			pointer-events: ${({ disabled, isFetching }) => (disabled || isFetching ? "none" : "auto")};
			color: ${({ color }) => getContrastingTextColor(color)};

			svg {
				fill: ${({ color }) => getContrastingTextColor(color)};
			}
		}
	}
`;
