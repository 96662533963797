import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Modal from "src/js/components/modal";
import TextField from "src/js/components/TextField";
import Toast from "src/js/components/Toast";

const SendTestEmailModal = ({ isOpen, onClose, outboundEmail }) => {
	const { t } = useTranslation(["outreach", "common"]);
	const [email, setEmail] = useState("");

	const handleSend = async () => {
		try {
			await API.post(`/api/outbound_emails/${outboundEmail.id}/send_test.json`, {
				email,
			});

			Toast.success(t("outreach.SendTestEmailModal.success1", "Test skickat till {{email}}", { email }));

			onClose();
		} catch (e) {
			Toast.error(e);
		}
	};

	return (
		<Modal open={isOpen} onClose={onClose} title="Skicka testutskick" primaryAction={{ content: "Skicka", onAction: handleSend, disabled: !email }}>
			<TextField label="E-post" value={email} onChange={(value) => setEmail(value)} />
		</Modal>
	);
};
export default SendTestEmailModal;
