import i18n from "src/js/i18n";

import CustomFieldCreate from "src/js/views/OrderFlow/CustomFields/Create";
import CustomField from "src/js/views/OrderFlow/CustomFields";
import CustomFieldsList from "src/js/views/OrderFlow/CustomFields/List";

export default () => [
	{
		path: "/admin/customfields",
		exact: true,
		component: CustomFieldsList,
		name: i18n.t("routes.names.customfields", "Anpassade fält"),
	},
	{
		path: "/admin/customfields/:id(\\d+)",
		exact: true,
		component: CustomField,
		name: i18n.t("routes.names.customfield", "Anpassat fält"),
	},
	{
		path: "/admin/customfields/create",
		exact: true,
		component: CustomFieldCreate,
		name: i18n.t("routes.names.customfield_create", "Skapa anpassat fält"),
	},
];
