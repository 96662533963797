import React, { useState, useRef, useEffect } from "react";
import { Button, LegacyStack } from "@shopify/polaris";
import EmailEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import API from "../../API";
import SkeletonPage from "src/js/components/Skeletons/Page";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";

const EmailCampaignTemplate = () => {
	const { id } = useParams();
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation(["email_campaign", "common"]);

	const initialCampaign = location.state?.email_campaign;
	const [campaign] = useState(initialCampaign || null);
	const [form, setForm] = useState({
		title: initialCampaign ? initialCampaign.title : "",
		content: "",
		design: {},
	});
	const [saving, setSaving] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [disabled] = useState(!!(initialCampaign && initialCampaign.sent && initialCampaign.type === "newsletter"));

	const editorRef = useRef(null);
	const designLoaded = useRef(false);

	// Use react-query to fetch the email template if an id is provided.
	const { data, isLoading, error } = useQuery({
		queryKey: id ? ["emailTemplate", id] : null, // Only fetch the template if an id is provided.
		queryFn: () => API.get(`/api/email_templates/${id}.json`).then((res) => res.data.email_template),
		enabled: !!id,
	});

	// Update the form state once the API data is fetched.
	useEffect(() => {
		if (data) {
			setForm(data);
		}
	}, [data]);

	// Optionally handle errors from the query.
	useEffect(() => {
		if (error) {
			Toast.error(error);
		}
	}, [error]);

	const onLoadEditor = () => {
		if (editorRef.current && id && !designLoaded.current) {
			editorRef.current.loadDesign(form.design);
			designLoaded.current = true;
		}
	};

	const onDesignLoaded = () => {
		onLoadEditor();
		setLoaded(true);
	};

	const saveForm = () => {
		if (disabled) {
			return;
		}
		setSaving(true);
		if (editorRef.current) {
			editorRef.current.exportHtml((data) => {
				const updatedForm = { ...form, content: data.html, design: data.design };
				setForm(updatedForm);
				doSave(updatedForm);
			});
		}
	};

	const doSave = (updatedForm) => {
		setSaving(true);
		if (campaign && campaign.id && id) {
			// Update existing template
			API.put(`/api/email_templates/${id}.json`, updatedForm)
				.then((result) => {
					setSaving(false);
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(t("email_campaign.responses.template.updated", "Mall uppdaterad"));
					history.goBack(-1);
				})
				.catch((error) => {
					setSaving(false);
					Toast.error(error);
				});
		} else {
			// Create new template
			API.post("/api/email_templates.json", updatedForm)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						setSaving(false);
						return;
					}
					if (campaign) {
						// If a campaign exists, update it with the new template.
						const updatedCampaign = { ...campaign, template: result.data.email_template };
						API.post("/api/email_campaigns.json", updatedCampaign)
							.then((result) => {
								setSaving(false);
								if (result.data.error) {
									Toast.error(result.data.error);
									return;
								}
								Toast.success(t("email_campaign.responses.created", "E-postkampanj skapad"));
								history.replace(`/admin/email_campaigns/${result.data.email_campaign.id}`);
							})
							.catch((error) => {
								setSaving(false);
								Toast.error(error);
							});
					} else {
						Toast.success(t("email_campaign.responses.template.saved", "Mall sparad"));
						setSaving(false);
					}
				})
				.catch((error) => {
					setSaving(false);
					Toast.error(error);
				});
		}
	};

	if (id && isLoading) {
		return <SkeletonPage />;
	}

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<div style={{ width: "100%", padding: "1.2500rem" }}>
				<LegacyStack>
					<LegacyStack.Item fill />
					<LegacyStack>
						<Button onClick={() => history.goBack()}>{t("common.actions.cancel", "Avbryt")}</Button>
						<Button primary disabled={!loaded || disabled} loading={saving} onClick={saveForm}>
							{t("common.actions.save", "Spara")}
						</Button>
					</LegacyStack>
				</LegacyStack>
			</div>
			<div style={{ height: "100%" }} className="unlayer_container">
				<EmailEditor
					ref={editorRef}
					locale="sv-SE"
					appearance={{
						theme: "dark",
						panels: {
							tools: {
								dock: "left",
							},
						},
					}}
					onLoad={onLoadEditor}
					onDesignLoad={onDesignLoaded}
				/>
			</div>
		</div>
	);
};

export default EmailCampaignTemplate;
