import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import API from "src/js/API";
import { useEventListener } from "src/js/hooks/UseEventListener";
import { store } from "src/js/store";
import FortnoxReconnectModal from "./FortnoxReconnectModal";
import useQuery from "src/js/hooks/useQuery";
import Toast from "src/js/components/Toast";

export const DEFAULT_SCOPES = ["customer", "article", "invoice", "print", "settings", "companyinformation"];

export type FortnoxGlobalContextProps = {
	openWindow: (params: { url: string; params: any }) => void;
	handleReconnect: () => void;
	handleDiconnect: () => void;
	t?: any;
	pipelineSettings?: any;
	isLoadingPipelineSettings?: boolean;
	refetchPipelines: () => void;
};

const FortnoxGlobalContext = React.createContext<FortnoxGlobalContextProps>({
	openWindow: () => {},
	handleReconnect: () => {},
	handleDiconnect: () => {},
	refetchPipelines: () => {},
});

const FortnoxGlobalContextProvider: React.FC<{ children: React.ReactNode; t?: any }> = ({ children, t }) => {
	const [reconnectModalIsOpen, setReconnectModalIsOpen] = React.useState<boolean>(false);
	const blockReconnectModal = useRef<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getScopesArray = (authentication = store.getState().account.fortnox_integration.authentication) => {
		return authentication?.scope?.split(" ");
	};

	const pipelineSettingsQueryKey = ["pipeline_settings_fortnox"];
	const {
		data: pipelineSettings,
		isFetching: isLoadingPipelineSettings,
		refetch: refetchPipelines,
	} = useQuery({
		queryKey: pipelineSettingsQueryKey,
		queryFn: async () => {
			const response = await API.get("/api/fortnox/pipeline_settings.json");
			return response.data.pipeline_settings;
		},
	});

	const openWindow = useCallback(async ({ url = "/api/fortnox/connect", params }) => {
		const { handle } = store.getState().account;

		const scopes = [...new Set([...(params.scopes || "").trim().split(" "), ...DEFAULT_SCOPES])].filter(Boolean);
		params.scopes = scopes.join(" ");

		const urlSearchParams = new URLSearchParams(params);
		urlSearchParams.append("handle", handle);

		const newUrl = `${url}?${urlSearchParams.toString()}`;

		if ([3, 419].includes(store.getState().user.id)) {
			console.debug("newUrl:", newUrl);
		}

		window.location.href = newUrl;
	}, []);

	const handleDiconnect = useCallback(async () => {
		const result = await API.delete(`/api/fortnox.json`);
		if (result?.data?.integration) {
			Toast.success(`${result.data.integration.title} ${t("fortnox.settings.responses.disconnectd", "Fortnox har kopplats bort")}`);
			const response = await API.get("/api/account.json");
			if (response.data) store.dispatch({ type: "SET_ACCOUNT", account: response.data });

			return true;
		}
	}, [t]);

	// const getScopes = (type: string | null) => {
	// 	switch (type) {
	// 		case "order":
	// 			return "customer order offer article invoice print settings";
	// 		case "invoice":
	// 			return "customer invoice article invoice print settings";
	// 		default:
	// 			return store.getState().account.fortnox_integration?.authentication?.scopes || "customer invoice article invoice print settings";
	// 	}
	// };

	const handleReconnect = useCallback(() => {
		const previousScopes =
			store.getState().account?.fortnox_integration?.authentication?.scope ||
			store.getState().account?.fortnox_integration?.previous_authentication?.scope;
		const previousScopesArray = previousScopes?.split(" ");

		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: previousScopes || DEFAULT_SCOPES.join(" "),
				type: `fortnox${previousScopesArray?.includes("order") ? "" : "_invoice"}`,
				title: "Fortnox",
				integration_id: "fortnox",
			},
		});
	}, [openWindow]);

	const handleOpenReconnectModal = useCallback(() => {
		if (blockReconnectModal.current) {
			if (!reconnectModalIsOpen) Toast.error("Fortnox har tappat kopplingen. Vänligen återkoppla Fortnox.");
			return;
		}

		setReconnectModalIsOpen(true);
	}, [reconnectModalIsOpen]);

	useEventListener("fortnoxReconnect" as any, handleOpenReconnectModal, window as any);

	const value: FortnoxGlobalContextProps = useMemo(
		() => ({
			openWindow,
			handleReconnect,
			handleDiconnect,
			pipelineSettings,
			isLoadingPipelineSettings,
			refetchPipelines,
		}),
		[openWindow, handleReconnect, handleDiconnect, pipelineSettings, isLoadingPipelineSettings, refetchPipelines]
	);

	useEffect(() => {
		//IF Fortnox is enabled, test its connection
		(async () => {
			if (process.env.NODE_ENV === "development") return;

			try {
				await API.get("/api/fortnox/test_connection.json");
			} catch (e) {
				Toast.error(e);
			}
		})();
	}, []);

	return (
		<FortnoxGlobalContext value={value as FortnoxGlobalContextProps}>
			{children}

			<FortnoxReconnectModal
				open={reconnectModalIsOpen}
				onClose={() => {
					blockReconnectModal.current = true;
					setReconnectModalIsOpen(false);
				}}
				handleReconnect={handleReconnect}
			/>
		</FortnoxGlobalContext>
	);
};

const mapStateToProps = (state: any) => ({
	account: state.account,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedFortnoxGlobalContextProvider: React.ComponentType<{ children: React.ReactNode } & PropsFromRedux> = connector(
	withTranslation(["fortnox", "common"])(FortnoxGlobalContextProvider)
);

export default FortnoxGlobalContext;
export { ConnectedFortnoxGlobalContextProvider as FortnoxGlobalContextProvider };
