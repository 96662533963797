import React from "react";
import { StyledSettings, StyledSettingsInnerCard } from "./styles";
import SettingsItem from "./SettingsItem";
import { LegacyCard } from "@shopify/polaris";
import { checkUserRoles } from "src/js/Utilities";
import { routesAsObject } from "src/js/Routes";

type SectionItem = {
	onAction?: () => void;
	disabled?: boolean;
	backgroundColor?: string;
	icon?: any;
	title?: string;
	description?: string;
	path?: string;
	tooltip?:
		| string
		| {
				content: string;
				icon?: any;
		  };
	loading?: boolean;

	confirmation?: any;
	confirm?: any;
	roles?: string[];
};

type Section = {
	title?: string;
	items: SectionItem[];

	enabled?: boolean;
	roles?: string[];
};

type SettingsProps = {
	sections: Section[];
};

const Settings = ({ sections }: SettingsProps) => {
	return (
		<StyledSettings>
			{sections
				?.filter((s) => (s.title || s.items?.length) && (!("enabled" in s) || s.enabled) && (!s.roles?.length || checkUserRoles(s.roles)))
				.map((section, key) => {
					return (
						<LegacyCard key={key} title={section.title}>
							<StyledSettingsInnerCard>
								{section?.items
									.filter(
										(item) =>
											(!("enabled" in item) || item.enabled) &&
											(!item.path || checkUserRoles(routesAsObject[item.path]?.roles)) &&
											(!item.roles?.length || checkUserRoles(item.roles))
									)
									.map((item: any, key) => {
										return <SettingsItem key={key} item={item} />;
									})}
							</StyledSettingsInnerCard>
						</LegacyCard>
					);
				})}
		</StyledSettings>
	);
};
export default Settings;
