import i18n from "src/js/i18n";

import Integrations from "src/js/views/Integrations/Integrations.js";
import IntegrationFullscreen from "src/js/views/Integrations/IntegrationFullscreen.js";
import IntegrationConnected from "src/js/components/IntegrationConnected.js";

export default () => [
	{
		path: "/admin/integrations/:id(\\d+)/:rand",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: IntegrationFullscreen,
	},
	{
		path: "/admin/integrations/:id(\\d+)/:rand([\\d|\\.]+):path(.+)",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: IntegrationFullscreen,
	},
	{
		path: "/admin/integration/connected",
		mainMenu: false,
		exact: true,
		component: IntegrationConnected,
		name: i18n.t("routes.names.integration_connected", "Integrationer"),
	},
	{
		path: "/admin/integrations",
		settings: true,
		description: "Tillåter dina system att kommunicera med Sellfinity för en bättre upplevelse",
		exact: true,
		component: Integrations,
		name: i18n.t("routes.names.integrations", "API & Integrationer"),
		roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
	},
];
