import i18n from "src/js/i18n";

import GroupIndex from "src/js/views/Groups/index";
import GroupCreate from "src/js/views/Groups/create";
import GroupEdit from "src/js/views/Groups/edit";

export default () => [
	{
		path: "/admin/groups",
		mainMenu: true,
		exact: true,
		component: GroupIndex,
		name: i18n.t("routes.names.groups", "Grupper"),
	},
	{
		path: "/admin/groups/create",
		mainMenu: false,
		exact: true,
		component: GroupCreate,
		name: i18n.t("routes.names.group_create", "Skapa grupp"),
	},
	{
		path: "/admin/groups/:id",
		mainMenu: false,
		exact: true,
		component: GroupEdit,
		name: i18n.t("routes.names.group_edit", "Redigera grupp"),
	},
];
