/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Spinner, EmptyState, ExceptionList, Badge } from "@shopify/polaris";
import moment from "moment";
import {
	ExportMinor,
	ImportMinor,
	ProfileMajor,
	NoteMajor,
	HeartMajor,
	AddProductMajor,
	RemoveProductMajor,
	DeleteMajor,
} from "@shopify/polaris-icons";
import { connect, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import ContactModal from "./ContactModal";
import Toast from "src/js/components/Toast";
import SellerSelector from "../../components/SellerSelector.js";
import RelationshipSelector from "../../components/RelationshipSelector.js";
import ChangeRelationshipModal from "../../components/ChangeRelationshipModal.js";
import ChangeUserModal from "../../components/ChangeUserModal.js";
import TagModal from "../../components/tag_modal.js";
import UpdateContactMetafieldModal from "../../components/UpdateContactMetafieldModal.js";
import ImportModal from "../Workspaces/components/ImportModal.js";
import getDefaultFilters from "../../components/IndexTable/getDefaultFilters";
import IndexTable from "src/js/components/IndexTable";
import Modal from "src/js/components/modal";
import ImportToDiallistModal from "../DialList/Modals/ImportToDiallistModal";
import { useHistory, useLocation } from "react-router-dom";
import { IndexTableColumn } from "src/js/components/IndexTable/types";

const ContactPeople = forwardRef((props: any, ref) => {
	// Local state
	const [empty, setEmpty] = useState(false);
	const [firstFetch, setFirstFetch] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [params, setParams] = useState({});
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [visibleItems, setVisibleItems] = useState([]);
	const [currentContact, setCurrentContact] = useState<ContactType | Partial<ContactType>>({ tags: [] });
	const [callListImportModalIsOpen, setCallListImportModalIsOpen] = useState(false);
	const [showContactModal, setShowContactModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [showImportModal, setShowImportModal] = useState(false);
	const [showAddTagModal, setShowAddTagModal] = useState(false);
	const [showRemoveTagModal, setShowRemoveTagModal] = useState(false);
	const [showRelationshipModal, setShowRelationshipModal] = useState(false);
	const [showSellerModal, setShowSellerModal] = useState(false);
	const [currentMetafield, setCurrentMetafield] = useState<any>(null);
	const [showUpdateContactMetafieldModal, setShowUpdateContactMetafieldModal] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [bulkRemoveModalIsOpen, setBulkRemoveModalIsOpen] = useState(false);

	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation(["contact", "common"]);

	useSelector((state: any) => state.board_contacts);
	useSelector((state: any) => state.fields);
	useSelector((state: any) => state.contact_metafields);

	// Ref to the inner IndexTable so we can call its refresh methods
	const indexTableRef = useRef<any>(null);

	// Handlers and helper functions

	// Expose inner methods (e.g. getFilters) to parent via ref
	useImperativeHandle(ref, () => ({
		getFilters: () => indexTableRef.current?.getFilters(),
		setFilters: ({ filters, search }) =>
			indexTableRef.current?.setFilters({
				filters,
				search,
			}),
	}));

	const onSelectionChange = (items) => {
		setSelectedItems(items);
	};

	const updateParams = (newParams) => {
		setParams(newParams);
	};

	const openContactModal = (item) => {
		setShowContactModal(true);
		setCurrentContact(item);
	};

	const closeContactModal = () => {
		setShowContactModal(false);
	};

	const gotoContact = (item) => {
		item.path = `/admin/contacts/${item.id}`;
		history.push(`/admin/contacts/${item.id}`, {
			stacks: [
				{
					parent_path: location.pathname + location.search,
					parent_title: t("contact.person.plural", "Kontaktpersoner"),
					item: { id: item.id, item, path: `/admin/contacts/${item.id}` },
					items: visibleItems,
				},
			],
			path: {
				title: t("contact.person.plural", "Kontaktpersoner"),
				url: "/admin/contacts/people",
			},
		});
	};

	const exportExcel = () => {
		setShowExportModal(true);
		const newParams = { ...params, is_company: 0, is_visitor: 0 };
		API.post("/api/contacts/export.json", {}, { params: newParams });
	};

	const importExcel = () => {
		setShowImportModal(true);
	};

	const getUser = (field, user) => {
		for (let i = 0; i < user.fields.length; i++) {
			if (user.fields[i].field_id === field.id) {
				return user.fields[i].user;
			}
		}
		return null;
	};

	const getRelationship = (field, contact) => {
		for (let i = 0; i < contact.fields.length; i++) {
			if (contact.fields[i].field_id === field.id) {
				return contact.fields[i].relationship;
			}
		}
		return "none";
	};

	const changeContactRelationship = (field, contact, relationship) => {
		setSaving(true);
		API.put(`/api/contacts/${contact.id}/fields/${field.id}/relationship.json`, { relationship })
			.then((result) => {
				Toast.success(t("contact.responses.relation.changed", "Relation ändrad"));
				if (indexTableRef.current) indexTableRef.current.refresh(false, true);
				setSaving(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const changeContactUser = (field, contact, user) => {
		setSaving(true);
		API.put(`/api/contacts/${contact.id}/fields/${field.id}/user.json`, { user })
			.then((result) => {
				Toast.success(t("contact.responses.user_contact.changed", "Kundansvarig ändrad"));
				if (indexTableRef.current) indexTableRef.current.refresh(false, true);
				setSaving(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const onItemsFetched = (items) => {
		if (items.length < 1 && firstFetch && indexTableRef.current && !indexTableRef.current.state?.appliedFilters?.length) {
			setEmpty(true);
		} else {
			setEmpty(false);
		}
		items.forEach((item) => {
			item.path = `/admin/contacts/${item.id}`;
		});
		setVisibleItems(items);
		setFirstFetch(false);
	};

	const getBulkParams = () => {
		if (selectedItems === "All") {
			return { ...params, is_company: 0, is_visitor: 0 };
		} else {
			return { ids: selectedItems };
		}
	};

	const closeAddTagModal = () => {
		setShowAddTagModal(false);
	};

	const addTags = () => {
		setShowAddTagModal(true);
	};

	const closeRemoveTagModal = () => {
		setShowRemoveTagModal(false);
	};

	const removeTags = () => {
		setShowRemoveTagModal(true);
	};

	const doAddTags = (tags) => {
		setSaving(true);
		API.post("/api/contacts/tags.json", { tags }, { params: getBulkParams() })
			.then((result) => {
				if (indexTableRef.current) indexTableRef.current.refresh();
				Toast.success(t("contact.responses.tags.added", "La till taggar"));
				setSaving(false);
				setShowAddTagModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const doRemoveTags = (tags) => {
		setSaving(true);
		API.post("/api/contacts/remove_tags.json", { tags }, { params: getBulkParams() })
			.then((result) => {
				if (indexTableRef.current) indexTableRef.current.refresh();
				Toast.success(t("contact.responses.tags.removed", "Tog bort taggar"));
				setSaving(false);
				setShowRemoveTagModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const closeRelationshipModal = () => {
		setShowRelationshipModal(false);
	};

	const changeRelationship = () => {
		setShowRelationshipModal(true);
	};

	const doChangeRelationship = (relationship, field) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit.json", { relationship, field }, { params: getBulkParams() })
			.then((result) => {
				if (indexTableRef.current) indexTableRef.current.refresh();
				Toast.success(t("contact.responses.relation.changed_plural", "Ändrade relationer"));
				setSaving(false);
				setShowRelationshipModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const closeSellerModal = () => {
		setShowSellerModal(false);
	};

	const changeSeller = () => {
		setShowSellerModal(true);
	};

	const doChangeSeller = (seller, field) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit.json", { user: seller, field }, { params: getBulkParams() })
			.then((result) => {
				if (indexTableRef.current) indexTableRef.current.refresh();
				Toast.success(t("contact.responses.customer_user.changed_plural", "Kundansvarig ändrad"));
				setSaving(false);
				setShowSellerModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const updateMetafield = (metafield) => {
		setCurrentMetafield(metafield);
		setShowUpdateContactMetafieldModal(true);
	};

	const closeUpdateContactMetafieldModal = () => {
		setShowUpdateContactMetafieldModal(false);
	};

	const doUpdateContactMetafield = (value) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit_metafield.json", { metafield: currentMetafield, value }, { params: getBulkParams() })
			.then((result) => {
				if (indexTableRef.current) indexTableRef.current.refresh();
				Toast.success(`${t("common.terms.changed", "Ändrade")} ${currentMetafield!.title}`);
				setSaving(false);
				setShowUpdateContactMetafieldModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const bulkRemoveContacts = async (ids) => {
		try {
			if (!ids?.length) {
				throw new Error("No contacts selected");
			}
			setRemoving(true);
			const result = await API.delete("/api/contacts.json", { data: { ids } });
			if (indexTableRef.current) indexTableRef.current.refresh();
			Toast.success(
				t("contact.responses.removed_people", "Tog bort {{count}}st kontakter", {
					count: result.data.count,
				})
			);
			setBulkRemoveModalIsOpen(false);
		} catch (error) {
			Toast.error(error);
		} finally {
			setRemoving(false);
		}
	};

	const toggleCallListImportModalIsOpen = (open = !callListImportModalIsOpen) => {
		setCallListImportModalIsOpen(open);
	};

	// Build filters, bulk actions, and columns

	const filters = getDefaultFilters("people");

	const bulkActions: any[] = [
		{
			items: [
				{
					icon: ProfileMajor,
					content: t("contact.actions.customer.change", "Ändra kundansvarig"),
					onAction: changeSeller,
				},
				{
					icon: HeartMajor,
					content: t("contact.actions.relation.change", "Ändra relation"),
					onAction: changeRelationship,
				},
			],
		},
		{
			items: [
				{
					icon: AddProductMajor,
					content: t("contact.actions.tags.add", "Lägg till taggar"),
					onAction: addTags,
				},
				{
					icon: RemoveProductMajor,
					content: t("contact.actions.tags.remove", "Ta bort taggar"),
					onAction: removeTags,
				},
			],
		},
		{
			items: [
				{
					icon: ImportMinor,
					content: t("contact.actions.import.dial_entry", "Importera till ringlista"),
					onAction: toggleCallListImportModalIsOpen,
				},
			],
		},
	];

	const columns: IndexTableColumn[] = [
		{
			handle: "NAME",
			label: t("contact.columns.name.label", "Namn"),
			sortable: true,
			render: (item) => {
				const exceptions: any[] = [];
				if (item.note) {
					exceptions.push({
						icon: NoteMajor,
						description: item.note,
					});
				}
				const exceptionList = exceptions.length ? (
					<div className="CustomerListItem__Exceptions">
						<ExceptionList items={exceptions} />
					</div>
				) : null;
				return (
					<div>
						<div className="CustomerListItem__Profile">
							<h3 className="CustomerListItem__Title">{item.name}</h3>
							{!props.company?.id && <span className="CustomerListItem__Location">{item.parent ? item.parent.name : null}</span>}
						</div>
						<div>
							{item.tags.map((prop, key) => (
								<Badge key={key}>{prop.title}</Badge>
							))}
						</div>
						{exceptionList}
					</div>
				);
			},
		},
		{
			handle: "EMAIL",
			label: t("contact.columns.email.label", "E-post"),
			sortable: true,
			render: (item) => item.email,
		},
		{
			handle: "PHONE",
			label: t("contact.columns.phone.label", "Telefon"),
			sortable: true,
			render: (item) => item.phone,
		},
		{
			handle: "MOBILEPHONE",
			label: t("contact.columns.mobilephone.label", "Mobiltelefon"),
			sortable: true,
			render: (item) => item.mobile_phone,
		},
		{
			handle: "TITLE",
			label: t("contact.columns.title.label", "Befattning/Roll"),
			sortable: true,
			render: (item) => item.title,
		},
		{
			handle: "LAST_ACTIVITY",
			label: t("contact.columns.last_activity.label", "Senast aktivitet"),
			sortable: true,
			render: (item) => (item.last_activity_at ? moment(item.last_activity_at).locale("sv").calendar() : "–"),
		},
	];

	store.getState().fields.forEach((field) => {
		columns.push({
			handle: `USER_${field.id}`,
			label: t("contact.columns.contact_user.label", "Kundansvarig") + (store.getState().fields.length > 1 ? " " + field.title : ""),
			sortable: false,
			render: (item) => <SellerSelector user={getUser(field, item)} onChange={(user) => changeContactUser(field, item, user)} clearable />,
		});
		columns.push({
			handle: `RELATIONSHIP_${field.id}`,
			label: t("contact.columns.relation.label", "Relation") + (store.getState().fields.length > 1 ? " " + field.title : ""),
			sortable: false,
			render: (item) => (
				<RelationshipSelector
					relationship={getRelationship(field, item)}
					onChange={(relationship) => changeContactRelationship(field, item, relationship)}
				/>
			),
		});
	});

	const metafieldActions: any = { items: [] };
	for (let i = 0; i < store.getState().contact_metafields.length; i++) {
		if (store.getState().contact_metafields[i].for === "person") {
			columns.push({
				handle: "METAFIELD_" + store.getState().contact_metafields[i].id,
				label: store.getState().contact_metafields[i].title,
				sortable: false,
				render: (item, column) => {
					const metafieldId = parseInt(column.handle.substring(10));
					let metaField: any = null;
					for (let j = 0; j < store.getState().contact_metafields.length; j++) {
						if (store.getState().contact_metafields[j].id === metafieldId) {
							metaField = store.getState().contact_metafields[j];
							break;
						}
					}
					if (metaField) {
						for (let j = 0; j < item.metafields.length; j++) {
							if (item.metafields[j].metafield_id === metafieldId) {
								if (metaField.type === "boolean") return item.metafields[j].value ? "Ja" : "Nej";
								return item.metafields[j].value + "";
							}
						}
					}
					return "–";
				},
			});
			metafieldActions.items.push({
				icon: AddProductMajor,
				content: store.getState().contact_metafields[i].title,
				onAction: () => updateMetafield(store.getState().contact_metafields[i]),
			});
		}
	}
	if (store.getState().contact_metafields.length) {
		bulkActions.push(metafieldActions);
	}
	if (store.getState().user.roles?.includes("ROLE_ADMIN")) {
		bulkActions.push({
			items: [
				{
					icon: DeleteMajor,
					content: t("contact.actions.remove", "Ta bort"),
					onAction: () => {
						setBulkRemoveModalIsOpen(true);
					},
					destructive: true,
					loading: removing,
				},
			],
		});
	}

	const defaultParams: any = { is_company: 0, is_visitor: 0 };
	if (props.company?.id) {
		defaultParams.parent_id = props.company.id;
	}

	return (
		<div>
			<IndexTable
				urlParamsEnabled={props.urlParamsEnabled}
				params={defaultParams}
				inline={!!props.company || props.inline}
				ref={indexTableRef}
				history={history}
				setRefreshHandler={(refreshHandler) => {
					if (indexTableRef.current) {
						indexTableRef.current.refresh = refreshHandler;
					}
				}}
				title={t("contact.person.title", "Kontaktpersoner") as string}
				secondaryActions={[
					{
						icon: ExportMinor,
						content: t("contact.actions.export", "Exportera") as string,
						onAction: exportExcel,
						roles: ["ROLE_ADMIN"],
					},
					{
						icon: ImportMinor,
						content: uploading ? ((<Spinner size="small" />) as any) : t("contact.actions.import", "Importera"),
						onAction: importExcel,
						enabled: !props.company,
						roles: ["ROLE_ADMIN"],
					},
				].filter((action) => !("enabled" in action) || action.enabled)}
				primaryAction={{
					content: t("contact.person.actions.new", "Ny kontaktperson") as string,
					onAction: () =>
						openContactModal({
							name: "",
							company: "",
							email: "",
							phone: "",
							zip: "",
							note: "",
							address1: "",
							address2: "",
							country: "SE",
							tags: [],
						}),
					primary: true,
				}}
				refreshKey="people"
				filterKey="people"
				savedSearchHandle="contact_people"
				resourceUrl="/api/contacts_verbose.json"
				resourceHandle="contacts"
				onItemsFetched={onItemsFetched}
				onSelectionChange={onSelectionChange}
				resourceName={{
					singular: t("contact.person.singular", "kontaktperson"),
					plural: t("contact.person.plural", "kontaktpersoner"),
				}}
				onUpdateParams={updateParams}
				filters={filters}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				onClickRow={gotoContact}
				bulkActions={bulkActions}
				emptyState={
					empty && (
						<div>
							<EmptyState
								heading={t("contact.person.error.empty.heading", "Inga kontaktpersoner") as string}
								action={{
									content: t("contact.person.actions.create", "Skapa kontaktperson") as string,
									onAction: () =>
										openContactModal({
											name: "",
											company: "",
											email: "",
											phone: "",
											zip: "",
											note: "",
											address1: "",
											address2: "",
											country: "SE",
											tags: [],
										}),
								}}
								image="/assets/images/empty_state/People.png"
							>
								<p>{t("contact.person.error.empty.text", "Du har inga kontaktpersoner")}</p>
							</EmptyState>
							<ContactModal
								open={showContactModal}
								onUpdate={() => setEmpty(false)}
								contact={currentContact as ContactType}
								company={props.company}
								onCreate={(contact) => {
									if (props.company) {
										if (indexTableRef.current) indexTableRef.current.refresh();
									} else {
										gotoContact(contact);
									}
								}}
								onClose={closeContactModal}
							/>
						</div>
					)
				}
			/>
			<ContactModal
				company={props.company}
				open={showContactModal}
				onUpdate={() => {
					if (indexTableRef.current) indexTableRef.current.refresh();
				}}
				contact={currentContact as ContactType}
				onCreate={(contact) => {
					if (props.company) {
						if (indexTableRef.current) indexTableRef.current.refresh();
					} else {
						gotoContact(contact);
					}
				}}
				onClose={closeContactModal}
			/>
			<TagModal
				open={showAddTagModal}
				onClose={closeAddTagModal}
				resourceName={{
					singular: t("contact.singular", "kontakt"),
					plural: t("contact.plural", "kontakter"),
				}}
				resource="contacts"
				numItems={selectedItems.length}
				onConfirm={doAddTags}
				loading={saving}
				params={{ type: "person" }}
			/>
			<TagModal
				open={showRemoveTagModal}
				onClose={closeRemoveTagModal}
				resourceName={{
					singular: t("contact.singular", "kontakt"),
					plural: t("contact.plural", "kontakter"),
				}}
				resource="contacts"
				numItems={selectedItems.length}
				onConfirm={doRemoveTags}
				loading={saving}
				remove
			/>
			<ChangeRelationshipModal
				open={showRelationshipModal}
				loading={saving}
				onClose={closeRelationshipModal}
				onConfirm={doChangeRelationship}
				numItems={selectedItems.length}
			/>
			<ChangeUserModal
				open={showSellerModal}
				loading={saving}
				onClose={closeSellerModal}
				onConfirm={doChangeSeller}
				numItems={selectedItems.length}
			/>
			<UpdateContactMetafieldModal
				open={showUpdateContactMetafieldModal}
				loading={saving}
				onClose={closeUpdateContactMetafieldModal}
				onConfirm={doUpdateContactMetafield}
				numItems={selectedItems.length}
				metafield={currentMetafield}
			/>
			<ImportModal
				open={showImportModal}
				type="people"
				onClose={() => setShowImportModal(false)}
				onComplete={() => {
					if (indexTableRef.current) indexTableRef.current.refresh();
				}}
			/>
			<Modal
				open={showExportModal}
				onClose={() => setShowExportModal(false)}
				title={t("contact.export.contacts.title", "Exportera kontaktpersoner")}
				primaryAction={{
					content: t("buttons.close", "Stäng") as string,
					onAction: () => setShowExportModal(false),
				}}
			>
				<Modal.Section>
					<p>{t("contact.export.please_wait", "Din export är på väg. Du kommer att få ett mail när den är klar.")}</p>
				</Modal.Section>
			</Modal>
			<Modal
				sectioned
				title={t("contacts.actions.bulk_remove_people.confirm.title", `Ta bort {{count}}st kontakter?`, {
					count: indexTableRef.current?.getSelectedItems()?.length,
				})}
				open={bulkRemoveModalIsOpen}
				onClose={() => setBulkRemoveModalIsOpen(false)}
				secondaryActions={[
					{
						content: t("common.actions.cancel", "Avbryt") as string,
						onAction: () => setBulkRemoveModalIsOpen(false),
					},
				]}
				primaryAction={{
					content: t("common.actions.remove", "Ta bort") as string,
					loading: removing,
					onAction: () => {
						bulkRemoveContacts(indexTableRef.current?.getSelectedItems());
					},
				}}
			>
				<p>
					{t("contacts.actions.bulk_remove_people.confirm.text", "	Säker på att du vill ta bort {{count}}st kontakter?", {
						count: indexTableRef.current?.getSelectedItems()?.length,
					})}
				</p>
			</Modal>
			<ImportToDiallistModal
				isOpen={callListImportModalIsOpen}
				onSuccess={() => {
					setCallListImportModalIsOpen(false);
					if (indexTableRef.current) indexTableRef.current.clearSelection();
				}}
				onClose={() => {
					toggleCallListImportModalIsOpen(false);
				}}
				contactIds={indexTableRef.current?.getSelection()}
			/>
		</div>
	);
});

export default ContactPeople;
