import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Colors from "../../../Colors";
import IconWithBackround from "../../../components/IconWithBackround";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import { DollarIcon } from "../../../icons";
import { store } from "../../../store";
import TimeLine from "../../../components/TimeLine";
import Note from "../Note";
import UpcomingActivity from "../UpcomingActivity";
import LateActivity from "../LateActivity";
import useQuery from "src/js/hooks/useQuery";
import { UseQueryResult } from "@tanstack/react-query";
import InvoiceGraph from "./economy/InvoiceGraph";
import styled from "styled-components";
import ContractGraph from "./economy/ContractGraph";
import { currencyFormatter } from "src/js/Utilities";
import { ReceiptMajor } from "@shopify/polaris-icons";
import Toast from "src/js/components/Toast";
import getFiscalYearOrDefaultStartDate from "src/js/Utilities/getFiscalYearOrDefaultStartDate";

const ContactInvoiceAndActivitiesDashboard = ({ t, contact, setTab, history }) => {
	const [selectedYear, setSelectedYear] = useState(moment(getFiscalYearOrDefaultStartDate()).format("YYYY"));
	const boardId = store.getState().account.sales_board_id;

	const invoiceParams: any = useMemo(() => {
		const p: any = {};

		if (contact?.id) {
			p.contact_id = contact.id;
			p.also_archived = 1;
		}

		return p;
	}, [contact?.id]);

	const fetchInvoiceData = useCallback(async () => {
		try {
			const res = await API.get(`/api/standard_dashboards/invoice/stats.json`, { params: invoiceParams });
			return res.data.stats;
		} catch (error) {
			Toast.error(error);
			return null;
		}
	}, [invoiceParams]);

	const invoiceQueryKey = [
		`customer_${contact?.id}_invoice_dashboard`,
		Object.keys(invoiceParams).length && `board_${boardId}_rows_${JSON.stringify(invoiceParams)}`,
	].filter(Boolean);
	const { data: stats = null, isFetching: IsLoadingStats }: UseQueryResult<any | null> = useQuery({
		queryKey: invoiceQueryKey,
		queryFn: fetchInvoiceData,
		refetchOnWindowFocus: false,
	});

	return (
		<Wrapper className="contact_dashboard">
			<StatsBoxWrapper
				style={{ gridArea: "invoices", gridTemplateColumns: "1fr 1fr" }}
				loading={IsLoadingStats}
				items={[
					{
						title: t("contact.dashboards.invoice.latest_invoice", "Senaste faktura"),
						text: stats?.invoices?.data?.latest_invoice ? moment(stats?.invoices?.data?.latest_invoice.date).format("YYYY-MM-DD") : "-",
						icon: <IconWithBackround icon={ReceiptMajor} color={Colors.green} />,
					},
					{
						title: t("contact.dashboards.invoice.total_invoiced_current", "Fakturerat (nuvarande period)"),
						text: currencyFormatter({ value: stats?.invoices?.[selectedYear]?.data?.total || 0, currency: "SEK" }),
						icon: <IconWithBackround icon={<DollarIcon color={Colors.purple} />} color={Colors.purple} />,
						loading: IsLoadingStats,
					},
					{
						title: t("contact.dashboards.invoice.total_invoiced_previous", "Fakturerat (föregående period)"),
						text: currencyFormatter({
							value: stats?.invoices?.[moment(selectedYear).subtract(1, "year").format("YYYY")]?.data?.total || 0,
							currency: "SEK",
						}),

						icon: <IconWithBackround icon={<DollarIcon color={Colors.purple} />} color={Colors.purple} />,
						loading: IsLoadingStats,
					},
				]}
			/>

			<ActivitiesWrapper style={{ gridArea: "activities" }}>
				<UpcomingActivity
					contact={contact}
					preview
					onShowMore={() => {
						setTab("activities", "upcoming_tasks");
					}}
				/>

				<LateActivity
					contact={contact}
					preview
					onShowMore={() => {
						setTab("activities", "late_tasks");
					}}
				/>

				<Note
					contact={contact}
					// className={`note_${contact?.is_company ? "company" : "person"}`}
					note={contact?.note}
					disabled={contact?.removed}
				/>

				<div className="timeline">
					<TimeLine
						title={t("contact.timeline.title", "Senaste aktivitet")}
						onShowMore={() => {
							setTab("activities");
						}}
						preview
						disabled={contact?.removed}
						handle={"contacts/" + contact.id}
						tasks={[]}
						deals={[]}
						tickets={[]}
						projects={[]}
						call_lists={[]}
						history={history}
						onComment={() => {}}
						onNewContact={() => {}}
						contact={contact}
						isCompany={contact.is_company}
					/>
				</div>
			</ActivitiesWrapper>

			<GraphWrapper
				className="contact_dashboard__graph"
				style={{
					gridArea: "invoice_graph",
				}}
			>
				<InvoiceGraph data={stats?.invoices} loading={IsLoadingStats} onChangeYear={setSelectedYear} />
			</GraphWrapper>

			<GraphWrapper
				style={{
					gridArea: "contracts_graph",
				}}
			>
				<ContractGraph data={stats?.contracts} loading={IsLoadingStats} />
			</GraphWrapper>
		</Wrapper>
	);
};
export default withTranslation(["contact", "common"], { withRef: true })(ContactInvoiceAndActivitiesDashboard);

const Wrapper = styled.div`
	display: grid;
	gap: 1.875rem;
	grid-template-columns: 1fr 1fr;

	grid-template-areas:
		"invoices activities"
		"invoices activities"
		"invoice_graph contracts_graph";

	.statsBoxWrapper {
		margin: 0;

		.statsBox {
			min-height: 100px;
		}
	}
`;

const GraphWrapper = styled.div`
	flex: 1;
	position: relative;
`;

const ActivitiesWrapper = styled.div`
	display: grid;
	gap: 1.875rem;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"timeline upcoming_activity"
		"note late_activity";
`;
