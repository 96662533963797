import { Checkbox, FormLayout, LegacyCard } from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Page from "src/js/components/page";
import { RightWrapper, Wrapper } from "./styles";
import CollapsibleSection from "src/js/components/CollapsibleSection/CollapsibleSection";
import Chat from "src/js/components/ChatBot";
import API from "src/js/API";
import ResourceOptions from "src/js/components/ResourceOptions";
import useQuery from "src/js/hooks/useQuery";
import useDebounce from "src/js/hooks/useDebounce";
import Badge from "src/js/components/Badge";
import Spinner from "src/js/components/Spinner";
import styled from "styled-components";
import RangeSlider from "src/js/components/RangeSlider";
import Toast from "src/js/components/Toast";
import { useHistory } from "react-router-dom";
import OptionList from "src/js/components/OptionList/OptionList";
import { UseQueryResult } from "@tanstack/react-query";
import Colors from "src/js/Colors";

const ProspectCreate = () => {
	const { t } = useTranslation(["prospects", "common"]);
	const [axiconFilters, setAxiconFilters] = useState<AxiconFiltersType>({});
	const chatRef = React.createRef<any>();
	const history = useHistory();

	const queryMatchesLeftKey = useMemo(() => ["prospect_list"], []);
	const { data: matchesLeftThisMonth, isFetching: isLoadingMatchesLeftThisMonth }: UseQueryResult<any> = useQuery({
		queryKey: queryMatchesLeftKey,
		queryFn: async () => {
			const res = await API.get(`/api/company_registry/matches_left_this_month.json`);
			return res.data.matches_left_this_month;
		},
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const getChangeHandler = useCallback(
		(key) => (value) => {
			setAxiconFilters((axiconFilters) => ({
				...axiconFilters,
				[key]: value || undefined,
			}));
		},
		[]
	);

	const getChangeHandlerforMultiple = (keys: any[]) => (values: any) => {
		setAxiconFilters((axiconFilters) => {
			const newFilters = { ...axiconFilters };
			keys.forEach((key, index) => {
				newFilters[key] = values[index];
			});

			return newFilters;
		});
	};

	const postFilters = useDebounce(() => {
		return Object.entries(axiconFilters).reduce((acc, [key, value]) => {
			if (key === "description") return acc; // Skip description

			if (Array.isArray(value) ? value?.length : value) acc[key] = value;
			return acc;
		}, {});
	}, 1500);

	const postFiltersCount = Object.keys(postFilters).length;

	const { data: count = 0, isFetching: isLoadingCount } = useQuery({
		queryKey: postFiltersCount ? ["prospects", "count", postFilters] : [],
		queryFn: async () => {
			const res = await API.post("/api/company_registry/filter.json", { filter: postFilters, matches: false });
			return res.data.count;
		},
		enabled: !!postFiltersCount,
		initialData: 0,
	});

	const applyFilters = (data) => {
		if (data && typeof data === "object") setAxiconFilters(data);
	};

	const handleSave = useCallback(async () => {
		const loadingToastId = Toast.loading(t("prospects.axicon.response.create.loading", "Skapar prospektlista") as string);
		const chatId = chatRef.current?.getChat()?.chatId;
		try {
			const res = await API.post("/api/prospect_lists.json", { axicon_filter: axiconFilters, chatId });
			if (res.data?.prospect_list) {
				Toast.update(loadingToastId, {
					render: t("prospects.axicon.response.create.success", "Prospektlistan skapad") as string,
					type: "success",
				});
				history.push(`/admin/prospect_lists/${res.data.prospect_list.id}`);
			}
		} catch (error) {
			Toast.dismiss(loadingToastId);
		}
	}, [axiconFilters, chatRef, history, t]);

	const filterSections = useMemo(
		() => [
			{
				key: "exclude_companies_in_contacts",
				label: t("prospects.axicon.filters.exclude_companies_in_contacts.label2", "Exkludera existerande CRM kontakter") as string,
				type: "checkbox",
			},
			{
				key: "exclude_companies_in_prospecting_lists",
				label: t(
					"prospects.axicon.filters.exclude_companies_in_prospecting_lists.label",
					"Exkludera existerande företag i prospekt listor"
				) as string,
				type: "checkbox",
			},
			{
				label: t("prospects.axicon.filters.industries.label", "Branscher") as string,
				key: "sni_codes",
				url: "/api/sni_codes.json",
				valueKey: "code",
				labelKey: "label",
			},
			{
				label: t("prospects.axicon.filters.types.label", "Bolagsformer") as string,
				key: "types",
				type: "options",
				options: [
					{
						label: "Aktiebolag",
						value: "Aktiebolag",
					},
					{
						label: "Enskild näringsidkare",
						value: "Enskild näringsidkare",
					},
					{
						label: "Handelsbolag",
						value: "Handelsbolag",
					},
					{
						label: "Kommanditbolag",
						value: "Kommanditbolag",
					},
					{
						label: "Övriga",
						value: "Övriga",
					},
				],
			},
			{
				label: t("prospects.axicon.filters.cities.label", "Städer") as string,
				key: "cities",
				url: "/api/geography/cities.json",
				valueKey: "title",
			},
			{
				label: t("prospects.axicon.filters.municipalities.label", "Kommuner") as string,
				key: "municipalities",
				url: "/api/geography/municipalities.json",
				valueKey: "title",
			},
			{
				label: t("prospects.axicon.filters.counties.label", "Län") as string,
				key: "counties",
				url: "/api/geography/counties.json",
				valueKey: "title",
			},
			{
				keys: ["turnover_min", "turnover_max"],
				label: t("prospects.axicon.filters.turnover.label", "Omsättning") as string,
				slider: true,
				max: 100000000,
				step: 1000000,
				prefix: "kr",
				suffix: "kr",
			},
			{
				keys: ["profit_min", "profit_max"],
				label: t("prospects.axicon.filters.profit.label", "Vinst") as string,
				slider: true,
				max: 100000000,
				step: 1000000,
				prefix: "kr",
				suffix: "kr",
			},
			{
				keys: ["employees_min", "employees_max"],
				label: t("prospects.axicon.filters.employees.label", "Anställda") as string,
				slider: true,
				max: 1000,
				step: 5,
			},
			{
				keys: ["vehicles_min", "vehicles_max"],
				label: t("prospects.axicon.filters.vehicles.label", "Fordon") as string,
				slider: true,
				max: 1000,
				step: 5,
			},
			{
				keys: ["workplaces_min", "workplaces_max"],
				label: t("prospects.axicon.filters.workplaces.label", "Arbetsplatser") as string,
				slider: true,
				max: 50,
				step: 1,
			},
			{
				keys: ["registered_from", "registered_to"],
				label: t("prospects.axicon.filters.registered.label", "Registreringsdatum") as string,
				type: "date",
				labels: ["från", "till"],
			},

			{
				label: t("prospects.axicon.filters.mobile.label", "Telefon och operatörer") as string,
				keys: [
					"telephone_numbers_min",
					"telephone_numbers_max",
					"porting_date_from",
					"porting_date_to",
					"operators_to_include",
					"operators_to_exclude",
				],
				items: [
					{
						keys: ["telephone_numbers_min", "telephone_numbers_max"],
						label: t("prospects.axicon.filters.telephone_numbers.label", "Antal telefonnummer") as string,
						labels: [
							t("prospects.axicon.filters.telephone_numbers_min.label", "Antal telefonnummer från") as string,
							t("prospects.axicon.filters.telephone_numbers_max.label", "Antal telefonnummer till") as string,
						],
						slider: true,
						max: 500,
						step: 5,
					},
					{
						keys: ["porting_date_from", "porting_date_to"],
						label: t("prospects.axicon.filters.porting_date.label", "Porteringsdatum") as string,
						type: "date",
						labels: [
							t("prospects.axicon.filters.porting_date_from.label", "Porteringsdatum från") as string,
							t("prospects.axicon.filters.porting_date_to.label", "Porteringsdatum till") as string,
						],
					},
					{
						label: t("prospects.axicon.filters.operators_to_include.label", "Operatörer att inkludera") as string,
						key: "operators_to_include",
						resourceHandle: "operators",
						url: "/api/company_registry/operators.json",
						valueKey: "title",
					},
					{
						label: t("prospects.axicon.operators_to_exclude.counties.label", "Operatörer att exkludera") as string,
						key: "operators_to_exclude",
						resourceHandle: "operators",
						url: "/api/company_registry/operators.json",
						valueKey: "title",
					},
				],
			},
		],
		[t]
	);

	const renderComp = (
		{ url, keys, label, labels, key, resourceHandle = key, valueKey, labelKey, slider, min, max, prefix, suffix, type, step, items, options }: any,
		parent: any = null
	) => {
		if (url) {
			const count = (() => {
				if (keys) return keys.filter((k) => !!axiconFilters[k] || axiconFilters[k] === 0).length;
				if (type === "checkbox") return axiconFilters[key] ? 1 : 0;
				return axiconFilters[key]?.length;
			})();

			return (
				<ResourceOptions
					label={
						parent && (
							<>
								{label}
								{!!count && (
									<Badge
										status="info"
										onRemove={() => {
											setAxiconFilters((curr) => {
												const filterKeys = keys ?? [key];
												const newFilters = { ...curr };
												filterKeys.forEach((k) => {
													delete newFilters[k];
												});

												return newFilters;
											});
										}}
									>
										{count}
									</Badge>
								)}
							</>
						)
					}
					valueKey={valueKey}
					labelKey={labelKey}
					url={url}
					resourceHandle={resourceHandle}
					onChange={getChangeHandler(key)}
					selected={axiconFilters[key] || []}
				/>
			);
		}

		if (type === "options") {
			return <OptionList allowMultiple label={label} selected={axiconFilters[key] ?? []} options={options} onChange={getChangeHandler(key)} />;
		}

		if (type === "checkbox") {
			return <Checkbox label={label} checked={axiconFilters[key] ?? false} onChange={getChangeHandler(key)} />;
		}

		if (keys?.length) {
			return (
				<FormLayout.Group condensed={!parent?.items}>
					<VerticalFlex>
						{slider && (
							<RangeSlider
								output
								label=""
								labelHidden
								value={[parseInt(String(axiconFilters[keys[0]] ?? 0)), parseInt(String(axiconFilters[keys[1]] ?? 0))]}
								prefix={prefix}
								min={min}
								max={max}
								step={step}
								onChange={getChangeHandlerforMultiple(keys)}
							/>
						)}
						<HorizontalFlex>
							<TextField
								label={labels?.[0] || "Min"}
								suffix={suffix}
								value={axiconFilters[keys[0]] ?? ""}
								onChange={getChangeHandler(keys[0])}
								type={type as any}
							/>
							<TextField
								label={labels?.[1] || "Max"}
								suffix={suffix}
								value={axiconFilters[keys[1]] ?? ""}
								onChange={getChangeHandler(keys[1])}
								type={type as any}
							/>
						</HorizontalFlex>
					</VerticalFlex>
				</FormLayout.Group>
			);
		}
	};

	const onRemoveFilter = useCallback(
		(keys) => () => {
			setAxiconFilters((curr) => {
				const newFilters = { ...curr };
				keys.forEach((k) => {
					delete newFilters[k];
				});

				return newFilters;
			});
		},
		[]
	);

	const getCount = useCallback(
		(filter) => {
			const { key, keys, type } = filter;
			if (keys) return keys.filter((k) => (!!axiconFilters[k] && String(axiconFilters[k])?.length) || axiconFilters[k] === 0).length;
			if (type === "checkbox") return axiconFilters[key as any] ? 1 : 0;
			return axiconFilters[key as any]?.length;
		},
		[axiconFilters]
	);

	return (
		<Page
			title={t("prospects.create.title", "Skapa ny prospektlista") as string}
			primaryAction={{
				content: t("prospects.actions.list.create", "Skapa prospektlista") as string,
				onAction: handleSave,
				disabled: !count,
			}}
		>
			<Wrapper>
				<Chat
					ref={chatRef}
					// chatId={83} // for testing
					introMessage={
						<>
							<h3>{t("prospects.axicon.filters.intro", "Här kan ni med hjälp av AI hitta nya kunder.")}</h3>

							<p>{t("prospects.axicon.filters.intro2", "Skriv i chatten nedan, alternativt välj ett av följande alternativ")}</p>
						</>
					}
					predefinedMessages={[
						{
							message: "Visa mig företag med en omsättning över 10 miljoner",
						},
						{
							message: "Visa mig företag med fler än 10 anställda som sitter i Stockholm",
						},
						{
							message: "Visa mig fastighetsbolag i Göteborg",
						},
						{
							message: "Visa mig företag med fler än 2 arbetsplatser",
						},
					]}
					messageContentKey="message_to_display_in_chat"
					onData={{
						handler: applyFilters,
						tooltip: t("prospects.axicon.filters.apply", "Använd filter") as string,
						key: "structured_content",
						autoApplyOnNewMessage: true,
					}}
				/>

				<RightWrapper>
					<LegacyCard sectioned>
						<FormLayout>
							<b>
								Antal importer kvar för denna månad:
								<Badge color={Colors.yellow}>{isLoadingMatchesLeftThisMonth ? <Spinner size="small" /> : matchesLeftThisMonth}</Badge>
							</b>
							<b>
								Antal träffar:
								<Badge status="success">{isLoadingCount ? <Spinner size="small" /> : count}</Badge>
							</b>
							<b>
								Antal filtreringar: <Badge status="info">{postFiltersCount}</Badge>
							</b>
						</FormLayout>
					</LegacyCard>

					<LegacyCard sectioned>
						<FormLayout>
							{filterSections.map((item) => {
								const { label, key, keys, items } = item;
								const count = getCount(item);

								return (
									<CollapsibleSection
										key={key}
										open={!!count}
										title={
											<>
												{label}
												{!!count && (
													<Badge status="info" onRemove={onRemoveFilter(keys ?? [key])}>
														{count}
													</Badge>
												)}
											</>
										}
									>
										{items ? <FormLayout>{items.map((i) => renderComp(i, item))}</FormLayout> : renderComp(item)}
									</CollapsibleSection>
								);
							})}
						</FormLayout>
					</LegacyCard>
				</RightWrapper>
			</Wrapper>
		</Page>
	);
};
export default ProspectCreate;

const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const HorizontalFlex = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: space-between;
`;
