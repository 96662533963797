import { FormLayout } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Details from "src/js/components/Details";
import Modal from "src/js/components/modal";
import Spinner from "src/js/components/Spinner";
import useQuery from "src/js/hooks/useQuery";

const SendConfirmModal = ({ targetAudiences, selectedContacts, onClose, handleSend, isOpen, outboundEmail }) => {
	const { t } = useTranslation(["outreach", "common"]);

	const { data: counts, isFetching } = useQuery({
		queryKey: ["target_audience_counts", ...targetAudiences.map((targetAudience) => targetAudience.id)],
		queryFn: async () => {
			const res = await API.post(`/api/outbound_emails/${outboundEmail.id}/counts.json`, {
				target_audience_ids: targetAudiences.map((targetAudience) => targetAudience.id),
				contact_ids: selectedContacts.map((contact) => contact.id),
			});

			return res.data;
		},
		enabled: !!(!!targetAudiences?.length || !!selectedContacts?.length) && isOpen,
		initialData: targetAudiences.length ? { total: 0, target_audiences: targetAudiences, selected_contact_count: 0 } : null,
	});

	return (
		<Modal
			open={isOpen}
			title={t("outreach.SendConfirmModal.title", "Säker du vill skicka detta utskick?")}
			onClose={onClose}
			primaryAction={{
				content: t("outreach.SendConfirmModal.send", "Skicka") as string,
				onAction: handleSend,
				disabled: isFetching || !counts?.total,
			}}
		>
			{/* <Trans
				i18nKey="outreach.SendConfirmModal.desc"
				defaults="Skicka till <b>{{count}}</b> företag/kontakter?"
				values={{ count: counts?.total }}
				components={{ b: <b /> }}
			/>
			<br /> */}
			<FormLayout>
				<div>
					<p style={{ color: "#ffffff", fontSize: 16, fontWeight: 600 }}>
						{`${t("outreach.SendConfirmModal.total", "Totalt antal mottagare")}: `}
						{isFetching ? <Spinner size={"small"} /> : counts?.total}
					</p>
					<p style={{ color: "#ffffff", fontSize: 12, opacity: 0.5 }}>
						{t(
							"outreach.SendConfirmModal.total_helptext",
							"Unika företag/kontakter med registrerad e-postadress, exklusive de e-postadresser som vid validering visade sig vara ogiltiga eller som aktivt avregistrerat sig från utskick."
						)}
					</p>
				</div>

				<div>
					<p>Målgrupper:</p>
					<Details
						items={[
							{
								label: t("outreach.SendConfirmModal.selected_contacts_count", "Antal individuellt valda kontakter"),
								value: counts?.selected_contact_count,
								enabled: !!counts?.selected_contact_count,
							},
							...(counts?.target_audiences?.map((a) => ({
								label: a.title,
								value: a.count || 0,
							})) || []),
						].filter((i) => !("enabled" in i) || i.enabled)}
					/>
				</div>
			</FormLayout>
		</Modal>
	);
};
export default SendConfirmModal;
