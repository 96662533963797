import { FormLayout, Spinner } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Details from "src/js/components/Details";
import Modal from "src/js/components/modal";
import useQuery from "src/js/hooks/useQuery";
import { getDispatchedStatuses } from "../Utilities/getDispatchedStatus";

const ResendBatchConfirmModal = ({ isOpen, onClose, batch, statuses, handleSend }) => {
	const { t } = useTranslation(["outreach", "common"]);

	const { data: counts, isFetching } = useQuery({
		queryKey: ["batch_resend_to_statuses_counts", batch?.id, JSON.stringify(statuses)],
		queryFn: async () => {
			const res = await API.post(`/api/outbound_email_batches/${batch?.id}/resend/count.json`, {
				statuses,
			});

			return res.data;
		},
		enabled: !!(statuses?.length && isOpen),
	});

	const dispatchedStatuses = getDispatchedStatuses();

	return (
		<Modal
			open={isOpen}
			title={t("outreach.ResendBatchConfirmModal.title", "Säker du vill skicka detta utskick igen?")}
			onClose={onClose}
			primaryAction={{
				content: t("outreach.ResendBatchConfirmModal.send", "Skicka") as string,
				onAction: handleSend,
				disabled: isFetching || !counts?.total,
			}}
		>
			<FormLayout>
				<div>
					<p style={{ color: "#ffffff", fontSize: 16, fontWeight: 600 }}>
						{`${t("outreach.ResendBatchConfirmModal.total", "Totalt antal mottagare")}: `}
						{isFetching ? <Spinner size={"small"} /> : counts?.total}
					</p>
				</div>

				<div>
					<p>Statusar:</p>
					<Details
						items={[
							...(counts?.statuses?.map((a) => ({
								label: dispatchedStatuses[a.title]?.label || a.label,
								value: a.count || 0,
							})) || []),
						]}
					/>
				</div>
			</FormLayout>
		</Modal>
	);
};
export default ResendBatchConfirmModal;
