import i18n from "src/js/i18n";

import WorkspaceView from "src/js/views/Workspaces/WorkspaceView.js";
import BoardView from "src/js/views/Workspaces/BoardView.js";
import DashboardView from "src/js/views/Workspaces/DashboardView.js";

export default () => [
	{
		path: "/admin/workspaces/:workspace_id/dashboards/:id",
		mainMenu: false,
		exact: true,
		component: DashboardView,
		name: i18n.t("routes.names.dashboard", "Dashboard"),
	},
	{
		path: "/admin/workspaces/:workspace_id/boards/:id",
		mainMenu: false,
		exact: true,
		component: BoardView,
		name: i18n.t("routes.names.board", "Board"),
	},
	{
		path: "/admin/workspaces/:id",
		mainMenu: false,
		exact: true,
		component: WorkspaceView,
		name: i18n.t("routes.names.workspace", "Workspace"),
	},
];
