import Colors from "src/js/Colors";
import i18n from "src/js/i18n";

export const getDispatchedStatuses = (dispatchedEmail?: DispatchedEmailType | null) => {
	return {
		not_sent: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.not_dispatched.label", "Ej skickat"),
			color: Colors.gray,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.not_dispatched.tooltip", "Ej skickat"),
		},
		send_error: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.send_error.label", "Ett fel uppstod"),
			color: Colors.red,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.send_error.tooltip", "Ett fel uppstod"),
		},
		context_error: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.context_error.label", "Ett fel uppstod med kontexten"),
			color: Colors.red,
			tooltip: i18n.t(
				"outbound.ouboundEmailLog.dispatchedEmail.status.context_error.tooltip",
				"Ett fel uppstod med förmodligen variablarna. Kontrollera variablarna ({{variableNamn}}) i E-postmallen"
			),
		},
		delivered_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.delivered_at.label", "Levererat"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.delivered_at.tooltip", "Levererat {{date}}", {
				date: dispatchedEmail?.delivered_at,
			}),
		},
		bounced_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.bounced_at.label", "Bounced"),
			color: Colors.red,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.bounced_at.tooltip", "Bounced {{date}}", {
				date: dispatchedEmail?.bounced_at,
			}),
		},
		soft_bounced_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.soft_bounced_at.label", "Bounced soft"),
			color: Colors.red,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.soft_bounced_at.tooltip", "Bounced soft {{date}}", {
				date: dispatchedEmail?.soft_bounced_at,
			}),
		},

		complained_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.complained_at.label", "Klagat"),
			color: Colors.red,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.complained_at.tooltip", "Klagat {{date}}", {
				date: dispatchedEmail?.complained_at,
			}),
		},

		opened_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.opened_at.label", "Öppnat"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.opened_at.tooltip", "Öppnat {{date}}", {
				date: dispatchedEmail?.opened_at,
			}),
		},

		clicked_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.clicked_at.label", "Klickat"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.clicked_at.tooltip", "Klickat {{date}}", {
				date: dispatchedEmail?.clicked_at,
			}),
		},

		unsubscribed_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.unsubscribed_at.label", "Avregistrerat"),
			color: Colors.red,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.unsubscribed_at.tooltip", "Avregistrerat {{date}}", {
				date: dispatchedEmail?.unsubscribed_at,
			}),
		},

		sent_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.sent_at.label", "Skickat"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.sent_at.tooltip", "Skickat {{date}}", {
				date: dispatchedEmail?.sent_at && `(${dispatchedEmail.sent_at})`,
			}),
		},

		dispatched_at: {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.dispatched_at.label", "Planerat utskick"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.dispatched_at.tooltip", "Planerat utskick {{date}}", {
				date: dispatchedEmail?.dispatched_at && `(${dispatchedEmail.dispatched_at})`,
			}),
		},
	};
};

// const getReceiveStatus = (ouboundEmailLog: DispatchedEmailType) => {
const getReceiveStatus = (dispatchedEmail: DispatchedEmailType, outboundEmailLog?: OutboundEmailLogType) => {
	if (!dispatchedEmail) {
		if (outboundEmailLog?.emailWasUnsubscribed) return getDispatchedStatuses(dispatchedEmail).unsubscribed_at;
		return getDispatchedStatuses(dispatchedEmail).not_sent;
	}

	if (dispatchedEmail.send_error && !dispatchedEmail.sent_at) {
		return getDispatchedStatuses(dispatchedEmail).send_error;
	}

	if (dispatchedEmail.clicked_at) {
		return getDispatchedStatuses(dispatchedEmail).clicked_at;
	}

	if (dispatchedEmail.opened_at) {
		return getDispatchedStatuses(dispatchedEmail).opened_at;
	}

	if (dispatchedEmail.bounced_at) {
		return getDispatchedStatuses(dispatchedEmail).bounced_at;
	}

	if (dispatchedEmail.soft_bounced_at) {
		return getDispatchedStatuses(dispatchedEmail).soft_bounced_at;
	}

	if (dispatchedEmail.complained_at) {
		return getDispatchedStatuses(dispatchedEmail).complained_at;
	}

	if (dispatchedEmail.unsubscribed_at) {
		return getDispatchedStatuses(dispatchedEmail).unsubscribed_at;
	}

	if (dispatchedEmail.delivered_at) {
		return getDispatchedStatuses(dispatchedEmail).delivered_at;
	}

	if (dispatchedEmail.sent_at) {
		return getDispatchedStatuses(dispatchedEmail).sent_at;
	}

	if (dispatchedEmail.dispatched_at) {
		return {
			label: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.dispatched_at.label", "Planerat utskick"),
			color: Colors.green,
			tooltip: i18n.t("outbound.ouboundEmailLog.dispatchedEmail.status.dispatched_at.tooltip", "Planerat utskick {{date}}", {
				date: dispatchedEmail.dispatched_at && `(${dispatchedEmail.dispatched_at})`,
			}),
		};
	}
};
export default getReceiveStatus;
