import React from "react";
import styled from "styled-components";
import { Spinner, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Colors from "../Colors";

type DetailsProps = {
	items?: any;
	style?: React.CSSProperties;
	gap?: string | number | { vertical?: string | number; horizontal?: string | number };
	t?: any;
};

const Details = ({ items, style = {}, gap, t }: DetailsProps) => {
	const getValueComponent = ({ label, value, type, href, component, className, color, height, loading, duplicates }, key) => {
		const style: any = {};
		if (color) style.color = color;

		if (component)
			return (
				<span style={style} className="info-view__component" key={key}>
					{component}
				</span>
			);

		if (href && value) {
			return (
				<a href={href} target="_blank" key={key} className={`info-view__row__value ${className || ""}`} style={style} rel="noreferrer">
					{value}
				</a>
			);
		}
		if (type === "email" && value) {
			return (
				<a href={`mailto:${value}`} key={key} className={`info-view__row__value ${className || ""}`} style={style}>
					{value}
				</a>
			);
		}
		if (type === "phone" && value) {
			return (
				<a href={`tel:${value}`} key={key} className={`info-view__row__value ${className || ""}`} style={style}>
					{value}
				</a>
			);
		}
		if ((type === "orgnr" || type === "company_number") && value) {
			return (
				<LegacyStack>
					<a
						href={`https://www.allabolag.se/${value?.number?.replace("-", "") || value?.replace("-", "")}`}
						target="_blank"
						key={key}
						className={`info-view__row__value ${className || ""}`}
						style={style}
						rel="noreferrer"
					>
						{value?.number || value}
					</a>
					{loading && <Spinner size="small" />}
					{!loading && duplicates?.length > 0 && <div style={{ color: Colors.red }}>{t("contact.errors.exists", "Företag finns redan")}</div>}
				</LegacyStack>
			);
		}

		return (
			<span key={key} style={style} className={`info-view__row__value ${className || ""}`}>
				{(() => {
					switch (typeof value) {
						case "string":
						case "number":
							return value;
						case "object":
							if (!value) return;

							if (React.isValidElement(value)) {
								return value;
							}
							if (Array.isArray(value)) {
								return value.join(", ");
							}
							return JSON.stringify(value, null, 2);
						default:
							return value;
					}
				})()}
			</span>
		);
	};

	if (!items || !items.length) return null;

	const verticalGap = (gap as any)?.vertical || gap || 0;
	const horizontalGap = (gap as any)?.horizontal || gap || 0;

	return (
		<TableWrapper style={style}>
			<tbody>
				{items.map((item, index) => (
					<tr key={index}>
						{!item.empty ? (
							<>
								<LabelCell horizontalGap={horizontalGap} verticalGap={verticalGap}>
									{item.label && item.label.capitalize ? item.label.capitalize() : item.label}:
								</LabelCell>
								<ValueCell>{getValueComponent(item, index)}</ValueCell>
							</>
						) : (
							<>
								<td />
								<td />
							</>
						)}
					</tr>
				))}
			</tbody>
		</TableWrapper>
	);
};

export default withTranslation(["contact", "common"], { withRef: true })(Details);

const TableWrapper = styled.table`
	border-collapse: collapse;
	height: max-content;

	tr > td:empty {
		min-height: 20px;
		height: 20px;
		line-height: 20px;
	}

	/* width: 100%; */
`;

export const LabelCell = styled.td<{ verticalGap?: any; horizontalGap?: any }>`
	font-weight: 500;
	color: var(--textColor3);
	opacity: 0.75;
	padding-right: ${({ horizontalGap }) => (typeof horizontalGap === "number" ? `${horizontalGap}px` : horizontalGap) || "0.5rem"};
	padding-top: ${({ verticalGap }) => (typeof verticalGap === "number" ? `${verticalGap}px` : verticalGap) || 0};
	padding-bottom: ${({ verticalGap }) => (typeof verticalGap === "number" ? `${verticalGap}px` : verticalGap) || 0};
	text-align: left;
	white-space: nowrap;
	/* vertical-align: center; */
`;

export const ValueCell = styled.td<{ verticalGap?: any; horizontalGap?: any }>`
	padding-left: ${({ horizontalGap }) => (typeof horizontalGap === "number" ? `${horizontalGap}px` : horizontalGap) || "0.5rem"};
	padding-bottom: ${({ verticalGap }) => (typeof verticalGap === "number" ? `${verticalGap}px` : verticalGap) || 0};
	padding-top: ${({ verticalGap }) => (typeof verticalGap === "number" ? `${verticalGap}px` : verticalGap) || 0};
	text-align: left;
	/* vertical-align: center; */
`;
